import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {TranslatePipe} from '@aztrix/translate';

@Component({
  selector: 'lt-pending-changes-warning',
  templateUrl: './pending-changes-warning.component.html',
  styleUrls: ['./pending-changes-warning.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslatePipe, ButtonModule, IconsModule],
})
export class PendingChangesWarningComponent {
  @Input() message: string;
  @Input() showCancel = false;
  @Output() save = new EventEmitter();
  @Output() discard = new EventEmitter();
  @Output() cancel = new EventEmitter();
}
