<ax-success-icon
  *ngIf="showIcon"
  [color]="bodyIconColorClass"
  [name]="bodyIconClass"
></ax-success-icon>
<h3 *ngIf="bodyTitle">{{ bodyTitle | translate }}</h3>
<span>{{ bodyExplanation | translate }}</span>
<div *ngIf="showButton" class="h-button-wrapper h-button-wrapper--center">
  <button axButton (click)="didClickButton.emit()" color="primary">
    {{ buttonLabel | translate }}
  </button>
</div>
