import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from '@aztrix/translate';
import {Agreement, AgreementVerificationType} from '@aztrix/models';
import {AgreementRepresentation, ProposalRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-agreement-verification-status',
  templateUrl: './agreement-verification-status.component.html',
  styleUrls: ['./agreement-verification-status.component.scss', '../../property.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementVerificationStatusComponent {
  @Input() agreement: Agreement | AgreementRepresentation;
  @Input() verification?: AgreementVerificationType | ProposalRepresentation.VerificationEnum;
  @Input() isExternal = false;

  constructor(private _translate: TranslateService) {}

  get verificationDisplayValue(): string | undefined {
    return this.verification === AgreementVerificationType.IDENTITY
      ? this._translate.instant('label.passbase')
      : this.agreement.verificationValue;
  }
}
