import {Pipe, PipeTransform} from '@angular/core';
import {sortItems} from '@aztrix/helpers';
import {AlistItemRepresentation} from '@aztrix/sdk';

@Pipe({name: 'sortItems'})
export class SortItemsPipe implements PipeTransform {
  transform(items: AlistItemRepresentation[]): AlistItemRepresentation[] {
    return sortItems(items);
  }
}
