<ax-value-edit
  type="TEXT"
  [form]="title"
  [label]="'item.type.FORM.title' | translate"
></ax-value-edit>

<ax-value-edit
  type="TEXT_AREA"
  [form]="description"
  [label]="'item.type.FORM.description' | translate"
></ax-value-edit>

<div class="h-button-wrapper">
  <button axButton (click)="cancel()">
    {{ 'label.cancel' | translate }}
  </button>
  <button axButton filled color="primary" [disabled]="form.invalid" (click)="save()">
    {{ 'label.save' | translate }}
  </button>
</div>
