<div class="container">
  <div class="title">
    <h3>{{ 'cookies.analytics.title' | translate }}</h3>
    <p>{{ 'landing.snackbar.analytics.text' | translate }}</p>
  </div>

  <div class="button-wrapper">
    <a
      *ngIf="googleAnalyticsId"
      color="primary"
      href=""
      class="cookie-button"
      (click)="cookiePreferences.emit(); $event.preventDefault()"
    >
      {{ 'landing.snackbar.analytics.cookie.preferences' | translate }}
    </a>
    <button axButton filled color="primary" (click)="decline()">
      {{ 'label.decline' | translate }}
    </button>
    <button axButton filled color="primary" (click)="accept()">
      {{ 'label.accept' | translate }}
    </button>
  </div>
</div>
