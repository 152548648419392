import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PoweredByAztrixModule} from '@aztrix/components/powered-by-aztrix';
import {ProposalSubscribeSendToEmailModule} from '@aztrix/subscribe/proposal-subscribe-send-to-email';

import {ProposalDocumentsModule} from './proposal-documents/proposal-documents.module';
import {ProposalSubscribeErrorsModule} from './proposal-subscribe-errors/proposal-subscribe-errors.module';
import {ProposalSubscribeFormComponent} from './proposal-subscribe-form.component';
import {ProposalSubscribeStepsModule} from './proposal-subscribe-steps/proposal-subscribe-steps.module';
import {ProposalSubscribeVerificationFormModule} from './proposal-subscribe-verification-form/proposal-subscribe-verification-form.module';

@NgModule({
  declarations: [ProposalSubscribeFormComponent],
  imports: [
    CommonModule,
    ProposalDocumentsModule,
    ProposalSubscribeStepsModule,
    ProposalSubscribeErrorsModule,
    ProposalSubscribeVerificationFormModule,
    ProposalSubscribeSendToEmailModule,
    PoweredByAztrixModule,
  ],
  exports: [ProposalSubscribeFormComponent, ProposalDocumentsModule],
})
export class ProposalSubscribeFormModule {}
