import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {HelperPipesModule} from '@aztrix/helpers';

@Component({
  selector: 'lt-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss', '../action-button.scss'],
  standalone: true,
  imports: [CommonModule, MatSelectModule, HelperPipesModule, FormsModule, ReactiveFormsModule],
})
export class LanguageSelectorComponent {
  @Input() availableLanguages: string[];
  @Input() selectedForm = new FormControl();
}
