<button
  axButton
  icon-button
  [cdkCopyToClipboard]="value || ''"
  (cdkCopyToClipboardCopied)="didClickCopy()"
  [color]="color === 'grey' ? undefined : 'white'"
>
  <ax-icon
    class="icon"
    [ngClass]="{copied: copied$ | async, white: color === 'white'}"
    [name]="(copied$ | async) ? 'check-circle' : 'content-copy'"
  ></ax-icon>
</button>
