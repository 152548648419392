import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ButtonModule} from '@aztrix/components/button';
import {DocumentModule} from '@aztrix/components/document';
import {ValueViewModule} from '@aztrix/components/value-view';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';

import {ProposalDocumentCheckboxComponent} from './proposal-document/proposal-document-checkbox/proposal-document-checkbox.component';
import {ProposalDocumentSignButtonComponent} from './proposal-document/proposal-document-sign-button/proposal-document-sign-button.component';
import {ProposalDocumentComponent} from './proposal-document/proposal-document.component';
import {ProposalDocumentsComponent} from './proposal-documents.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    ProposalDocumentsComponent,
    ProposalDocumentComponent,
    ProposalDocumentCheckboxComponent,
    ProposalDocumentSignButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    IconsModule,
    ValueViewModule,
    ButtonModule,
    DocumentModule,
    TranslatePipe,
  ],
  exports: [
    ProposalDocumentsComponent,
    ProposalDocumentComponent,
    ProposalDocumentCheckboxComponent,
    ProposalDocumentSignButtonComponent,
  ],
})
export class ProposalDocumentsModule {}
