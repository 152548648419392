<button class="button" (click)="openBottomSheet()">
  <ax-icon class="icon" [name]="open ? 'close' : 'menu'"></ax-icon>
  <span>{{ 'label.more' | translate | uppercase }}</span>
</button>

<ng-template>
  <ax-navbar-bottom-sheet>
    <ng-content></ng-content>
  </ax-navbar-bottom-sheet>
</ng-template>
