import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {AnalyticsService, defaultConsent, grantedConsent, HelperPipesModule} from '@aztrix/helpers';
import {TranslatePipe} from '@aztrix/translate';

@Component({
  selector: 'lt-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss', '../cookie-modal.scss'],
  standalone: true,
  imports: [CommonModule, TranslatePipe, ButtonModule],
})
export class CookieConsentComponent implements OnInit {
  @Input() googleAnalyticsId?: string;
  @Input() facebookPixelId?: string;
  @Input() facebookAccessToken?: string;

  @Output() _close = new EventEmitter<void>();
  @Output() cookiePreferences = new EventEmitter<void>();

  constructor(private _analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    if (this.googleAnalyticsId) {
      this._analyticsService.initGoogle(this.googleAnalyticsId);
    }

    if (this.facebookPixelId) {
      this._analyticsService.initFacebook(this.facebookPixelId);
    }
  }

  accept() {
    if (this.googleAnalyticsId) {
      this._analyticsService.updateConsent(grantedConsent);
    } else {
      this._analyticsService.consentGrantFacebook();
    }
    this._close.next();
  }

  decline() {
    if (this.googleAnalyticsId) {
      this._analyticsService.updateConsent(defaultConsent);
    } else {
      this._analyticsService.consentGrantFacebook();
    }
    this._close.next();
  }
}
