import {Component} from '@angular/core';

import {AbstractItemView} from '../abstract-item-view';

@Component({
  selector: 'lt-item-button-view',
  templateUrl: './item-button-view.component.html',
  styleUrls: ['./item-button-view.component.scss', '../item-view.scss'],
})
export class ItemButtonViewComponent extends AbstractItemView {
  get data() {
    return <string>this.item.data;
  }
}
