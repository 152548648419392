<form [name]="proposal?.id">
  @if (form) {
    <ax-proposal-subscribe-steps
      [form]="form"
      [proposal]="proposal"
      [proposalProperties]="(requestedProperties$ | async) || []"
      [autocompleteProperties]="autocompleteProperties || []"
      [language]="(language$ | async) || undefined"
      [agreementCode]="agreementCode"
      [agreement]="agreement"
      [myProfile]="myProfile"
      [demo]="demo"
      [collapsibleGroups]="collapsibleGroups"
      [noSubscribeButton]="noSubscribeButton"
      [orientation]="orientation"
      [open]="open"
      [external]="external"
      [edit]="edit"
      [subscriberMetadata]="subscriberMetadata"
      [subscribeLabel]="subscribeLabel"
      (subscribed)="subscribed.next($event)"
    >
      <ng-content></ng-content>
    </ax-proposal-subscribe-steps>
  }

  <div class="footer">
    <ax-powered-by-aztrix
      *ngIf="external && (whiteLabel$ | async) === false"
    ></ax-powered-by-aztrix>
    <div class="actions">
      <ng-content select=".action"></ng-content>
    </div>
  </div>
</form>
