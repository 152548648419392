import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProposalSubscribeFormModule} from '@aztrix/subscribe/proposal-subscribe-form';

import {ProposalSubscribeStepperComponent} from './proposal-subscribe-stepper.component';

@NgModule({
  declarations: [ProposalSubscribeStepperComponent],
  imports: [CommonModule, ProposalSubscribeFormModule],
  exports: [ProposalSubscribeStepperComponent],
})
export class ProposalSubscribeStepperModule {}
