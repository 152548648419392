<ax-property-edit
  [form]="form"
  [name]="name"
  [hint]="hint"
  [autofocus]="autofocus"
  [customLabel]="label"
  [required]="required"
  [showIcon]="showIcon"
  [readonly]="readonly"
  [icon]="customIcon"
></ax-property-edit>
