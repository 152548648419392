import {Component, Input} from '@angular/core';

@Component({
  selector: 'ax-value-view',
  templateUrl: './value-view.component.html',
  styleUrls: ['./value-view.component.scss'],
})
export class ValueViewComponent {
  @Input() type: 'BOOLEAN' | 'TEXT' | 'TEXT_AREA' | 'RICH_TEXT' | 'DATE' | 'FILE' | 'IMAGE';
  @Input() label: string;
  @Input() value: string | string[] | undefined | null;
}
