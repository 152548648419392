import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconsModule} from '@aztrix/icons';

import {SuccessIconComponent} from './success-icon.component';

@NgModule({
  declarations: [SuccessIconComponent],
  imports: [CommonModule, IconsModule],
  exports: [SuccessIconComponent],
})
export class SuccessIconModule {}
