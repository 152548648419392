import {Component} from '@angular/core';

import {AbstractProposalDocument} from '../../abstract-proposal-document';

@Component({
  selector: 'ax-proposal-document-checkbox',
  templateUrl: 'proposal-document-checkbox.component.html',
  styleUrls: ['../document.scss'],
})
export class ProposalDocumentCheckboxComponent extends AbstractProposalDocument {}
