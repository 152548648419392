import {Component, Input} from '@angular/core';
import {Agreement} from '@aztrix/models';
import {AgreementRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-agreement-status',
  templateUrl: './agreement-status.component.html',
  styleUrls: ['./agreement-status.component.scss', '../property.scss'],
})
export class AgreementStatusComponent {
  @Input() proposalHasVerification = false;
  @Input() agreement?: Agreement | AgreementRepresentation;

  get isActive() {
    return this.agreement?.active || false;
  }

  get isConfirmed() {
    return this.agreement?.agreementData?.confirmed || false;
  }

  get isVerified() {
    return this.agreement?.verified || false;
  }
}
