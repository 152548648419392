<ax-agreement-status
  [proposalHasVerification]="proposalHasVerification"
  [agreement]="agreement"
></ax-agreement-status>
<div class="property" *ngIf="languageCode$ | async">
  <div class="title">{{ 'agreement.language-version' | translate }}</div>
  <span class="status">{{ 'languageCode.' + (languageCode$ | async) + '.label' | translate }}</span>
</div>
@if (agreement) {
  <ax-agreement-subscriber [agreement]="agreement"> </ax-agreement-subscriber>
}
<div class="start-date property" *ngIf="agreementData">
  <div class="title">{{ 'label.startDate' | translate }}</div>
  <span class="status">{{ startTimestamp$ | async | formatDateTime }}</span>
</div>
@if (agreement) {
  <ax-agreement-verification-status
    *ngIf="proposalHasVerification"
    [agreement]="agreement"
    [verification]="proposal?.verification"
  ></ax-agreement-verification-status>
}
<div
  class="end-date property"
  *ngIf="agreement?.expirationTimestamp || (agreementData?.endTimestamp && isHistoryAgreement$)"
>
  <div class="title">{{ 'label.endDate' | translate }}</div>
  {{ agreementData?.endTimestamp || agreement?.expirationTimestamp | formatDateTime }}
</div>
<div class="property" *ngIf="agreement?.active && !(isSubscriber$ | async)">
  <div class="title">{{ 'label.modifiedDate' | translate }}</div>
  <span *ngIf="agreement?.modificationTimestamp as modifiedDate">
    {{ modifiedDate | formatDateTime }}
  </span>
</div>
<div class="property">
  <div class="title">
    {{ ((isSubscriber$ | async) ? 'label.modifiedDate' : 'label.dataModifiedDate') | translate }}
  </div>
  <span *ngIf="agreement?.agreementData?.startTimestamp as startTimestamp">
    {{ startTimestamp | formatDateTime }}
  </span>

  @if (agreementData?.creatorId && (agreementCreator$ | async)) {
    <span> ({{ agreementCreator$ | async | displayName }}) </span>
  } @else if ((createdBySubject$ | async) !== '') {
    <span>
      ({{
        'label.modified.by'
          | translate: {subject: createdBySubject$ | async | translate | lowercase}
      }})
    </span>
  }
</div>
<div class="property" *ngIf="!agreement?.active">
  <div class="title">{{ 'label.expiredDate' | translate }}</div>
  <span *ngIf="agreement?.expirationTimestamp as expiredDate">
    {{ expiredDate | formatDateTime }}
  </span>
</div>
<div
  class="code property"
  *ngIf="agreement?.agreementCode && (owner$ | async)?.id === agreement?.proposalOwnerId"
>
  <div class="title">{{ 'agreement.confirm-link' | translate }}</div>
  <div class="code-wrapper">
    <ax-copy-text
      [value]="baseUrl + '/proposal/agreement/confirm/' + agreement?.agreementCode"
    ></ax-copy-text>
    <button
      class="email-button"
      *ngIf="!agreement?.agreementData?.confirmed && (isOwner$ | async)"
      axButton
      color="primary"
      filled
      (click)="didClickSendEmail.next($event)"
    >
      <ax-icon class="icon" [name]="iconNameForCommunicationType(communicationType)"></ax-icon>
      {{ 'label.sendByEmail' | translate }}
    </button>
  </div>
</div>
