import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {SuccessIconModule} from '@aztrix/components/success-icon';
import {TranslatePipe} from '@aztrix/translate';

import {ActionFinishedComponent} from './action-finished.component';

@NgModule({
  declarations: [ActionFinishedComponent],
  imports: [CommonModule, SuccessIconModule, TranslatePipe, ButtonModule],
  exports: [ActionFinishedComponent],
})
export class ActionFinishedModule {}
