import {Component, ElementRef, Input} from '@angular/core';
import {ModalSize, OverlayService} from '@aztrix/components/overlay';

import {AztrixInfoModalComponent} from './aztrix-info-modal/aztrix-info-modal.component';

@Component({
  selector: 'ax-powered-by-aztrix',
  template: `
    <ax-components-theme>
      <button class="aztrix-button" [ngClass]="{'big-logo': bigLogo}" (click)="openDialog($event)">
        <span>Powered by</span>
        <ax-icon class="icon logo" [name]="name"></ax-icon>
      </button>
    </ax-components-theme>
  `,
  styleUrls: ['powered-by-aztrix.component.scss'],
})
export class PoweredByAztrixComponent {
  @Input() bigLogo = false;
  @Input() legal = true;
  @Input() name = 'custom:aztrix_logo';

  constructor(private _elementRef: ElementRef, private _overlay: OverlayService) {}

  openDialog(evt: Event): void {
    evt.preventDefault();
    this._overlay.createModal(this._elementRef, AztrixInfoModalComponent, {
      modalSize: ModalSize.EXTRA_SMALL,
      init: (modal) => {
        modal.legal = this.legal;
      },
    });
  }
}
