import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {getRequestedPropertyIds, proposalLegacyToProposal, sortSteps} from '@aztrix/helpers';
import {AgreementDocument, Property, Proposal, ProposalLanguage} from '@aztrix/models';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  ProfileRepresentation,
  ProposalLanguageItemWithPropertyRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {combineLatest, map, ReplaySubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-data',
  templateUrl: './agreement-data.component.html',
  styleUrls: ['./agreement-data.component.scss', '../overview.scss'],
})
export class AgreementDataComponent implements OnChanges {
  @Input() myProfile?: ProfileRepresentation;
  @Input() proposal?: Proposal | ProposalRepresentation;
  @Input() language?: ProposalLanguage | ProposalLanguageRepresentation;
  @Input() agreementProperties?: AgreementPropertyRepresentation[];
  @Input() agreementDocuments: (AgreementDocument | AgreementDocumentRepresentation)[];

  @Input() showOutOfSync = true;
  @Input() agreementConfirmed = false;
  @Input() agreementActive = true;

  @Input() isInAgreement = true;

  @Output() addProperty = new EventEmitter<Property>();
  @Output() editProperty = new EventEmitter<Property | undefined>();

  private _language$ = new ReplaySubject<ProposalLanguage | ProposalLanguageRepresentation>(1);
  private _proposal$ = new ReplaySubject<Proposal | undefined>(1);

  language$ = combineLatest([this._language$]).pipe(
    map(([language]) => {
      return {
        ...language,
        steps: sortSteps(language.items, language.steps),
      };
    }),
    map((language) => {
      return <ProposalLanguageRepresentation>{
        ...language,
        items: [...(language.items || [])]?.sort((i1, i2) =>
          (i1.orderIndex || 0) > (i2.orderIndex || 0) ? 1 : -1
        ),
      };
    })
  );

  hasSteps$ = this.language$.pipe(map((language) => !!language.steps?.length));

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.language && this.language) {
      this._language$.next(this.language);
    }
    if (changes.proposal && this.proposal) {
      this._proposal$.next(proposalLegacyToProposal(<ProposalRepresentation>this.proposal));
    }
  }

  getAgreementPropertyForItem(item: ProposalLanguageItemWithPropertyRepresentation | undefined) {
    return this.agreementProperties?.find(
      (ap) => ap.requestedPropertyId === item?.requestedPropertyId
    );
  }

  getPropertyForItem(item: ProposalLanguageItemWithPropertyRepresentation | undefined) {
    return (
      this.proposal && 'properties' in this.proposal
        ? this.proposal
        : proposalLegacyToProposal(<ProposalRepresentation>this.proposal)
    )?.properties.find((p) => p.requestedPropertyId === item?.requestedPropertyId);
  }

  stepSkipped(item: ProposalLanguageItemWithStepRepresentation): boolean {
    const step = this.language?.steps?.find((s) => s.id === item.stepId);
    const stepRequestedPropertyIds = getRequestedPropertyIds(step, this.language?.items);
    let skipped = true;
    for (const requestedPropertyId of stepRequestedPropertyIds) {
      const agreementProperty = this.agreementProperties?.find(
        (p) => p.requestedPropertyId === requestedPropertyId
      );
      if (agreementProperty) {
        skipped = false;
        break;
      }
    }

    return skipped;
  }

  getStepForItem(item: ProposalLanguageItemWithStepRepresentation) {
    if (!item.stepId) {
      return undefined;
    }
    return this.language?.steps?.find((step) => step.id === item.stepId);
  }
}
