import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  Component,
  HostBinding,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {fuzzySort} from '@aztrix/helpers';
import {CustomFieldValueLabel, Property} from '@aztrix/models';
import {CustomFieldValueLabelRepresentation, PropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {debounceTime, map, shareReplay, startWith} from 'rxjs/operators';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-dropdown-edit',
  templateUrl: 'custom-dropdown-edit.component.html',
  styleUrls: ['custom-dropdown-edit.component.scss', '../show-icon.scss'],
})
export class CustomDropdownEditComponent
  extends AbstractProposalPropertyEdit
  implements OnInit, OnChanges, OnDestroy
{
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  dropdownControl = new UntypedFormControl();
  formFilterControl = new UntypedFormControl('');

  formSubscription = new Subscription();
  dropdownControlSubscription = new Subscription();

  private _query$ = this.formFilterControl.valueChanges.pipe(
    debounceTime(500),
    startWith(undefined)
  );

  private _dropdownChanges$ = this.dropdownControl.valueChanges.pipe(
    startWith(this.dropdownControl.value)
  );

  valueLabels$ = new BehaviorSubject<
    CustomFieldValueLabel[] | CustomFieldValueLabelRepresentation[] | undefined
  >(undefined);

  readonly itemSize = 48;

  valueLabelsFiltered$ = combineLatest([
    this._query$,
    this.valueLabels$,
    this._dropdownChanges$,
  ]).pipe(
    map(([query, values]) => {
      if (query && query.length) {
        return fuzzySort(values, query, ['label']);
      } else {
        const valueLabels = [...(values || [])];
        const selectedIndex = valueLabels.findIndex(
          (vl) => vl.value === this.dropdownControl.value
        );

        if (selectedIndex >= 0) {
          const selectedLabel = valueLabels.splice(selectedIndex, 1)[0];
          return [selectedLabel, ...valueLabels];
        }
        return valueLabels;
      }
    }),
    shareReplay(1)
  );

  viewportHeight$ = this.valueLabelsFiltered$.pipe(
    map((valueLabels) => {
      const size = valueLabels.length > 6 ? 4 : valueLabels.length;
      return this.itemSize * size;
    }),
    shareReplay(1)
  );

  ngOnInit() {
    if (this.required) {
      this.dropdownControl.setValidators(Validators.required);
    }

    this.dropdownControlSubscription = this.dropdownControl.valueChanges.subscribe((value) => {
      this.form.get('value')?.setValue(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: Property | PropertyRepresentation) => {
          if (this.dropdownControl.value !== property?.value) {
            this.dropdownControl.setValue(property.value);
          }
        });

      this._disableChanges(this.form.disabled);
      this.valueControl?.registerOnDisabledChange((isDisabled) => this._disableChanges(isDisabled));
    }

    if (changes.requestedPropertyTypeDescription && this.requestedPropertyTypeDescription) {
      this.valueLabels$.next(this.valueLabels);
    }
  }

  ngOnDestroy() {
    this.dropdownControlSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  openedChange(opened: boolean) {
    if (opened) {
      this.viewport.checkViewportSize();
    } else {
      this.form.markAllAsTouched();
    }
  }

  clearDropdownValue() {
    this.clearValue();
  }

  private _disableChanges(isDisabled: boolean) {
    if (isDisabled) {
      this.dropdownControl.disable({emitEvent: false});
    } else {
      this.dropdownControl.enable({emitEvent: false});
    }
  }
}
