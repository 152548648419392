import {Component, Input} from '@angular/core';
import {AgreementDocumentRepresentation, DocumentRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-agreement-document',
  templateUrl: './agreement-document.component.html',
  styles: ['p {margin-top: 0.25rem}'],
})
export class AgreementDocumentComponent {
  @Input() document: DocumentRepresentation;
  @Input() agreementDocument?: AgreementDocumentRepresentation;
  @Input() accepted: boolean;
  @Input() isInAgreement = true;

  get showDocumentStatus() {
    if (this.isInAgreement && (this.accepted || this.agreementDocument?.signed)) {
      return true;
    }

    if (!this.isInAgreement) {
      return true;
    }

    return false;
  }
}
