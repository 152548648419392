import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {ButtonModule} from '@aztrix/components/button';
import {ComponentsThemeModule} from '@aztrix/components/theme';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {AztrixInfoModalComponent} from './aztrix-info-modal/aztrix-info-modal.component';
import {LegalFooterComponent} from './legal-footer/legal-footer.component';
import {PoweredByAztrixComponent} from './powered-by-aztrix.component';

@NgModule({
  declarations: [PoweredByAztrixComponent, LegalFooterComponent, AztrixInfoModalComponent],
  imports: [
    CommonModule,
    TranslatePipe,
    IconsModule,
    MatDialogModule,
    ComponentsThemeModule,
    ButtonModule,
  ],
  exports: [PoweredByAztrixComponent, LegalFooterComponent],
})
export class PoweredByAztrixModule {}
