import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

import {ValueView} from '../../value-view';

@Component({
  selector: 'ax-rich-text-value-view',
  templateUrl: './rich-text-value-view.component.html',
  styleUrls: ['rich-text-value-view.component.scss', '../../value-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextValueViewComponent extends ValueView implements OnChanges {
  @HostBinding('class.ql-snow') snow = true;

  constructor(private _domSanitizer: DomSanitizer, private _changeDetector: ChangeDetectorRef) {
    super();
  }

  safeHtml: SafeHtml;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.safeHtml = this._domSanitizer.bypassSecurityTrustHtml(this.value || '');
      this._changeDetector.markForCheck();
    }
  }
}
