@for (subItem of sortedItems; track subItem.orderIndex) {
  @if (subItem.type === 'PROPERTY') {
    @if (propertyItem(subItem); as propertyItem) {
      <ax-proposal-subscribe-property
        [form]="$any(form.get('properties')?.get(propertyItem.requestedPropertyId || ''))"
        [proposalId]="proposal?.id"
        [proposalProperty]="getProposalProperty(propertyItem)"
        [autocompleteProperties]="
          propertyItem
            | autocompleteProperties
              : agreement
              : (isOwner ? undefined : myProfile)
              : autocompleteProperties
        "
        [language]="language"
        [myProfile]="myProfile"
        [required]="
          isOwner ? false : isRequiredProperty(proposal, propertyItem.requestedPropertyId)
        "
        [isOwner]="isOwner"
        [confirmed]="agreement?.agreementData?.confirmed || false"
        [demo]="demo"
      ></ax-proposal-subscribe-property>
    }
  } @else {
    @if (textTemplateItem(subItem); as textTemplateItem) {
      <ax-proposal-subscribe-text-template
        [form]="form"
        [templateForm]="getTextTemplateForm(textTemplateItem.textTemplateId)"
        [proposal]="proposal"
        [autocompleteProperties]="autocompleteProperties"
        [language]="language"
        [agreement]="agreement"
        [myProfile]="myProfile"
        [isOwner]="isOwner"
        [demo]="demo"
      ></ax-proposal-subscribe-text-template>
    }
  }
}
