import {Pipe, PipeTransform} from '@angular/core';
import {iconForItemType} from '@aztrix/helpers';
import {AlistItemRepresentation, AlistItemWithPropertyRepresentation} from '@aztrix/sdk';
import {ControlsOf, ValuesOf} from '@ngneat/reactive-forms';

@Pipe({name: 'iconForItemType'})
export class IconForItemTypePipe implements PipeTransform {
  transform(
    value:
      | AlistItemWithPropertyRepresentation
      | ValuesOf<ControlsOf<AlistItemRepresentation>>
      | undefined
  ): string {
    return iconForItemType(value);
  }
}
