<div>
  <mat-form-field
    class="c-input"
    [ngClass]="{readonly: readonly}"
    appearance="outline"
    style="width: 100%"
  >
    <mat-label>{{ label }}<span *ngIf="required">&nbsp;*</span></mat-label>
    <mat-chip-grid [disabled]="form.disabled" #chipList aria-label="Chips selection">
      <mat-chip-row
        *ngFor="let chip of chips$ | async"
        [editable]="false"
        [removable]="true"
        (removed)="remove(chip)"
      >
        {{ chip }}
        <ax-icon matChipRemove class="icon" name="close"></ax-icon>
      </mat-chip-row>
      <input
        [axDisableSpecialFileCharacters]="disableSpecialFileCharacters"
        [formControl]="chipsCtrl"
        (focusout)="form.markAsTouched()"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [readonly]="readonly"
        (matChipInputTokenEnd)="add($event)"
        (blur)="blur($event)"
      />
    </mat-chip-grid>
    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
    <mat-error><ng-template [ngTemplateOutlet]="errorsTemplate"></ng-template></mat-error>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let chip of filteredChips$ | async" [value]="chip">
        {{ chip }}
      </mat-option>
    </mat-autocomplete>

    <button
      axButton
      icon-button
      type="button"
      *ngIf="form?.enabled && hasValue && !readonly"
      matSuffix
      (click)="clearValue()"
    >
      <ax-icon class="icon" name="close"></ax-icon>
    </button>
  </mat-form-field>
</div>
