@if (!empty && (documentControls$ | async)?.length) {
  @for (documentControl of documentControls$ | async; track documentControl.value.id) {
    <ax-proposal-document
      [documentControl]="documentControl"
      [proposal]="proposal"
      [proposalLanguage]="proposalLanguage"
      [agreementProperties]="agreementProperties"
      [intermediateAgreementDataCode]="intermediateAgreementDataCode"
      [demo]="demo"
      [agreementDocument]="agreementDocumentById(documentControl)"
    ></ax-proposal-document>
  }
}
