import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ActionFinishedModule} from '@aztrix/components/action-finished';
import {ButtonModule} from '@aztrix/components/button';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {SpinnerModule} from '@aztrix/components/spinner';
import {SubscribeModule} from '@aztrix/subscribe/subscribe';
import {SubscribeFailedSnackbarModule} from '@aztrix/subscribe/subscribe-failed-snackbar';
import {SubscribeThemeModule} from '@aztrix/subscribe/theme';

import {ProposalSubscribeModalComponent} from './proposal-subscribe-modal.component';
import {SubscribeFormComponent} from './subscribe-form/subscribe-form.component';
import {SubscribeSuccessComponent} from './subscribe-form/subscribe-success/subscribe-success.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    SubscribeFormComponent,
    ProposalSubscribeModalComponent,
    SubscribeSuccessComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    TranslatePipe,
    HttpClientModule,
    SubscribeModule,
    ImageSourceModule,
    SpinnerModule,
    SubscribeThemeModule,
    SubscribeFailedSnackbarModule,
    ActionFinishedModule,
  ],
  exports: [ProposalSubscribeModalComponent],
})
export class ProposalSubscribeModalModule {}
