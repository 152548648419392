import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {AbstractProposalPropertyEdit} from '../abstract-proposal-property-edit';

@Component({
  selector: 'ax-proposal-file-edit',
  templateUrl: './proposal-file-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalFileEditComponent extends AbstractProposalPropertyEdit {
  @Input() demo = false;

  get maxFileSize() {
    return this.requestedPropertyType?.fileInfo?.maxSize || 0;
  }
  get acceptTypes() {
    return this.requestedPropertyType?.fileInfo?.types || [];
  }

  get uploadLink() {
    return `r/proposals/${this.proposalId}/uploadFileForAgreement\
/${this.requestedPropertyId}/${this.type}`;
  }
}
