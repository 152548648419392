<div class="section-wrapper" *ngIf="stepForm">
  <p class="notice" *ngIf="(requiredProperties$ | async)?.length">
    {{ 'notice.required-field' | translate }}
  </p>
  <ax-proposal-subscribe-items
    [form]="form"
    [itemForm]="stepForm"
    [proposal]="proposal"
    [autocompleteProperties]="autocompleteProperties || []"
    [language]="language"
    [item]="item"
    [agreement]="agreement"
    [myProfile]="myProfile"
    [isOwner]="isOwner"
    [demo]="demo"
  >
    <ng-content></ng-content>
  </ax-proposal-subscribe-items>
</div>

<ax-proposal-subscribe-errors
  [proposalLanguage]="language"
  [form]="form"
  [stepForm]="typedStepForm"
></ax-proposal-subscribe-errors>

<ng-content select=".failed-error"></ng-content>
