import {LocationStrategy} from '@angular/common';
import {Attribute, Directive, ElementRef, Input, Renderer2, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {deepEqual, siblingsOfItem} from '@aztrix/helpers';
import {
  AlistItemRepresentation,
  AlistItemWithStringRepresentation,
  AlistLanguageRepresentation,
  AlistRepresentation,
} from '@aztrix/sdk';

@Directive({selector: '[ltItemViewLink]'})
export class ItemViewLinkDirective extends RouterLink {
  private _item: AlistItemRepresentation;
  private _alist: AlistRepresentation;
  private _alistLanguage: AlistLanguageRepresentation | undefined;

  @Input() set ltItemViewLink(item: AlistItemRepresentation) {
    this._item = item;
    this._update();
  }

  @Input() set ltItemViewLinkAlist(alist: AlistRepresentation) {
    this._alist = alist;
    this._update();
  }

  @Input() set ltItemViewLinkAlistLanguage(alistLanguage: AlistLanguageRepresentation | undefined) {
    this._alistLanguage = alistLanguage;
    this._update();
  }

  constructor(
    private _sanitizer: DomSanitizer,
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined,
    renderer: Renderer2,
    el: ElementRef,
    locationStrategy?: LocationStrategy
  ) {
    super(router, route, tabIndexAttribute, renderer, el, locationStrategy);
    this.queryParamsHandling = 'merge';
  }

  private _update() {
    this.routerLink = this.getCommand(this._item, this._alistLanguage);
    this.queryParams = {
      list: this._alist?.name,
    };
  }

  getCommand(
    item: AlistItemRepresentation | undefined,
    alistLanguage: AlistLanguageRepresentation | undefined
  ): string[] {
    if (!item || item.format !== AlistItemWithStringRepresentation.FormatEnum.PAGE) {
      return [];
    }

    const title = this.titleOfItem(item);
    const siblings = siblingsOfItem(item, alistLanguage?.items || []) || [];

    if (
      siblings
        .filter((i) => !deepEqual({...i, id: undefined}, {...item, id: undefined}))
        .find((i) => i.title === item.title)
    ) {
      return [`${item.orderIndex}-${title}`];
    } else {
      return [title || ''];
    }
  }

  titleOfItem(item: AlistItemRepresentation | undefined): string | undefined {
    return this._sanitizer.sanitize(SecurityContext.URL, item?.title ?? null)?.toLowerCase();
  }
}
