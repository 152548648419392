import {Component, Input} from '@angular/core';
import {AlistLanguageRepresentation} from '@aztrix/sdk';

import {AbstractItemView} from '../abstract-item-view';

@Component({
  selector: 'lt-item-collapse-view',
  templateUrl: './item-collapse-view.component.html',
  styleUrls: ['./item-collapse-view.component.scss', '../item-view.scss'],
})
export class ItemCollapseViewComponent extends AbstractItemView {
  @Input() alistLanguage: AlistLanguageRepresentation;

  open = false;
}
