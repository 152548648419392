<ng-container [ngSwitch]="item.type">
  <button
    class="form"
    *ngSwitchCase="ItemType.PROPOSAL"
    axButton
    icon-button
    bigButton
    filled
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openSubscribe()"
  >
    <ax-icon class="icon" [name]="item.icon ? item.icon : (item | iconForItemType)"></ax-icon>
  </button>
  <ng-container *ngSwitchCase="ItemType.PROPERTY">
    <a
      *ngIf="property | propertyIsCommunicationRelated"
      class="button ax-social"
      [ngClass]="
        (property | propertyIsSocialRelated) ? 'ax-' + (property.type | lowercase) : 'ax-primary'
      "
      axButton
      icon-button
      filled
      bigButton
      [attr.href]="property | propertyCommunicationHref: undefined : params || undefined"
      [attr.aria-label]="item | itemLabel"
      [target]="property | propertyCommunicationTarget"
      (click)="trackClick()"
    >
      <ax-icon class="icon" [name]="item.icon ? item.icon : (property | propertyIcon)"></ax-icon>
    </a>
  </ng-container>
  <a
    class="link"
    *ngSwitchCase="ItemType.PROPERTIES"
    axButton
    icon-button
    filled
    bigButton
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openPropertiesModal($event)"
  >
    <ax-icon class="icon" [name]="item.icon ? item.icon : 'account-details-outline'"></ax-icon>
  </a>
  <a
    class="link"
    *ngSwitchCase="item.type === ItemType.ITEMS || item.type === ItemType.PRODUCT_CARD"
    axButton
    icon-button
    filled
    bigButton
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openItemsModal($event)"
  >
    <ax-icon class="icon" [name]="item.icon ? item.icon : 'package-variant-closed'"></ax-icon>
  </a>
  <a
    class="link"
    *ngSwitchCase="ItemType.PAYMENT"
    axButton
    icon-button
    filled
    bigButton
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openPayModal($event)"
  >
    <ax-icon class="icon" [name]="item.icon ? item.icon : 'credit-card-outline'"></ax-icon>
  </a>
  <a
    *ngSwitchDefault
    class="link"
    axButton
    icon-button
    filled
    bigButton
    color="primary"
    [attr.href]="data | urlParams: params"
    [attr.aria-label]="item | itemLabel"
    (click)="trackClick()"
  >
    <ax-icon class="icon" [name]="item.icon ? item.icon : 'link'"></ax-icon>
  </a>
</ng-container>
