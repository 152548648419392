<div class="logo">
  <ax-icon class="icon" name="custom:aztrixlogo"></ax-icon>
</div>
<h1 class="dialog-title">{{ 'aztrix.info' | translate }}</h1>
<div class="dialog-content">
  <p>
    {{ 'aztrix.explanation' | translate }}
  </p>
</div>
<div class="button-wrapper">
  <a axButton filled color="primary" class="button" [href]="backendUrl$ | async" target="_blank">
    {{ 'label.moreInfo' | translate }}
    <ax-icon class="icon" name="open-in-new"></ax-icon
  ></a>
</div>
<ax-legal-footer class="legal" *ngIf="legal" color="primary"></ax-legal-footer>
