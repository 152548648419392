import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {SpinnerModule} from '@aztrix/components/spinner';
import {TranslatePipe} from '@aztrix/translate';

// import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
// import {PaymentMethodModule} from '@aztrix/components/payment-method';
import {StripeCheckoutComponent} from './stripe-checkout.component';

@NgModule({
  declarations: [StripeCheckoutComponent],
  imports: [
    CommonModule,
    ButtonModule,
    TranslatePipe,
    SpinnerModule,
    SnackbarModule,
    // MatSelectModule,
    // PaymentMethodModule,
  ],
  exports: [StripeCheckoutComponent],
})
export class StripeCheckoutModule {}
