import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';
import {colorIsLight, contrast} from '@aztrix/helpers';

@Directive({selector: '[axContrast]'})
export class ContrastDirective implements AfterViewInit {
  @Input('axContrast') color = '';

  constructor(private _elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const element: HTMLElement = this._elementRef.nativeElement;
    const backgroundColor = this.getInheritedBackgroundColor(element);

    if (this.color === '') {
      if (colorIsLight(backgroundColor)) {
        if (element.classList.contains('ax-contrast-light')) {
          element.classList.remove('ax-contrast-light');
        }
        if (!element.classList.contains('ax-contrast-dark')) {
          element.classList.add('ax-contrast-dark');
        }
      } else {
        if (element.classList.contains('ax-contrast-dark')) {
          element.classList.remove('ax-contrast-dark');
        }
        if (!element.classList.contains('ax-contrast-light')) {
          element.classList.add('ax-contrast-light');
        }
      }
    } else {
      const colorKey = this._contrastColorKey(backgroundColor);
      element.style.color = `var(${colorKey})`;
    }
  }

  getInheritedBackgroundColor(el: Element) {
    const divElement = document.createElement('div');
    document.head.appendChild(divElement);
    const backgroundColor = getComputedStyle(divElement).backgroundColor;
    document.head.removeChild(divElement);

    return this._traverseParentBackgroundColor(el, backgroundColor);
  }

  private _traverseParentBackgroundColor(el: Element, defaultBackgroundColor: string): string {
    const backgroundColor = getComputedStyle(el).backgroundColor;

    if (backgroundColor !== defaultBackgroundColor) {
      return backgroundColor;
    }
    if (!el.parentElement) {
      return backgroundColor;
    }

    return this._traverseParentBackgroundColor(el.parentElement, defaultBackgroundColor);
  }

  private _contrastColorKey(color: string): string {
    const styles = getComputedStyle(this._elementRef.nativeElement);

    const paletteColors = ['500', '400', '600', '300', '700', '200', '800', '100', '900'].map(
      (ratio) => {
        const key = `--${this.color}-color-${ratio}`;
        return {key, value: styles.getPropertyValue(`--${this.color}-color-${ratio}`)};
      }
    );

    for (const paletteColor of paletteColors) {
      if (contrast(color, paletteColor.value) >= 4.5) {
        return paletteColor.key;
      }
    }
    return colorIsLight(color) ? `--${this.color}-color-900` : `--${this.color}-color-100`;
  }
}
