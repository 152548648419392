import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {CustomValidators} from '@aztrix/helpers';
import {FormControl} from '@ngneat/reactive-forms';
import {first, Observable} from 'rxjs';

@Component({
  selector: 'ax-retry-verification',
  templateUrl: './retry-verification.component.html',
  styleUrls: ['./retry-verification.component.scss'],
})
export class RetryVerificationComponent implements OnInit, OnDestroy {
  @Input() form = new FormControl('', [CustomValidators.validateLoginValue()]);
  @Input() startDate: number;
  @Input() canChangeValue: boolean;
  @Input() verificationType: 'EMAIL' | 'MOBILE';
  @Input() retryVerification: (verificationValue: string) => Observable<any>;

  @Output() verificationValueChanges = new EventEmitter<string>();

  set endTime(time: number) {
    localStorage.setItem('endTime', time.toString());
  }

  get endTime() {
    return +(localStorage.getItem('endTime') || '');
  }

  private _interval: NodeJS.Timeout;
  private _mobileForm = new FormControl<string>();
  private _emailForm = new FormControl<string>();

  constructor(
    private _overlay: OverlayService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._mobileForm.valueChanges.subscribe((value) => {
      this.form.setValue(value);
    });
    this._emailForm.valueChanges.subscribe((value) => {
      this.form.setValue(value);
    });

    const currentEndTime = +(localStorage.getItem('endTime') || '');
    if (currentEndTime && currentEndTime <= 0) {
      this.endTime = this.startDate + 120 * 1000;
    }

    this.startTimer();
  }

  ngOnDestroy(): void {
    clearInterval(this._interval);
  }

  startTimer() {
    this._interval = setInterval(() => {
      this._changeDetector.detectChanges();
    }, 1000);
  }

  resendVerification() {
    this.endTime = Date.now() + 120 * 1000;

    this.retryVerification(<string>this.form.value)
      .pipe(first())
      .subscribe(() => {
        this.verificationValueChanges.next(<string>this.form.value);
        this._overlay.closeModal();
      });
  }
}
