import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AgreementViewModule} from '@aztrix/subscribe/agreement-view';
import {CustomPropertyViewModule} from '@aztrix/subscribe/custom-property-view';

import {ProposalSubscribeOverviewComponent} from './proposal-subscribe-overview.component';

@NgModule({
  declarations: [ProposalSubscribeOverviewComponent],
  imports: [CommonModule, AgreementViewModule],
  exports: [ProposalSubscribeOverviewComponent, CustomPropertyViewModule],
})
export class ProposalSubscribeOverviewModule {}
