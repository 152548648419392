import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {LinkDirective} from './link.directive';

@NgModule({
  declarations: [LinkDirective],
  imports: [RouterModule],
  exports: [LinkDirective],
})
export class LinkModule {}
