import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PropertyErrorsModule} from '@aztrix/components/property-edit';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';

import {ProposalSubscribeErrorsComponent} from './proposal-subscribe-errors.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [ProposalSubscribeErrorsComponent],
  imports: [CommonModule, IconsModule, HelperPipesModule, TranslatePipe, PropertyErrorsModule],
  exports: [ProposalSubscribeErrorsComponent],
})
export class ProposalSubscribeErrorsModule {}
