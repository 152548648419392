import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {PassbaseButtonComponent} from './passbase-button.component';

@NgModule({
  imports: [CommonModule, IconsModule, ButtonModule, TranslatePipe],
  declarations: [PassbaseButtonComponent],
  exports: [PassbaseButtonComponent],
})
export class PassbaseButtonModule {}
