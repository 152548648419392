import {Component, HostBinding, Inject, Input} from '@angular/core';
import {BACKEND_URL} from '@aztrix/environment';
import {TranslateService} from '@aztrix/translate';
import {BehaviorSubject, combineLatest, map} from 'rxjs';

@Component({
  selector: 'ax-legal-footer',
  templateUrl: './legal-footer.component.html',
  styleUrls: ['./legal-footer.component.scss'],
})
export class LegalFooterComponent {
  @Input() @HostBinding('class') color: 'inherit' | 'primary' = 'inherit';
  @Input() legalText = true;

  termsUrl$ = combineLatest([this._backendUrl$, this._translate.lang$]).pipe(
    map(([backendUrl, lang]) => backendUrl + `/policy?type=terms_conditions&lang=${lang}`)
  );
  privacyUrl$ = combineLatest([this._backendUrl$, this._translate.lang$]).pipe(
    map(([backendUrl, lang]) => backendUrl + `/policy?type=privacy_policy_preface&lang=${lang}`)
  );

  constructor(
    private _translate: TranslateService,
    @Inject(BACKEND_URL) private _backendUrl$: BehaviorSubject<string>
  ) {}

  get bugMail() {
    const subject = this._translate.instant('mail.bug.subject');
    return `mailto:${encodeURI('report@aztrix.me')}?subject=${encodeURI(subject)}`;
  }
}
