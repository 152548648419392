import {Component, Input} from '@angular/core';
import {BehaviorSubject, interval} from 'rxjs';
import {first} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';

@Component({
  selector: 'ax-copy-text',
  templateUrl: 'copy-text.component.html',
  styleUrls: ['copy-text.component.scss'],
})
export class CopyTextComponent {
  @Input() value?: string;
  @Input() secret = false;
  @Input() code = false;

  copied$ = new BehaviorSubject(false);

  fakeValue = this._generateRandomFakeValue();

  didClickCopy() {
    this.copied$.next(true);
    interval(5000)
      .pipe(first())
      .subscribe(() => {
        this.copied$.next(false);
      });
  }

  private _generateRandomFakeValue() {
    return uuid() + uuid() + uuid();
  }
}
