import {Component, Input} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';

@Component({
  selector: 'ax-proposal-document',
  templateUrl: './proposal-document.component.html',
  styleUrls: ['./proposal-document.component.scss'],
})
export class ProposalDocumentComponent {
  @Input() documentControl: UntypedFormGroup;
  @Input() agreementDocument?: AgreementDocumentRepresentation;
  @Input() proposal?: ProposalRepresentation;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Input() intermediateAgreementDataCode: string;
  @Input() demo = false;

  get signingRequired(): boolean {
    return (<UntypedFormGroup>this.documentControl).get('signingRequired')?.value;
  }

  get signedControl() {
    return <FormControl>this.documentControl.get('signed');
  }
}
