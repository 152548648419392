import {Component, Input} from '@angular/core';
import {AlistLanguageRepresentation} from '@aztrix/sdk';

import {AbstractItemView} from '../abstract-item-view';

@Component({
  selector: 'lt-item-page-view',
  templateUrl: './item-page-view.component.html',
  styleUrls: ['./item-page-view.component.scss', '../item-view.scss'],
})
export class ItemPageViewComponent extends AbstractItemView {
  @Input() alistLanguage?: AlistLanguageRepresentation;

  pageOpen = false;
}
