import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Params} from '@angular/router';
import {TranslateService} from '@aztrix/translate';
import {
  AlistItemRepresentation,
  AlistItemWithItemsRepresentation,
  AlistItemWithPropertyRepresentation,
  AlistLanguageRepresentation,
  AlistRepresentation,
} from '@aztrix/sdk';
import {map, Observable, of, ReplaySubject, switchMap} from 'rxjs';
import {propertyTypeLabel$} from '@aztrix/helpers';

@Component({
  selector: 'lt-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss'],
})
export class ItemViewComponent implements OnChanges {
  @Input() alist: AlistRepresentation;
  @Input() alistLanguage?: AlistLanguageRepresentation;
  @Input() item: AlistItemRepresentation;

  @HostBinding('class.button') buttonItem = false;

  ItemFormat = AlistItemWithPropertyRepresentation.FormatEnum;
  ItemType = AlistItemWithPropertyRepresentation.TypeEnum;

  item$ = new ReplaySubject<AlistItemRepresentation>(1);

  params$: Observable<Params | null> = this.item$
    .pipe(
      switchMap((item) => {
        if (item.title) {
          return of(item.title);
        }
        if (item.dataType === AlistItemWithPropertyRepresentation.DataTypeEnum.PROPERTY) {
          return propertyTypeLabel$(
            this._translate,
            (<AlistItemWithPropertyRepresentation>item).data?.type
          );
        }
        return of(null);
      })
    )
    .pipe(
      map((campaign) => {
        if (!this.alistLanguage?.settings?.analytics?.google?.active) {
          return {};
        }

        return campaign ? <Params>{utm_campaign: campaign} : null;
      })
    );

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && this.item) {
      this.item$.next(this.item);
      this.buttonItem = this.isButtonFormat(this.item);
    }
  }

  constructor(private _translate: TranslateService) {}

  isButtonFormat(item: AlistItemRepresentation) {
    if (
      item.format === AlistItemWithItemsRepresentation.FormatEnum.BUTTON ||
      item.format === AlistItemWithItemsRepresentation.FormatEnum.PAGE ||
      item.format === AlistItemWithItemsRepresentation.FormatEnum.COLLAPSE
    ) {
      return true;
    }
    return false;
  }
}
