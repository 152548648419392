<button
  class="button"
  axButton
  filled
  fullWidth
  bigButton
  lowercase
  color="primary"
  [ngClass]="{open}"
  [attr.aria-label]="item | itemLabel"
  (click)="open = !open"
>
  <ax-icon class="icon" [name]="item.icon ? item.icon : (item | iconForItemType)"></ax-icon>
  {{ item | itemLabel }}
  <ax-icon class="icon post" name="chevron-down"></ax-icon>
</button>

<div class="collapse" [ngClass]="{open}">
  <lt-item-items-view [alist]="alist" [item]="item" [params]="params"></lt-item-items-view>
</div>
