import {StepperOrientation, StepperSelectionEvent} from '@angular/cdk/stepper';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {ActivatedRoute} from '@angular/router';
import {ConfirmPaymentComponent} from '@aztrix/components/confirm-payment';
import {OverlayService} from '@aztrix/components/overlay';
import {EnvironmentService} from '@aztrix/environment';
import {
  activeStepForms,
  agreementHasEqualValue,
  externalAgreementToExternalAgreementLegacy,
  hasValue,
  skippedSteps,
  stepFormIndex,
  Subscribe,
  SubscribeService,
} from '@aztrix/helpers';
import {
  Agreement,
  AgreementMetadata,
  AgreementVerificationType,
  ExternalAgreement,
  ProposalProperty,
} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  AgreementRepresentation,
  AgreementsService,
  ExternalAgreementRepresentation,
  IntermediateAgreementDataRepresentation,
  MetadataRepresentation,
  PaymentIntentRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  PropertyTypeRepresentation,
  ProposalLanguageItemWithStepRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
  ProposalsService,
  RequestedPropertyRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  interval,
  map,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

const INTERMEDIATE_AGREEMENT_DATA_LOCAL_STORAGE_KEY = 'aztrix:intermediate_agreement_code';

@Component({
  selector: 'ax-proposal-subscribe-steps',
  templateUrl: './proposal-subscribe-steps.component.html',
  styleUrls: ['./proposal-subscribe-steps.component.scss'],
})
export class ProposalSubscribeStepsComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() form: UntypedFormGroup;
  @Input() proposal?: ProposalRepresentation;
  @Input() proposalProperties: RequestedPropertyRepresentation[] | ProposalProperty[];
  @Input() autocompleteProperties: PropertyRepresentation[];
  @Input() language?: ProposalLanguageRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() agreementCode?: string;
  @Input() myProfile?: ProfileRepresentation;
  @Input() subscriberMetadata: AgreementMetadata[] | MetadataRepresentation[];
  @Input() collapsibleGroups = [];
  @Input() orientation: 'VERTICAL' | 'HORIZONTAL' = 'VERTICAL';
  @Input() demo = false;
  @Input() edit = false;
  @Input() external = false;
  @Input() noSubscribeButton = false;
  @Input() subscribeLabel = '';
  @Input() @HostBinding('class.is-open') open = true;
  @Input() @HostBinding('class.no-steps') noSteps = true;

  @Output() subscribed = new EventEmitter<ExternalAgreementRepresentation>();

  @ViewChildren(MatStepper) steppers: QueryList<MatStepper>;

  private _form$ = new ReplaySubject<UntypedFormGroup>(1);
  private _proposal$ = new ReplaySubject<ProposalRepresentation>(1);
  private _language$ = new ReplaySubject<ProposalLanguageRepresentation>(1);
  private _afterViewInit$ = new ReplaySubject<void>(1);

  private _subscriptions = new Subscription();

  intermediateAgreementData: IntermediateAgreementDataRepresentation | undefined;

  loading = true;
  ready = false;
  subscribeError = false;

  createdAgreementAfterPayment: AgreementRepresentation | null = null;

  loadingSubscribe$ = new BehaviorSubject<boolean>(false);

  redirectedSuccess$ = new BehaviorSubject<boolean>(false);

  showSteps$ = this._language$.pipe(
    map((language) => language.steps && language.steps.length),
    shareReplay(1)
  );

  items$ = this._language$.pipe(
    map((language) =>
      [...(language.items || [])].sort((i1, i2) =>
        (i1.orderIndex ?? Infinity) > (i2.orderIndex ?? Infinity) ? 1 : -1
      )
    ),
    shareReplay(1)
  );

  payed$: Observable<boolean> = combineLatest([this._proposal$, this.redirectedSuccess$]).pipe(
    map(([proposal, payed]) => !!proposal.product && payed)
  );

  formValueChanges$ = this._form$.pipe(
    switchMap((form) => form.valueChanges.pipe(startWith(form.value)))
  );

  stepper$ = this._afterViewInit$.pipe(
    switchMap(() => this.steppers?.changes.pipe(startWith(this.steppers)) || of(undefined)),
    map((stepperList) => <MatStepper>stepperList?.first)
  );

  stepperSelectionChange$ = this.stepper$.pipe(
    switchMap(
      (stepper) =>
        stepper?.selectionChange.pipe(
          startWith(<StepperSelectionEvent>{selectedStep: stepper.selected})
        )
    )
  );

  activeStepForms$ = combineLatest([this._language$, this.items$, this.formValueChanges$]).pipe(
    map(([language, items]) => activeStepForms(language, this.form, items)),
    shareReplay(1)
  );

  proposalPaymentsEnabled$ = this._environment
    .toggle$('PROPOSAL_PAYMENT_ENABLED')
    .pipe(shareReplay(1));

  constructor(
    @Optional() private _route: ActivatedRoute,
    private _elementRef: ElementRef,
    private _proposalsApiService: ProposalsService,
    private _overlayService: OverlayService,
    private _changeDetector: ChangeDetectorRef,
    private _environment: EnvironmentService,
    private _subscribeService: SubscribeService,
    private _agreementsService: AgreementsService
  ) {
    if (this._route) {
      this._route.queryParamMap.pipe(
        map((paramMap) => paramMap.get('redirect_status') === 'succeeded')
      );
    }

    this.showSteps$.pipe(first()).subscribe((showSteps) => {
      showSteps ? (this.noSteps = false) : (this.noSteps = true);
    });
  }

  ngOnInit(): void {
    this._subscriptions = new Subscription();

    if (this.demo || this.edit) {
      this.loading = false;
    } else {
      this.stepper$.subscribe((stepper) => {
        if (!stepper && !!this.form) {
          this._postIntermediateAgreementData();
        }
      });
      // polling on document status changes
      this._subscriptions.add(
        combineLatest([this.stepper$, interval(5000)])
          .pipe(
            filter(([stepper]) => {
              if (!stepper) {
                return false;
              }
              const activeSteps = activeStepForms(this.language, this.form, this.items);
              if (!this.stepsForm || !activeSteps) {
                return false;
              }

              return this.hasDocuments && stepper.selectedIndex >= activeSteps.length;
            }),
            startWith(0),
            switchMap(() => this._fetchIntermediateAgreementData$()),
            filter((intermediateAgreementData) => !!intermediateAgreementData)
          )
          .subscribe((intermediateAgreementData) => {
            this.loading = false;

            if (
              intermediateAgreementData &&
              (!this.intermediateAgreementData?.creationTimestamp ||
                (intermediateAgreementData?.creationTimestamp || 0) >=
                  this.intermediateAgreementData.creationTimestamp)
            ) {
              this.intermediateAgreementData = intermediateAgreementData;
              Subscribe.prefillForm(this.form, intermediateAgreementData?.agreementProperties);
              Subscribe.prefillFormDocuments(this.form, intermediateAgreementData?.documents);
            }
          })
      );

      this._subscriptions.add(
        combineLatest([this._fetchIntermediateAgreementData$(), this.stepper$])
          .pipe(first())
          .subscribe(([intermediateAgreementData, stepper]) => {
            if (intermediateAgreementData?.documents?.length) {
              // go through all the steps in order to avoid the stepper thinking
              // a step is not interacted with
              const activeSteps = activeStepForms(this.language, this.form, this.items);
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              for (const _iterator of Array(activeSteps.length + 1).keys()) {
                stepper?.next();
              }
            }
          })
      );

      this._subscriptions.add(
        this.stepperSelectionChange$.subscribe((event) => {
          const activeSteps = activeStepForms(this.language, this.form, this.items);
          if ((!event.previouslySelectedStep?.stepControl || !activeSteps) && this.hasSteps) {
            return;
          }
          if (
            (event.selectedIndex === activeSteps.length &&
              event.previouslySelectedIndex === activeSteps.length - 1) ||
            !this.hasSteps
          ) {
            this._postIntermediateAgreementData();
          }
        })
      );

      combineLatest([this.payed$.pipe(first()), this._fetchIntermediateAgreementData$()]).subscribe(
        ([payed, intermediateAgreementData]) => {
          if (payed) {
            this.confirmSubscribe(false, intermediateAgreementData);
          }
        }
      );
    }

    this._subscriptions.add(
      this.formValueChanges$.subscribe(() => {
        const skipped = skippedSteps(this.language, this.form);

        const stepsForm = <UntypedFormArray>this.form?.get('steps');
        for (const stepForm of stepsForm?.controls || []) {
          if (skipped.find((s) => s.id === stepForm.get('id')?.value)) {
            stepForm.disable({emitEvent: false});
          } else {
            stepForm.enable({emitEvent: false});
          }
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this._form$.next(this.form);
    }
    if (changes.proposal && this.proposal) {
      this._proposal$.next(this.proposal);
    }
    if (changes.language && this.language) {
      this._language$.next(this.language);
    }
  }

  ngAfterViewInit() {
    this._afterViewInit$.next();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  get stepperOrientation() {
    return <StepperOrientation>this.orientation.toLocaleLowerCase();
  }

  get stepsForm(): UntypedFormArray {
    return <UntypedFormArray>this.form?.get('steps');
  }

  get documentsForm(): UntypedFormArray {
    return <UntypedFormArray>this.form?.get('documents');
  }

  get sendToEmailForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.form?.get('sendToEmail');
  }

  get hasSteps() {
    return !!this.stepsForm?.controls?.length;
  }

  get hasDocuments(): boolean {
    return !!this.documentsForm?.controls?.length;
  }

  get agreementDocuments() {
    return this.intermediateAgreementData?.documents;
  }

  get label() {
    if (this.subscribeLabel) {
      return this.subscribeLabel;
    } else {
      return this.agreement?.agreementData?.confirmed ? 'label.subscribe' : 'label.confirm';
    }
  }

  get subscribeButtonLabel$() {
    if (this.hasProducts) {
      return this.proposalPaymentsEnabled$.pipe(
        map((result) => (result ? 'label.pay.confirm' : this.label))
      );
    } else {
      return of(this.label);
    }
  }

  get hasProducts(): boolean {
    return !!this.proposal?.product;
  }

  get items() {
    return this.language?.items;
  }

  getItemForStepForm(stepForm: UntypedFormGroup) {
    return <ProposalLanguageItemWithStepRepresentation>(
      this.language?.items?.find(
        (i) => (<ProposalLanguageItemWithStepRepresentation>i).stepId === stepForm.get('id')?.value
      )
    );
  }

  outOfRange(stepForm: UntypedFormGroup): boolean {
    if (!this.steppers?.first) {
      return false;
    }

    const stepForms = activeStepForms(this.language, this.form, this.items);
    const selectedStepForm = <UntypedFormGroup>this.steppers.first.selected?.stepControl;
    const currentIndex = stepFormIndex(selectedStepForm, this.language?.items || []);
    const stepIndex = stepFormIndex(stepForm, this.language?.items || []);

    return (
      (currentIndex === undefined && (stepIndex || 0) < stepForms.length) ||
      Math.abs((currentIndex || 0) - (stepIndex || 0)) > 1
    );
  }

  firstEmail() {
    const propertyControls = this.stepsForm.controls.flatMap((stepForm) =>
      Object.values((<UntypedFormGroup>stepForm.get('properties')).controls)
    );

    const properties = propertyControls.map((propertyControl) => propertyControl?.value);

    return this._getFirstAgreementPropertyOfType(
      {properties},
      RequestedPropertyTypeRepresentation.TypeEnum.EMAIL
    );
  }

  next(stepForm: UntypedFormGroup) {
    stepForm.markAllAsTouched();
  }

  documentsNext() {
    this.documentsForm.markAllAsTouched();
  }

  addAgreement() {
    this._changeDetector.detectChanges();
    this.form?.markAllAsTouched();

    if (this.form?.invalid || this.loadingSubscribe$.value === true) {
      return;
    }

    this.loadingSubscribe$.next(true);

    this.subscribeError = false;

    if (this.hasProducts) {
      this.proposalPaymentsEnabled$
        .pipe(
          first(),
          mergeMap((paymentsEnabled: boolean) => {
            const code = localStorage.getItem(INTERMEDIATE_AGREEMENT_DATA_LOCAL_STORAGE_KEY);
            if (paymentsEnabled && code) {
              return <Observable<PaymentIntentRepresentation>>(
                this._proposalsApiService.getIntermediateAgreementDataPaymentIntent(
                  this.proposal?.id || '',
                  code
                )
              );
            } else {
              return of(null);
            }
          })
        )
        .subscribe((result: PaymentIntentRepresentation | null) => {
          if (result === null) {
            this.confirmSubscribe(true);
          } else {
            this._overlayService.createModal(this._elementRef, ConfirmPaymentComponent, {
              title: 'label.pay',
              shadowDOM: false,
              closeOnBackdropClick: false,
              init: (modal) => {
                this.loadingSubscribe$.next(false);
                if (this.proposal?.product) {
                  modal.productId = this.proposal.product;
                }
                modal.paymentIntent$.next(result);
              },
            });
          }
        });
    } else {
      const formIntermediateAgreementData = Subscribe.formIntermediateAgeementData(
        this.form?.value,
        this.language?.languageCode || 'nl'
      );
      this.confirmSubscribe(true, formIntermediateAgreementData);
    }
  }

  confirmSubscribe(
    navigateToAgreement: boolean,
    intermediateAgreementData: IntermediateAgreementDataRepresentation | null = null
  ) {
    if (intermediateAgreementData) {
      this._subscribeFunction$(intermediateAgreementData).subscribe({
        next: (result) => {
          this._afterSubscribe(result, navigateToAgreement);
        },
        error: () => {
          this.subscribeError = true;
          this.loadingSubscribe$.next(false);
        },
      });
    } else {
      this._fetchIntermediateAgreementData$()
        .pipe(
          mergeMap((intermediateAgreementData) => {
            return this._subscribeFunction$(intermediateAgreementData);
          })
        )
        .subscribe({
          next: (result) => {
            this._afterSubscribe(result, navigateToAgreement);
          },
          error: () => {
            this.subscribeError = true;
            this.loadingSubscribe$.next(false);
          },
        });
    }
  }

  private _subscribeFunction$(
    intermediateAgreementData: IntermediateAgreementDataRepresentation | null | undefined
  ): Observable<Agreement | AgreementRepresentation | ExternalAgreement | undefined> {
    const intermediateAgreementDataCode = intermediateAgreementData?.code;

    const agreementProperties =
      intermediateAgreementDataCode && this.form
        ? []
        : <AgreementPropertyRepresentation[]>(
            Subscribe.formAgreementProperties(this.form?.value).filter((ap) =>
              hasValue(ap.property)
            )
          );

    const agreement = {
      agreementProperties: agreementProperties,
      subscriberMetadata: this.subscriberMetadata,
      language: this.language?.languageCode,
      verificationValue: this._getVerification(),
      sendManageAgreementEmail:
        this.language?.askToSendEmail && this.sendToEmailForm?.get('checked')?.value
          ? this.sendToEmailForm?.get('email')?.value.value
          : undefined,
      intermediateAgreementDataCode,
    };

    if (
      agreementHasEqualValue(<AgreementRepresentation>this.agreement, agreement) &&
      this.proposal?.verification !== ProposalRepresentation.VerificationEnum.NONE
    ) {
      if (!this.agreement?.id) {
        return throwError(() => new Error('no agreement'));
      }
      return this._agreementsService
        .sendVerification(
          this.agreement.id,
          this.agreement.agreementCode,
          agreement.verificationValue,
          this.agreement.agreementData?.language
        )
        .pipe(
          // resend verification timer
          tap(() =>
            this.proposal?.verification
              ? localStorage.setItem('endTime', (Date.now() + 120 * 1000).toString())
              : null
          )
        );
    } else {
      return this._subscribeService.subscribe(
        agreement,
        this.proposal?.id || '',
        this.agreementCode,
        this.external
      );
    }
  }

  private _afterSubscribe(
    result: Agreement | AgreementRepresentation | ExternalAgreement | null | undefined,
    navigateToAgreement: boolean
  ) {
    this.ready = true;
    this.loadingSubscribe$.next(false);
    localStorage.removeItem(INTERMEDIATE_AGREEMENT_DATA_LOCAL_STORAGE_KEY);

    if (!result) {
      return;
    }

    if (this.external && 'agreement' in result) {
      const externalAgreement = <ExternalAgreement>result;
      this.agreementCode = externalAgreement?.agreement?.agreementCode;
      this.subscribed.emit(
        <ExternalAgreementRepresentation>(
          (<unknown>externalAgreementToExternalAgreementLegacy(externalAgreement))
        )
      );
    } else {
      const agreement = <AgreementRepresentation>result;
      this.agreementCode = agreement.agreementCode;
      if (navigateToAgreement) {
        this.subscribed.emit(
          <ExternalAgreementRepresentation>(<unknown>externalAgreementToExternalAgreementLegacy({
            proposal: this.proposal,
            agreement: <Agreement>agreement,
          }))
        );
      } else {
        this.createdAgreementAfterPayment = agreement;
        this._changeDetector.detectChanges();
      }
    }
  }

  private _getVerification() {
    if (
      !this.proposal?.verification ||
      this.proposal.verification === AgreementVerificationType.NONE ||
      this.proposal.verification === AgreementVerificationType.IDENTITY
    ) {
      return undefined;
    }

    if (
      this.proposal.verification === AgreementVerificationType.EMAIL ||
      this.proposal.verification === AgreementVerificationType.MOBILE
    ) {
      if (this.form?.get('verification')?.value) {
        return this.form?.get('verification')?.get('property')?.value.value;
      } else {
        return undefined;
      }
    }
  }

  private _postIntermediateAgreementData() {
    const intermediateAgreementData = Subscribe.formIntermediateAgeementData(
      this.form?.value,
      this.language?.languageCode || 'nl'
    );

    if (
      !Subscribe.intermediateAgeementDataHasEqualValue(
        intermediateAgreementData,
        this.intermediateAgreementData
      ) &&
      !this.intermediateAgreementData
    ) {
      this._proposalsApiService
        .createIntermediateAgreementData(
          this.proposal?.id || '',
          <IntermediateAgreementDataRepresentation>intermediateAgreementData
        )
        .subscribe((result) => {
          this.intermediateAgreementData = <IntermediateAgreementDataRepresentation>result;
          this.loading = false;
          localStorage.setItem(INTERMEDIATE_AGREEMENT_DATA_LOCAL_STORAGE_KEY, result.code || '');
          this._changeDetector.detectChanges();
        });
    }
  }

  private _getFirstAgreementPropertyOfType(value: any, type: PropertyTypeRepresentation.NameEnum) {
    return Subscribe.formAgreementProperties(value)
      .filter((ap) => hasValue(ap.property))
      .filter((prop) => prop.property?.type === type)[0]?.property;
  }

  private _fetchIntermediateAgreementData$() {
    return this._proposal$.pipe(
      mergeMap((proposal) => {
        const code = localStorage.getItem(INTERMEDIATE_AGREEMENT_DATA_LOCAL_STORAGE_KEY);
        if (code) {
          return this._proposalsApiService
            .getIntermediateAgreementData(proposal.id || '', code)
            .pipe(
              map((intermediateAgreementData) => {
                this.intermediateAgreementData = <IntermediateAgreementDataRepresentation>(
                  intermediateAgreementData
                );
                return this.intermediateAgreementData;
              })
            );
        } else {
          return of(null);
        }
      }),
      first()
    );
  }

  closeModal() {
    this._overlayService.closeModal();
  }
}
