<div class="property" *ngIf="language?.documents?.length">
  <div class="title pdf-legal">{{ 'label.legal' | translate }}</div>
  <div class="document pdf-legal" *ngFor="let document of language?.documents || []">
    <ax-agreement-document
      [document]="document"
      [accepted]="accepted"
      [isInAgreement]="isInAgreement"
      [agreementDocument]="
        agreementDocumentByProposalDocumentId(document.id || '', agreementDocuments)
      "
    ></ax-agreement-document>
  </div>
</div>
