<ax-value-edit
  type="DATE"
  [form]="dateControl"
  [name]="name"
  [autofocus]="autofocus"
  [label]="label"
  [hint]="hint"
  [required]="required"
  [icon]="showIcon ? (customIcon ? customIcon : 'calendar') : undefined"
  [errorsTemplate]="errors"
  [readonly]="readonly"
></ax-value-edit>

<ng-template #errors>
  <ax-property-error *ngIf="form.touched" [form]="form" [label]="label"></ax-property-error>
</ng-template>
