<ng-container [ngSwitch]="item.type">
  <h2 class="header" *ngSwitchCase="ItemType.HEADER">{{ item.title ?? item.data }}</h2>
  <lt-item-text-view *ngSwitchCase="ItemType.TEXT" [item]="item"></lt-item-text-view>
  <ng-container *ngSwitchDefault [ngSwitch]="item.format">
    <lt-item-icon-view
      *ngSwitchCase="ItemFormat.ICON"
      [item]="item"
      [params]="params$ | async"
      [alist]="alist"
    ></lt-item-icon-view>
    <lt-item-link-view
      *ngSwitchCase="ItemFormat.LINK"
      [item]="item"
      [params]="params$ | async"
      [alist]="alist"
    ></lt-item-link-view>
    <lt-item-page-view
      *ngSwitchCase="ItemFormat.PAGE"
      [item]="item"
      [alist]="alist"
      [alistLanguage]="alistLanguage"
    ></lt-item-page-view>
    <lt-item-collapse-view
      *ngSwitchCase="ItemFormat.COLLAPSE"
      [item]="item"
      [params]="params$ | async"
      [alist]="alist"
    ></lt-item-collapse-view>
    <lt-item-property-view
      *ngSwitchCase="ItemFormat.PROPERTY"
      [item]="item"
      [params]="params$ | async"
      [alist]="alist"
    ></lt-item-property-view>
    <lt-item-button-view
      *ngSwitchDefault
      [item]="item"
      [params]="params$ | async"
      [alist]="alist"
    ></lt-item-button-view>
  </ng-container>
</ng-container>
