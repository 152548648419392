<div class="container">
  <div #checkoutElement></div>

  <div class="h-button-wrapper">
    <ax-button (click)="cancel.next()" [loading]="loading$ | async">{{
      'label.cancel' | translate
    }}</ax-button>
    <ax-button filled color="primary" (click)="confirmPayment()" [loading]="loading$ | async">{{
      'label.pay' | translate
    }}</ax-button>
  </div>

  <ax-snackbar [open]="!!error" color="red" (didClose)="error = undefined">{{ error }}</ax-snackbar>
</div>
