@if ((endTime | secondsFromNow) > 0) {
  <p>
    {{ 'agreement.verification.retry.message' | translate }}
  </p>
  <p>
    {{
      'agreement.verification.retry.message.time' | translate: {seconds: endTime | secondsFromNow}
    }}
  </p>
} @else if (canChangeValue) {
  <ax-value-edit
    [form]="form"
    [autofocus]="false"
    [required]="true"
    [hint]="
      'proposal.agreement.verification.property'
        | translate: {propertyType: verificationType | propertyTypeLabel}
    "
    [type]="
      verificationType.includes('MOBILE')
        ? 'PHONE'
        : verificationType === 'EMAIL'
          ? 'EMAIL'
          : 'PHONE'
    "
    [label]="verificationType | propertyTypeLabel"
    [errorsTemplate]="errorsTemplate"
  ></ax-value-edit>
  <div class="h-button-wrapper">
    <button
      [disabled]="form.invalid"
      axButton
      filled
      (click)="resendVerification()"
      color="primary"
    >
      {{ 'agreement.verification.send' | translate }}
    </button>
  </div>

  <ng-template #errorsTemplate>
    <ax-login-value-form-error
      *ngIf="form.touched"
      [form]="form"
      [propertyType]="verificationType"
    ></ax-login-value-form-error>
  </ng-template>
} @else {
  <p>
    {{ 'agreement.verification.retry.message.time.passed' | translate }}
  </p>
  <div class="h-button-wrapper">
    <button
      axButton
      filled
      color="primary"
      [disabled]="(endTime | secondsFromNow) > 0"
      (click)="resendVerification()"
    >
      {{ 'label.retry' | translate }}
    </button>
  </div>
}
