import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ButtonModule} from '@aztrix/components/button';
import {HelperPipesModule} from '@aztrix/helpers';
import {Property, ProposalProperty} from '@aztrix/models';
import {ProfileRepresentation, ProposalLanguageRepresentation} from '@aztrix/sdk';

import {ProposalSubscribePropertyComponent} from '../../proposal-subscribe-property/proposal-subscribe-property.component';
import {TranslatePipe} from '@aztrix/translate';

@Component({
  selector: 'ax-proposal-subscribe-property-edit',
  templateUrl: './proposal-subscribe-property-edit.component.html',
  styleUrls: ['./proposal-subscribe-property-edit.component.scss'],
  standalone: true,
  imports: [ProposalSubscribePropertyComponent, ButtonModule, HelperPipesModule, TranslatePipe],
})
export class ProposalSubscribePropertyEditComponent {
  @Input() form: UntypedFormGroup;
  @Input() proposalId: string;
  @Input() property: ProposalProperty | undefined;
  @Input() autocompleteProperties: Property[];
  @Input() language?: ProposalLanguageRepresentation;
  @Input() myProfile?: ProfileRepresentation;
  @Input() required = false;
  @Input() confirmed = false;
  @Input() isOwner = false;
  @Input() demo = false;
  @Input() showIcon = true;

  @Output() cancel = new EventEmitter<void>();
}
