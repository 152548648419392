<div class="step-title" *ngIf="step?.title">
  <h3>{{ step?.title }}</h3>
  <p>{{ step?.description }}</p>
</div>
<ax-agreement-property-view
  *ngFor="let agreementProperty of properties$ | async"
  [proposal]="proposal"
  [agreementProperty]="agreementProperty"
  [language]="language"
  [myProfile]="myProfile"
  [showOutOfSync]="showOutOfSync"
  [agreementActive]="agreementActive"
  [agreementConfirmed]="agreementConfirmed"
  (addProperty)="addProperty.next($event)"
  (editProperty)="editProperty.next($event)"
></ax-agreement-property-view>
