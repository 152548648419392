@for (items of groupedItems; track items.id) {
  <div
    class="container"
    [ngClass]="{
      vertical: items[0]?.format === ItemFormat.ICON
    }"
  >
    <ng-container *ngFor="let item of items">
      <lt-item-view
        class="item"
        *ngIf="item | canBeDisplayed"
        [alist]="alist"
        [alistLanguage]="alistLanguage"
        [item]="item"
      ></lt-item-view>
    </ng-container>
  </div>
}

@if (item?.type === ItemType.PROPERTIES) {
  <button
    axButton
    class="download"
    filled
    color="primary"
    [attr.aria-label]="'vcf.download' | translate"
    (click)="downloadItemAsVcf()"
  >
    <ax-icon class="icon" name="download-outline"></ax-icon>
    <span>{{ 'vcf.download' | translate }}</span>
  </button>
}
