<div class="overview-title">
  <ng-content></ng-content>
</div>
<div class="property">
  <ax-agreement-data
    [myProfile]="myProfile"
    [proposal]="proposal"
    [language]="language"
    [agreementDocuments]="agreementDocuments"
    [agreementProperties]="agreementProperties"
    [showOutOfSync]="showOutOfSync"
    [agreementActive]="agreementActive"
    [agreementConfirmed]="agreementConfirmed"
    [isInAgreement]="isInAgreement"
    (addProperty)="addProperty.emit($event)"
  ></ax-agreement-data>
</div>
