import {Component, Inject, Input} from '@angular/core';
import {BACKEND_URL} from '@aztrix/environment';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ax-aztrix-info-modal',
  templateUrl: `aztrix-info-modal.component.html`,
  styleUrls: ['aztrix-info-modal.component.scss'],
})
export class AztrixInfoModalComponent {
  @Input() legal = true;

  constructor(@Inject(BACKEND_URL) public backendUrl$: BehaviorSubject<string>) {}
}
