import {Directive, Input} from '@angular/core';
import {
  CustomFieldValueLabel,
  Property,
  ProposalPropertyType,
  ProposalPropertyTypeDescription,
} from '@aztrix/models';
import {
  CustomFieldValueLabelRepresentation,
  RequestedPropertyTypeDescriptionRepresentation,
} from '@aztrix/sdk';

@Directive()
export abstract class AbstractCustomPropertyView {
  @Input() property: Property;
  @Input() requestedPropertyType?: ProposalPropertyType;
  @Input() requestedPropertyTypeDescription?:
    | ProposalPropertyTypeDescription
    | RequestedPropertyTypeDescriptionRepresentation;
  @Input() isInListView = false;

  get valueLabels(): CustomFieldValueLabel[] | CustomFieldValueLabelRepresentation[] {
    return this.requestedPropertyTypeDescription?.customFieldDescription?.valueLabels || [];
  }
}
