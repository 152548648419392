import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import {ButtonModule} from '@aztrix/components/button';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {SpinnerModule} from '@aztrix/components/spinner';
import {StripeCheckoutModule} from '@aztrix/components/stripe-checkout';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ConfirmPaymentComponent} from './confirm-payment.component';

@NgModule({
  declarations: [ConfirmPaymentComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatStepperModule,
    TranslatePipe,
    ButtonModule,
    IconsModule,
    PropertyEditModule,
    StripeCheckoutModule,
    SpinnerModule,
  ],
  exports: [ConfirmPaymentComponent],
})
export class ConfirmPaymentModule {}
