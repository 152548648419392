<ax-action-finished
  (didClickButton)="closeModal()"
  bodyTitle="subscribe.finished.subtitle"
  [showButton]="false"
></ax-action-finished>
<div class="view">
  <a axButton outline target="_blank" [href]="agreementLink$ | async">{{
    'agreement.view' | translate
  }}</a>
</div>
