import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Agreement, AgreementData, Proposal} from '@aztrix/models';
import {
  AgreementDataRepresentation,
  AgreementRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {combineLatest, map, Observable, ReplaySubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-history-action',
  templateUrl: './agreement-history-action.component.html',
  styleUrls: ['./agreement-history-action.component.scss'],
})
export class AgreementHistoryActionComponent implements OnChanges {
  @Input() proposal?: Proposal | ProposalRepresentation;
  @Input() agreement?: Agreement | AgreementRepresentation;
  @Input() agreementData?: AgreementData | AgreementDataRepresentation;
  @Input() historyData: AgreementData[];
  @Input() isExternal = false;

  private _agreement$ = new ReplaySubject<Agreement | AgreementRepresentation>(1);
  private _agreementData$ = new ReplaySubject<AgreementData | AgreementDataRepresentation>(1);

  isHistoryAgreement$: Observable<boolean> = combineLatest([
    this._agreementData$,
    this._agreement$,
  ]).pipe(
    map(
      ([agreementData, agreement]) =>
        agreementData.startTimestamp !== agreement?.agreementData?.startTimestamp
    )
  );

  canViewHistory$: Observable<boolean> = combineLatest([
    this._agreementData$,
    this._agreement$,
  ]).pipe(map(([agreementData, agreement]) => !!agreementData || !!agreement.expirationTimestamp));

  ngOnChanges(changes: SimpleChanges) {
    if (this.agreement && changes.agreement) {
      this._agreement$.next(this.agreement);
    }

    if (this.agreementData && changes.agreementData) {
      this._agreementData$.next(this.agreementData);
    }
  }
}
