import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UrlTransformerService} from '@aztrix/environment';
import {ErrorType} from '@aztrix/models';
import {BehaviorSubject, catchError, first, throwError} from 'rxjs';

import {AbstractProposalDocument} from '../../abstract-proposal-document';

@Component({
  selector: 'ax-proposal-document-sign-button',
  templateUrl: './proposal-document-sign-button.component.html',
  styleUrls: ['../document.scss'],
})
export class ProposalDocumentSignButtonComponent
  extends AbstractProposalDocument
  implements OnChanges
{
  @Input() intermediateAgreementDataCode: string;

  loading$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<ErrorType | null>(null);

  constructor(
    private http: HttpClient,
    private _urlTransformer: UrlTransformerService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.agreementDocument && changes.agreementDocument) {
      this.form.setValue(this.agreementDocument.signed);
    }
  }

  signDocument() {
    if (!this.demo) {
      this.loading$.next(true);

      const transformedUrl = this._urlTransformer.transformUrl(
        `r/proposals/${this.proposal?.id}/intermediateAgreementData/${this.intermediateAgreementDataCode}/documents/${this.document.id}/sign`
      );

      this.http
        .get(transformedUrl, {
          params: {_noAuth: 'true', redirectUrl: window.location.href},
          responseType: 'text',
        })
        .pipe(
          first(),
          catchError((err: HttpErrorResponse) => {
            if (
              err instanceof HttpErrorResponse &&
              err.error === ErrorType.AGREEMENT_DOCUMENT_NOT_FOUND
            ) {
              this.error$.next(err.error);
            } else {
              this.error$.next(ErrorType.SOMETHING_WENT_WRONG);
            }
            this.loading$.next(false);
            return throwError(() => err);
          })
        )
        .subscribe((signLink: string) => {
          window.location.href = signLink;
          this.loading$.next(false);
        });
    } else {
      this.form.setValue(true);
    }
  }

  get errorMessageTranslated(): string {
    return `error.${this.error$.value}.message`;
  }

  get signIcon(): string {
    if (this.error$?.value) {
      return 'cached';
    }
    if (this.form?.value) {
      return 'check';
    }
    return 'file-document-edit';
  }

  get signLabelKey(): string {
    if (this.error$?.value) {
      return 'label.retry';
    }
    if (this.form?.value) {
      return 'label.signed';
    }
    return 'label.sign';
  }
}
