import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';

@Component({
  selector: 'ax-proposal-documents-step',
  templateUrl: './proposal-documents-step.component.html',
  styleUrls: ['../../section.scss'],
})
export class ProposalDocumentsStepComponent {
  @Input() form: UntypedFormGroup;
  @Input() proposal?: ProposalRepresentation;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Input() agreementDocuments?: AgreementDocumentRepresentation[];
  @Input() intermediateAgreementDataCode: string;
  @Input() demo = false;
}
