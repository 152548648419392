import {Component, Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {LOCALE, TranslateService} from '@aztrix/translate';
import {Locale, Proposal, ProposalLanguage} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  ProposalLanguageItemWithTextTemplateRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {
  BehaviorSubject,
  combineLatest,
  mergeMap,
  Observable,
  ReplaySubject,
  shareReplay,
} from 'rxjs';
import {TemplateParser} from '@aztrix/helpers';

@Component({
  selector: 'ax-agreement-template-view',
  templateUrl: './agreement-template-view.component.html',
  styleUrls: ['./agreement-template-view.component.scss'],
})
export class AgreementTemplateViewComponent implements OnChanges {
  @Input() proposal?: Proposal | ProposalRepresentation;
  @Input() language?: ProposalLanguage | ProposalLanguageRepresentation;
  @Input() item: ProposalLanguageItemWithTextTemplateRepresentation;
  @Input() agreementProperties?: AgreementPropertyRepresentation[];

  private _proposal$ = new ReplaySubject<Proposal | ProposalRepresentation>(1);
  private _language$ = new ReplaySubject<ProposalLanguage | ProposalLanguageRepresentation>(1);
  private _item$ = new ReplaySubject<ProposalLanguageItemWithTextTemplateRepresentation>(1);
  private _agreementProperties$ = new ReplaySubject<AgreementPropertyRepresentation[]>(1);

  constructor(
    private _translate: TranslateService,
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.proposal && this.proposal) {
      this._proposal$.next(this.proposal);
    }
    if (changes.language && this.language) {
      this._language$.next(this.language);
    }
    if (changes.item && this.item) {
      this._item$.next(this.item);
    }
    if (changes.agreementProperties && this.agreementProperties) {
      this._agreementProperties$.next(this.agreementProperties);
    }
  }

  parsedTextTemplate$: Observable<string | undefined> = combineLatest([
    this._item$,
    this._proposal$,
    this._language$,
    this._locale$,
    this._agreementProperties$,
  ]).pipe(
    mergeMap(([item, proposal, language, locale, agreementProperties]) => {
      const template = (language.textTemplates || []).find((tt) => tt.id === item.textTemplateId);
      return TemplateParser.parse$(
        template?.template,
        this._translate,
        proposal,
        language,
        locale,
        agreementProperties
      );
    }),
    shareReplay(1)
  );
}
