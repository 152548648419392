import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ButtonModule} from '@aztrix/components/button';
import {LoginValueFormErrorModule} from '@aztrix/components/login-value-form-error';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {TranslatePipe} from '@aztrix/translate';

import {OtpInputComponent} from './otp-input/otp-input.component';
import {OtpVerificationComponent} from './otp-verification.component';
import {RetryVerificationComponent} from './retry-verification/retry-verification.component';
import {SecondsFromNowPipe} from './seconds-from-now.pipe';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [
    OtpVerificationComponent,
    RetryVerificationComponent,
    OtpInputComponent,
    SecondsFromNowPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HelperPipesModule,
    TranslatePipe,
    ButtonModule,
    ValueEditModule,
    MatFormFieldModule,
    MatInputModule,
    LoginValueFormErrorModule,
  ],
  exports: [OtpVerificationComponent, RetryVerificationComponent],
})
export class OtpVerificationModule {}
