import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonComponent, ButtonModule} from '@aztrix/components/button';
import {ModalSize, OverlayService} from '@aztrix/components/overlay';
import {PropertyViewModule} from '@aztrix/components/property-view';
import {SpinnerModule} from '@aztrix/components/spinner';
import {IconsModule} from '@aztrix/icons';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';

import {SettingButtonModalComponent} from './setting-button-modal/setting-button-modal.component';

export enum SettingButtonSpacing {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

@Component({
  selector: 'lt-setting-button',
  templateUrl: './setting-button.component.html',
  styleUrls: ['./setting-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PropertyViewModule,
    ButtonModule,
    IconsModule,
    SpinnerModule,
  ],
})
export class SettingButtonComponent extends ButtonComponent implements AfterViewInit {
  @Input() title?: string;
  @Input() hint?: string;
  @Input() responsive = false;
  @Input() override color = 'white';
  @Input() postIcon: string | undefined = 'arrow-right';

  @ViewChild('content') contentTemplate: TemplateRef<unknown>;
  @MediaQuery(`(max-width: 399px)`) small = true;

  @HostBinding('class') _classes = 'alSpacingLarge';
  @Input()
  set spacing(spacing: SettingButtonSpacing) {
    switch (spacing) {
      case SettingButtonSpacing.SMALL:
        this._classes = 'alSpacingSmall';
        break;
      case SettingButtonSpacing.MEDIUM:
        this._classes = 'alSpacingMedium';
        break;
      default:
        this._classes = 'alSpacingLarge';
    }
  }

  @HostBinding('class.is-disabled') @Input() override disabled = false;

  open = false;

  constructor(
    private _overlay: OverlayService,
    private _resize: ObserveResizeService,
    private _elementRef: ElementRef,
    private _changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this._resize.register(this, this._elementRef, this._changeDetector);
  }

  clicked() {
    if (this.responsive) {
      if (this.small) {
        this._openModal();
      } else {
        this.open = !this.open;
      }
    }
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  stopPropagationWhenResponsive(event: Event) {
    this.stopPropagation(event);
  }

  private _openModal() {
    this._overlay.createModal(this._elementRef, SettingButtonModalComponent, {
      title: this.title,
      modalSize: ModalSize.EXTRA_SMALL,
      init: (modal) => {
        modal.content = this.contentTemplate;
      },
    });
  }
}
