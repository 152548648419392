<ax-file-edit
  [form]="form"
  [name]="name"
  [hint]="hint"
  [required]="required"
  [acceptTypes]="acceptTypes"
  [maxFileSize]="maxFileSize"
  [uploadLink]="uploadLink"
  [demo]="demo"
  [showIcon]="showIcon"
></ax-file-edit>
