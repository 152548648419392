<div class="document">
  <mat-checkbox
    [name]="'legal ' + document.id"
    [checked]="form.value"
    [formControl]="form"
    required
  >
    <ax-document
      [document]="document"
      [documentType]="documentType"
      [isAgreementDocument]="false"
      [agreementDocument]="agreementDocument"
      [agreementProperties]="agreementProperties"
      [proposal]="proposal"
      [proposalLanguage]="proposalLanguage"
      [demo]="demo"
    ></ax-document>
  </mat-checkbox>
</div>
<p class="document-description">{{ document.description }}</p>
<mat-error *ngIf="form?.touched && form?.invalid">
  <div class="c-property-edit__error">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{ 'document.confirmation.required' | translate }}</span>
  </div>
</mat-error>
