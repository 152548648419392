<form [formGroup]="form" *ngIf="this.form && hasVerification">
  <span class="title" *ngIf="showLabel">{{
    'proposal.subscribe.verification.title' | translate
  }}</span>
  <div>{{ 'agreement.verification.explanation' | translate }}</div>
  <ax-property-edit
    [form]="propertyControl"
    [autofocus]="false"
    [autocompleteProperties]="possibleProperties"
    [autocomplete]="true"
    [required]="true"
    [hint]="
      'proposal.agreement.verification.property'
        | translate: {propertyType: propertyControl?.value | propertyTypeLabel}
    "
  ></ax-property-edit>
</form>
