import {Component, HostBinding, OnChanges, SimpleChanges} from '@angular/core';
import {Property} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {startWith} from 'rxjs/operators';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-check-edit',
  templateUrl: 'custom-check-edit.component.html',
  styleUrls: ['custom-check-edit.component.scss', '../show-icon.scss'],
})
export class CustomCheckEditComponent extends AbstractProposalPropertyEdit implements OnChanges {
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }

  checks: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: Property | PropertyRepresentation) => {
          this.checks =
            property?.value
              ?.split(',')
              .map((v) => v.trim())
              .filter((v) => !!v) || [];
        });

      this._disableChanges(this.form.disabled);
      this.valueControl?.registerOnDisabledChange((isDisabled) => this._disableChanges(isDisabled));
    }
  }

  checked(value?: string): boolean {
    if (!value) {
      return !!this.checks.length;
    } else {
      return this.checks.indexOf(value) > -1;
    }
  }

  update(value: string | undefined, checked: boolean) {
    if (this.readonly) {
      return;
    }
    const index = this.checks.indexOf(value);
    if (checked) {
      if (index === -1) {
        this.checks.push(value);
      }
    } else {
      if (index > -1) {
        this.checks.splice(index, 1);
      }
    }
    this.form.setValue({...this.form.value, value: this.checks.join(',')});
    this.form.markAsTouched();
  }

  get isSingleCheck() {
    return this.valueLabels ? this.valueLabels.length < 1 : true;
  }

  private _disableChanges(isDisabled: boolean) {
    if (this.isSingleCheck && isDisabled) {
      this.form.setValue({
        ...this.form.value,
        value: this.values?.length ? this.values[0] : 'TRUE',
      });
    }
  }
}
