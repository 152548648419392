import {CdkStepper} from '@angular/cdk/stepper';
import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ax-proposal-subscribe-step-button',
  templateUrl: './proposal-subscribe-step-button.component.html',
  styleUrls: ['./proposal-subscribe-step-button.component.scss'],
})
export class ProposalSubscribeStepButtonComponent {
  @Input() type: 'next' | 'back';
  @Input() loading = false;
  @Input() subscribeLabel? = 'label.confirm';
  @Input() noSubscribeButton = false;
  @Output() nextClicked = new EventEmitter<void>();
  @Output() subscribeClicked = new EventEmitter<void>();

  constructor(public _stepper: CdkStepper) {}

  clicked() {
    if (this.loading) {
      return;
    }

    switch (this.type) {
      case 'next':
        if (this.lastStep) {
          this.subscribeClicked.next();
        } else {
          this.nextClicked.next();
          this._stepper.next();
        }

        break;
      case 'back':
        this._stepper.previous();
        break;
      default:
        this.subscribeClicked.next();
        break;
    }
  }

  get firstStep() {
    return this._stepper.selectedIndex === 0;
  }

  get lastStep() {
    return this._stepper.selectedIndex === this._stepper.steps.length - 1;
  }

  get label() {
    if (this.type === 'back') {
      return 'label.back';
    } else if (this.lastStep) {
      return this.subscribeLabel;
    } else {
      return 'label.next';
    }
  }
}
