<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [ngClass]="{single: isSingleRadio}"
  [name]="customIcon ? customIcon : 'card-text'"
></ax-icon>
<div>
  <div class="header">
    <span class="label">{{ label }}<span *ngIf="required">&nbsp;*</span></span>

    <button
      axButton
      icon-button
      type="button"
      *ngIf="form?.enabled && hasValue && !readonly"
      (click)="clearValue()"
    >
      <ax-icon class="icon" name="close"></ax-icon>
    </button>
  </div>

  <mat-radio-group
    [name]="getName()"
    class="radio-group"
    [ngClass]="{readonly: readonly}"
    [formControl]="radioControl"
  >
    <mat-radio-button *ngFor="let valueTranslation of valueLabels" [value]="valueTranslation.value">
      {{ valueTranslation.label }}
    </mat-radio-button>
  </mat-radio-group>
  <div class="hint-wrapper">
    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      *ngIf="form.valid || form.untouched"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
    <mat-error>
      <ax-property-error *ngIf="form.touched" [form]="form" [label]="label"></ax-property-error>
    </mat-error>
  </div>
</div>
