<ax-proposal-documents
  class="section-wrapper"
  [form]="form"
  [proposal]="proposal"
  [proposalLanguage]="proposalLanguage"
  [agreementProperties]="agreementProperties"
  [agreementDocuments]="agreementDocuments || []"
  [intermediateAgreementDataCode]="intermediateAgreementDataCode"
  [demo]="demo"
></ax-proposal-documents>
