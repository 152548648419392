<div class="property">
  <div class="title">{{ 'agreement.status' | translate }}</div>
  <span class="check">
    <span class="status">{{
      isActive ? ('agreement.active' | translate) : ('agreement.expired' | translate)
    }}</span>
    <div class="status-indicator" [ngClass]="{'is-expired': !isActive}"></div>
  </span>
  <span class="check">
    <span class="status">{{
      isConfirmed
        ? ('agreement.status.confirmed' | translate)
        : ('agreement.status.not-confirmed' | translate)
    }}</span>
    <div class="status-indicator" [ngClass]="{'is-pending': !isConfirmed}"></div>
  </span>
  <span class="check">
    <span *ngIf="proposalHasVerification" class="status">{{
      isVerified ? ('agreement.verified' | translate) : ('agreement.unverified' | translate)
    }}</span>
    <ax-icon
      *ngIf="proposalHasVerification"
      class="icon"
      [ngClass]="{'green-text': isVerified, 'orange-text': !isVerified}"
      name="custom:verified"
    ></ax-icon>
  </span>
</div>
