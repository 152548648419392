import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ValueViewComponent} from './value-view.component';
import {ValueViewLabelComponent} from './value-view-label.component';
import {BooleanValueViewComponent} from './value-views/boolean-value-view/boolean-value-view.component';
import {DateValueViewComponent} from './value-views/date-value-view/date-value-view.component';
import {FileValueViewComponent} from './value-views/file-value-view/file-value-view.component';
import {ImageValueViewComponent} from './value-views/image-value-view/image-value-view.component';
import {RichTextValueViewComponent} from './value-views/rich-text-value-view/rich-text-value-view.component';
import {TextValueViewComponent} from './value-views/text-value-view/text-value-view.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [
    ValueViewComponent,
    ValueViewLabelComponent,
    BooleanValueViewComponent,
    TextValueViewComponent,
    ImageValueViewComponent,
    FileValueViewComponent,
    RichTextValueViewComponent,
    DateValueViewComponent,
  ],
  imports: [CommonModule, HelperPipesModule, TranslatePipe, ImageSourceModule, IconsModule],
  exports: [ValueViewComponent, ValueViewLabelComponent],
})
export class ValueViewModule {}
