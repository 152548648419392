import {Pipe, PipeTransform} from '@angular/core';
import {itemCanBeDisplayed} from '@aztrix/helpers';
import {AlistItemRepresentation} from '@aztrix/sdk';
import {ControlsOf, ValuesOf} from '@ngneat/reactive-forms';

@Pipe({name: 'canBeDisplayed'})
export class CanBeDisplayedPipe implements PipeTransform {
  transform(
    item: AlistItemRepresentation | ValuesOf<ControlsOf<AlistItemRepresentation>> | undefined
  ): boolean {
    return itemCanBeDisplayed(item);
  }
}
