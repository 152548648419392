import {NgModule} from '@angular/core';

import {CanBeDisplayedPipe} from './can-be-displayed.pipe';
import {CanChangeIconPipe} from './can-change-icon.pipe';
import {IconForItemTypePipe} from './icon-for-item-type.pipe';
import {ItemAllowedInTierPipe} from './item-allowed-in-tier.pipe';
import {ItemIsLegalRelatedPipe} from './item-is-legal-related.pipe';
import {ItemLabelPipe} from './item-label.pipe';
import {ItemTypeLabelPipe} from './item-type-label.pipe';
import {ProposalTypePipe} from './proposal-type.pipe';
import {SortItemsPipe} from './sort-items.pipe';

const PIPES = [
  CanBeDisplayedPipe,
  SortItemsPipe,
  ItemAllowedInTierPipe,
  ItemIsLegalRelatedPipe,
  IconForItemTypePipe,
  ItemLabelPipe,
  CanChangeIconPipe,
  ProposalTypePipe,
  ItemTypeLabelPipe,
];

@NgModule({
  declarations: PIPES,
  exports: PIPES,
})
export class AlistPipesModule {}
