import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormArray, UntypedFormControl} from '@angular/forms';
import {ProposalLanguageRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-proposal-subscribe-errors',
  templateUrl: 'proposal-subscribe-errors.component.html',
})
export class ProposalSubscribeErrorsComponent {
  @Input() form: UntypedFormControl | AbstractControl;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() stepForm: FormGroup;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;

  get propertyControls() {
    return this.getControlsFromArray(this.stepForm.get('properties'));
  }

  get controls() {
    return this.propertyControls.flatMap((c) =>
      this.getControlsFromArray(c.get('properties')).flat()
    );
  }

  get typeErrors() {
    return this.propertyControls
      .filter((c) => (c.get('propertyType') ? c.get('propertyType')?.invalid : true))
      .map((c) =>
        this.getControlsFromArray(c.get('properties')).map((control) => ({
          type: control.value?.type,
        }))
      );
  }

  getControlsFromArray(form: AbstractControl | undefined | null): AbstractControl[] {
    if (!form) {
      return [];
    }
    return Object.values((<UntypedFormArray>form).controls);
  }
}
