import {Pipe, PipeTransform} from '@angular/core';
import {itemIsLegalRelated} from '@aztrix/helpers';
import {AlistItemRepresentation} from '@aztrix/sdk';
import {ControlsOf, ValuesOf} from '@ngneat/reactive-forms';

@Pipe({name: 'itemIsLegalRelated'})
export class ItemIsLegalRelatedPipe implements PipeTransform {
  transform(
    item: AlistItemRepresentation | ValuesOf<ControlsOf<AlistItemRepresentation>>
  ): boolean {
    return itemIsLegalRelated(<AlistItemRepresentation>item);
  }
}
