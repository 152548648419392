import {Component, HostBinding, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ax-navbar-more',
  templateUrl: './navbar-more.component.html',
  styleUrls: ['./navbar-more.component.scss'],
})
export class NavbarMoreComponent implements OnDestroy {
  @HostBinding('class.ax-navbar-item-active') open = false;

  @ViewChild(TemplateRef) _content: TemplateRef<unknown>;

  private _bottomSheetRef?: MatBottomSheetRef;
  private _subscription = new Subscription();

  constructor(private _bottomSheet: MatBottomSheet) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  openBottomSheet() {
    if (this.open) {
      if (this._bottomSheetRef) {
        this._bottomSheetRef.dismiss();
      }
    } else {
      this._bottomSheetRef = this._bottomSheet.open(this._content, {
        closeOnNavigation: true,
      });
      this._subscription.unsubscribe();
      this._subscription = this._bottomSheetRef.afterDismissed().subscribe(() => {
        this.open = false;
      });
    }
    this.open = !this.open;
  }
}
