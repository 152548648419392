import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ContrastModule} from '@aztrix/components/contrast';
import {ValueViewModule} from '@aztrix/components/value-view';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {DocumentLinkComponent} from './document-link/document-link.component';
import {DocumentTemplatePreviewComponent} from './document-template-preview/document-template-preview.component';
import {DocumentComponent} from './document.component';

@NgModule({
  declarations: [DocumentComponent, DocumentLinkComponent, DocumentTemplatePreviewComponent],
  imports: [CommonModule, IconsModule, ValueViewModule, TranslatePipe, ContrastModule],
  exports: [DocumentComponent, DocumentLinkComponent],
})
export class DocumentModule {}
