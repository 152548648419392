import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {OverlayService} from '@aztrix/components/overlay';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {getPendingLanguage, HelperPipesModule} from '@aztrix/helpers';
import {
  ProposalLanguageRepresentation,
  ProposalLanguagesService,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {TranslatePipe} from '@aztrix/translate';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';

@Component({
  selector: 'lt-edit-account-proposal',
  templateUrl: './edit-account-proposal.component.html',
  styleUrls: ['./edit-account-proposal.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslatePipe, ValueEditModule, ButtonModule],
})
export class EditAccountProposalComponent implements OnChanges {
  @Input() proposal: ProposalRepresentation;
  @Output() proposalChanged = new EventEmitter<ProposalRepresentation>();

  constructor(
    private _overlay: OverlayService,
    private _proposalLanguages: ProposalLanguagesService
  ) {}

  form = new FormGroup({
    title: new FormControl<string | undefined>(undefined),
    description: new FormControl<string | undefined>(undefined),
  });

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal && this.proposal) {
      const language = getPendingLanguage(this.proposal, 'en');
      this.form.get('title').setValue(language?.name);
      this.form.get('description').setValue(language?.description);
    }
  }

  cancel() {
    this._overlay.closeModal();
  }

  save() {
    const language = getPendingLanguage(this.proposal, 'en');
    this._proposalLanguages
      .addProposalLanguage(this.proposal.id || '', {
        ...(<ProposalLanguageRepresentation>language),
        name: this.form.get('title')?.value || '',
        description: this.form.get('description')?.value || '',
      })
      .subscribe((proposalLanguage) => {
        this.proposalChanged.next({...this.proposal, languages: [proposalLanguage]});
        this._overlay.closeModal();
      });
  }

  get title() {
    return <FormControl<string>>this.form.get('title');
  }

  get description() {
    return <FormControl<string>>this.form.get('description');
  }
}
