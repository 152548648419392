import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {
  canAddAgreement,
  getLatestOrDefaultLanguage,
  getPendingLanguage,
  hasValue,
  Subscribe,
} from '@aztrix/helpers';
import {Property, Proposal} from '@aztrix/models';
import {
  AgreementRepresentation,
  ExternalAgreementRepresentation,
  MetadataRepresentation,
  ProfileRepresentation,
  PropertyRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {BehaviorSubject, NEVER, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'ax-proposal-subscribe-stepper',
  templateUrl: './proposal-subscribe-stepper.component.html',
  styleUrls: ['./proposal-subscribe-stepper.component.scss'],
})
export class ProposalSubscribeStepperComponent implements OnChanges {
  @Input() proposal?: ProposalRepresentation | null;
  @Input() agreement?: AgreementRepresentation;
  @Input() agreementCode?: string;
  @Input() myProfile?: ProfileRepresentation;
  @Input() languageCode?: string;
  @Input() edit = false;
  @Input() demo = false;
  @Input() collapsibleGroups = [];
  @Input() open = true;
  @Input() orientation: 'VERTICAL' | 'HORIZONTAL' = 'VERTICAL';
  @Input() showSteps = false;
  @Input() noSubscribeButton = false;
  @Input() verification = true;
  @Input() external = false;
  @Input() subscriberMetadata: MetadataRepresentation[];
  @Input() subscribeLabel = '';
  @Output() subscribed = new EventEmitter<ExternalAgreementRepresentation | undefined>();

  isCreatingIntermediateAgreement$ = new BehaviorSubject<boolean>(false);
  intermediateCreateHasError$ = new BehaviorSubject<boolean>(false);

  valid = false;

  form: UntypedFormGroup;
  form$ = new BehaviorSubject<UntypedFormGroup | null>(null);

  stepsForm$ = this.form$.pipe(map((form) => <UntypedFormArray>form?.get('steps')));

  valueChanges$ = this.stepsForm$.pipe(
    mergeMap((stepsForm) => {
      if (stepsForm) {
        return stepsForm.valueChanges;
      } else {
        return NEVER;
      }
    })
  );

  autocompleteProperties$: Observable<PropertyRepresentation[]> = this.valueChanges$.pipe(
    map((steps) => steps.flatMap((step: any) => Object.values(step.properties))),
    map((values) => values.flatMap((value: any) => Object.values<Property>(value.properties))),
    map((properties) => properties.filter((p: Property) => hasValue(p))),
    map((properties) => {
      const uniqueProperties: PropertyRepresentation[] = [];
      for (const property of properties) {
        if (!uniqueProperties.find((p) => p.type === property.type && p.value === property.value)) {
          uniqueProperties.push(property);
        }
      }
      return uniqueProperties;
    })
  );

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.proposal || changes.agreement || changes.myProfile || changes.languageCode) &&
      this.proposal
    ) {
      this.form = Subscribe.createForm(
        <Proposal>this.proposal,
        this.external,
        this._language(this.proposal, this.languageCode),
        <AgreementRepresentation>this.agreement,
        this.myProfile,
        this.verification
      );

      if (!canAddAgreement(this.proposal, this.myProfile, this.agreement)) {
        this.form.disable({emitEvent: false});
      } else {
        this.form.enable({emitEvent: false});
      }

      this.form$.next(this.form);
    }
  }

  private _language(
    proposal: Proposal | ProposalRepresentation,
    languageCode?: string
  ): ProposalLanguageRepresentation {
    return <ProposalLanguageRepresentation>(
      (this.edit
        ? getPendingLanguage(proposal, languageCode)
        : getLatestOrDefaultLanguage(proposal, languageCode))
    );
  }
}
