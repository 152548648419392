import {HttpClient} from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {OverlayService} from '@aztrix/components/overlay';
import {BASE_URL} from '@aztrix/environment';
import {addQueryParametersToLink, getLanguageForTimestamp, getLink, isOwner} from '@aztrix/helpers';
import {
  Agreement,
  AgreementData,
  AgreementVerificationType,
  Profile,
  Property,
  PropertyType,
  Proposal,
} from '@aztrix/models';
import {
  AgreementDataRepresentation,
  AgreementRepresentation,
  ProfileRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {ConfirmExpireModalComponent} from '@aztrix/subscribe/confirm-expire-modal';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {filter, first, map, mergeMap, shareReplay, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'ax-agreement-view',
  templateUrl: './agreement-view.component.html',
  styleUrls: ['./agreement-view.component.scss', './property.scss'],
})
export class AgreementViewComponent implements OnChanges {
  @Input() myProfile?: ProfileRepresentation;
  @Input() proposal: Proposal | ProposalRepresentation | undefined;
  @Input() agreement: AgreementRepresentation | undefined;
  @Input() agreementData: AgreementDataRepresentation | undefined;

  @Input() historyData: AgreementData[];
  @Input() canExpire = true;
  @Input() isExternal = false;
  @Input() legal = true;
  @HostBinding('class.compact') @Input() compact = false;

  @Output() didExpire = new EventEmitter<AgreementRepresentation>();
  @Output() addProperty = new EventEmitter<Property>();
  @Output() editProperty = new EventEmitter<Property | undefined>();

  @Output() verified = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AgreementVerificationType = AgreementVerificationType;

  isActive = false;
  isConfirmed = false;
  isVerified = false;

  private _proposal$ = new ReplaySubject<Proposal>(1);
  private _agreement$ = new ReplaySubject<Agreement | AgreementRepresentation>(1);
  private _agreementData$ = new ReplaySubject<AgreementData | AgreementDataRepresentation>(1);
  private _myProfile$ = new ReplaySubject<Profile | ProfileRepresentation>(1);
  private _canExpire$ = new ReplaySubject<boolean>(1);

  private _proposalLanguages$: Observable<ProposalLanguageRepresentation[]> = combineLatest([
    this._proposal$,
    this._agreement$,
  ]).pipe(
    switchMap(([proposal, agreement]) =>
      this._getProposalLanguages(proposal.id || '', agreement.agreementCode)
    ),
    shareReplay(1)
  );

  proposalLanguage$ = combineLatest([this._proposalLanguages$, this._agreement$]).pipe(
    map(([proposalLanguages, agreement]) => {
      return proposalLanguages.find((l) => l.languageCode === agreement.agreementData?.language);
    })
  );

  canSubscribe: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;

  isHistoryAgreement$: Observable<boolean> = combineLatest([
    this._agreement$,
    this._agreementData$.pipe(filter((d) => !!d)),
  ]).pipe(
    map(
      ([agreement, agreementData]) =>
        agreementData.startTimestamp !== agreement.agreementData?.startTimestamp
    )
  );

  language$: Observable<ProposalLanguageRepresentation> = combineLatest([
    this._proposalLanguages$,
    this._agreementData$.pipe(filter((d) => !!d)),
  ]).pipe(
    map(
      ([languages, agreementData]) =>
        <ProposalLanguageRepresentation>(
          getLanguageForTimestamp(
            languages,
            agreementData.language
              ? agreementData.language
              : languages.find((l) => l.isDefault)?.languageCode || 'en',
            agreementData.startTimestamp
          )
        )
    ),
    shareReplay(1)
  );

  pdfDownloadEnabled$ = this.language$.pipe(
    map((proposalLanguage) => {
      return proposalLanguage.pdfDownloadEnabled;
    })
  );

  whiteLabel$ = this.language$.pipe(map((l) => !!l?.whiteLabel));

  startTimestamp$ = this._agreementData$.pipe(
    filter((d) => !!d),
    map((d) => d.startTimestamp)
  );

  verificationTimestamp$ = this._agreement$.pipe(map((d) => d.verificationTimestamp));

  canViewHistory$: Observable<boolean> = combineLatest([
    this._agreementData$,
    this._agreement$,
  ]).pipe(map(([agreementData, agreement]) => !!agreementData || !!agreement.expirationTimestamp));

  useRouterLink = window.location.origin.includes(this.baseUrl);

  isSubscriber$: Observable<boolean> = combineLatest([this._myProfile$, this._agreement$]).pipe(
    map(([myProfile, agreement]) => myProfile.id === agreement.subscriberId)
  );

  createdByIsMyProfile$ = combineLatest([this._myProfile$, this._agreementData$]).pipe(
    mergeMap(([myProfile, agreementData]) => {
      if (!myProfile) {
        return of(false);
      } else if (agreementData?.createdBy === 'OWNER') {
        return this._proposal$.pipe(map((proposal) => proposal.ownerId === myProfile.id));
      } else if (agreementData?.createdBy === 'SUBSCRIBER') {
        return this._agreement$.pipe(map((agreement) => agreement.subscriberId === myProfile.id));
      } else {
        return of(false);
      }
    })
  );

  isProposalOwner$: Observable<boolean> = combineLatest([this._agreement$, this._myProfile$]).pipe(
    map(([agreement, profile]) => !!this.myProfile && isOwner(agreement, profile))
  );

  canViewData$: Observable<boolean> = combineLatest([
    this._agreement$,
    this.isProposalOwner$.pipe(startWith(false)),
  ]).pipe(
    map(([agreement, proposalOwner]) => {
      if (proposalOwner) {
        return (
          (!agreement.deletedByOwner && !this.isExternal) ||
          (this.isExternal && !agreement.deletedByOwner)
        );
      } else {
        return (
          (agreement.deletedByOwner && !this.isExternal) ||
          (!agreement.deletedByOwner && this.isExternal) ||
          (!agreement.deletedByOwner && !this.isExternal)
        );
      }
    })
  );

  canExpire$ = combineLatest([this._agreement$, this._canExpire$]).pipe(
    map(([agreement, canExpire]) => !agreement?.expirationTimestamp && canExpire)
  );

  constructor(
    private _overlay: OverlayService,
    public dialog: MatDialog,
    private _http: HttpClient,
    private _elementRef: ElementRef,
    @Optional() @Inject(BASE_URL) public baseUrl: string
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.agreement && changes.agreement) {
      this._agreement$.next(this.agreement);
      this.isActive = this.agreement.active || false;
      this.isConfirmed = this.agreement.agreementData?.confirmed || false;

      if (this.proposalHasVerification) {
        this.isVerified = this.agreement.verified || false;
      }
    }

    if (this.proposal && changes.proposal) {
      this._proposal$.next(<Proposal>this.proposal);
    }

    if (this.agreementData && changes.agreementData) {
      this._agreementData$.next(this.agreementData);
    }

    if (this.myProfile && changes.myProfile) {
      this._myProfile$.next(this.myProfile);
    }

    if (changes.canExpire) {
      this._canExpire$.next(this.canExpire);
    }

    this.canSubscribe = this.proposal?.profileContext === this.myProfile?.profileContext;
  }

  get proposalHasVerification(): boolean {
    return (
      !!this.proposal?.verification &&
      this.proposal?.verification !== AgreementVerificationType.NONE
    );
  }

  expire(): void {
    this._overlay.createModal(this._elementRef, ConfirmExpireModalComponent, {
      title: 'agreement.modal.expire.title',
      init: (expireModal) => {
        expireModal.agreement = <AgreementRepresentation>this.agreement;
        expireModal.expired.pipe(first()).subscribe((updatedAgreement) => {
          this.didExpire.emit(updatedAgreement);
        });
      },
    });
  }

  private _getProposalLanguages(proposalId: string, agreementCode?: string) {
    let link = getLink('r/proposals/:proposalId/languages', {proposalId});
    link = addQueryParametersToLink(link, agreementCode ? {agreementCode} : undefined);
    return this._http.get<ProposalLanguageRepresentation[]>(link);
  }
}
