import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {getLatestOrDefaultLanguage} from '@aztrix/helpers';
import {
  AgreementRepresentation,
  ExternalAgreementRepresentation,
  MetadataRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map, mergeMap, shareReplay} from 'rxjs/operators';

@Component({
  selector: 'ax-subscribe',
  templateUrl: 'subscribe.component.html',
  styleUrls: ['subscribe.component.scss'],
})
export class SubscribeComponent implements OnChanges {
  @Input() proposalId: string;
  @Input() languageCode?: string;
  @Input() agreement?: AgreementRepresentation;
  @Input() subscriberMetadata: MetadataRepresentation[];
  @Input() orientation: 'VERTICAL' | 'HORIZONTAL' = 'VERTICAL';
  @Input() verification = true;
  @Input() externalAgreement: ExternalAgreementRepresentation;

  @Output() subscribed = new EventEmitter<ExternalAgreementRepresentation | undefined>();

  error$ = new BehaviorSubject(false);

  proposalId$ = new ReplaySubject<string>(1);
  languageCode$ = new ReplaySubject<string>(1);

  constructor(private http: HttpClient) {}

  proposal$: Observable<ProposalRepresentation | undefined> = this.proposalId$.pipe(
    mergeMap((proposalId) => this.http.get<ProposalRepresentation>(`/r/proposals/${proposalId}`)),
    shareReplay(1)
  );

  language$ = combineLatest([this.proposal$, this.languageCode$]).pipe(
    map(([proposal, languageCode]) => getLatestOrDefaultLanguage(proposal, languageCode))
  );

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposalId && this.proposalId) {
      this.proposalId$.next(this.proposalId);
    }

    if (changes.languageCode && this.languageCode) {
      this.languageCode$.next(this.languageCode);
    }
  }
}
