import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-chips-edit',
  templateUrl: './custom-chips-edit.component.html',
  styleUrls: ['./custom-chips-edit.component.scss', '../show-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomChipsEditComponent extends AbstractProposalPropertyEdit {
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }

  get amount() {
    return this.requestedPropertyType?.customFieldInfo?.maxAmountOfValues;
  }

  get labels() {
    return <string[]>this.valueLabels.map((label) => label.label);
  }
}
