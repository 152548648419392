import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {AutofocusModule} from '@aztrix/components/autofocus';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {DomainEditComponent} from './domain-edit.component';

@NgModule({
  declarations: [DomainEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslatePipe,
    AutofocusModule,
  ],
  exports: [DomainEditComponent],
})
export class DomainEditModule {}
