import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {itemCanBeDisplayed, itemIsLegalRelated, sortItems} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {
  AlistItemRepresentation,
  AlistItemWithStringRepresentation,
  AlistLanguageRepresentation,
  AlistRepresentation,
} from '@aztrix/sdk';

import {ItemViewModule} from '../item-view/item-view.module';
import {AlistPipesModule} from '../pipes/alist-pipes.module';

@Component({
  selector: 'lt-items-view',
  templateUrl: './items-view.component.html',
  styleUrls: ['./items-view.component.scss'],
  standalone: true,
  imports: [CommonModule, IconsModule, AlistPipesModule, ItemViewModule],
})
export class ItemsViewComponent implements OnChanges {
  @Input() alist: AlistRepresentation;
  @Input() alistLanguage: AlistLanguageRepresentation;

  ItemFormat = AlistItemWithStringRepresentation.FormatEnum;

  _items: AlistItemRepresentation[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alistLanguage && this.alistLanguage?.items) {
      this._items = [];
      let items: AlistItemRepresentation[] = [];
      for (const item of sortItems(this.alistLanguage.items).filter(
        (item) => itemCanBeDisplayed(item) && item.visible && !itemIsLegalRelated(item)
      )) {
        if (items.find((i) => i.format === item.format) || !items.length) {
          items.push(item);
        } else {
          this._items.push(item);
          items = [];
          items.push(item);
        }
      }
      this._items = [...this._items, ...items];
    }
  }
}
