<div class="container">
  <ax-snackbar
    [ngClass]="{snackbar: isExternal}"
    *ngIf="agreement?.deletedByOwner"
    [dismissible]="false"
    color="orange"
  >
    <ax-icon class="icon snackbar-icon" name="alert"></ax-icon>
    <span>{{ 'agreement.deleted.notice' | translate }}</span>
  </ax-snackbar>
  <ax-snackbar class="history-snackbar" *ngIf="isHistoryAgreement$ | async" [dismissible]="false">
    <span>{{ 'agreement.history.viewing' | translate }}&nbsp;</span>
    <b>{{ startTimestamp$ | async | formatDateTime }}</b>
  </ax-snackbar>
  <ax-snackbar *ngIf="verificationTimestamp$ | async" color="green" [dismissible]="false">
    <span>
      {{ 'agreement.external.verified.snackbar' | translate }}&nbsp;{{
        verificationTimestamp$ | async | formatDateTime
      }}</span
    >
  </ax-snackbar>
  <ax-snackbar
    class="snackbar"
    *ngIf="!historyData && isExternal && (whiteLabel$ | async) === false"
    ><div>
      <span>{{ 'agreement.external.snackbar' | translate }}&nbsp;</span>
      <a
        [attr.href]="
          baseUrl +
          '/register/' +
          proposal?.profileContext?.toLocaleLowerCase() +
          '?agreementCode=' +
          (agreement?.agreementCode || '')
        "
      >
        {{ 'label.register' | translate }}
      </a>

      <span>{{ 'label.or' | translate }}</span>

      <a
        [attr.href]="
          baseUrl +
          '/login/' +
          proposal?.profileContext?.toLocaleLowerCase() +
          '?agreementCode=' +
          (agreement?.agreementCode || '')
        "
      >
        {{ 'label.login' | translate | lowercase }}
      </a>
    </div>
  </ax-snackbar>
  <ng-content></ng-content>
  <div>
    <ax-proposal-header
      class="proposal-header"
      *ngIf="proposal"
      [proposal]="proposal"
      [language]="language$ | async"
    >
      <ax-menu-button
        [hasItems]="(language$ | async)?.askToSendEmail || (canExpire$ | async) || false"
        *ngIf="compact"
      >
        <button mat-menu-item *ngIf="canExpire$ | async" (click)="expire()">
          <ax-icon class="icon m-menu__icon" name="clock-alert"></ax-icon>
          {{ 'agreement.expire' | translate }}
        </button>
        <ax-agreement-download-pdf-button
          *ngIf="pdfDownloadEnabled$ | async"
          [agreement]="agreement"
          [language]="(language$ | async) || undefined"
          color="transparent"
        ></ax-agreement-download-pdf-button>
        <ax-agreement-send-to-email-button
          *ngIf="(language$ | async)?.askToSendEmail"
          [language]="(language$ | async) || undefined"
          [agreementData]="agreementData"
          [agreementCode]="agreement?.agreementCode"
          color="transparent"
        ></ax-agreement-send-to-email-button>
      </ax-menu-button>
    </ax-proposal-header>

    <ax-agreement-verification
      class="verification"
      *ngIf="
        (isHistoryAgreement$ | async) === false &&
        isActive &&
        proposalHasVerification &&
        (isExternal || (isSubscriber$ | async) === true)
      "
      [proposal]="proposal"
      [agreement]="agreement"
      [isExternal]="isExternal"
      (verified)="verified.emit()"
    ></ax-agreement-verification>

    <ng-container *ngIf="canViewData$ | async; else noData">
      <div class="actions" *ngIf="!compact">
        <button
          class="action"
          *ngIf="canExpire$ | async"
          axButton
          outline
          color="danger"
          (click)="expire()"
        >
          {{ 'agreement.expire' | translate }}
        </button>
        <ng-container *ngIf="isExternal && !compact">
          <ng-container *ngTemplateOutlet="actionContent"></ng-container
        ></ng-container>
      </div>

      <ax-agreement-overview
        class="agreement-overview"
        [myProfile]="myProfile"
        [proposal]="proposal"
        [agreement]="agreement"
        [language]="(language$ | async) || undefined"
        [agreementData]="agreementData"
        [historyData]="historyData"
        [isExternal]="isExternal"
        (addProperty)="addProperty.next($event)"
        (editProperty)="editProperty.next($event)"
      ></ax-agreement-overview>

      <div
        *ngIf="
          isExternal &&
          agreement?.agreementData?.confirmed &&
          !compact &&
          ((pdfDownloadEnabled$ | async) || (language$ | async)?.askToSendEmail)
        "
        class="overview send-mail"
      >
        <ax-agreement-download-pdf-button
          *ngIf="pdfDownloadEnabled$ | async"
          [agreement]="agreement"
          [language]="(language$ | async) || undefined"
        ></ax-agreement-download-pdf-button>
        <ax-agreement-send-to-email-button
          *ngIf="(language$ | async)?.askToSendEmail"
          [language]="(language$ | async) || undefined"
          [agreementData]="agreementData"
          [agreementCode]="agreement?.agreementCode"
        ></ax-agreement-send-to-email-button>
      </div>
    </ng-container>

    <div class="footer">
      <ax-powered-by-aztrix
        *ngIf="proposal && (whiteLabel$ | async) === false && isExternal"
      ></ax-powered-by-aztrix>
      <ng-container *ngIf="isExternal && compact">
        <ng-container *ngTemplateOutlet="actionContent"></ng-container
      ></ng-container>
    </div>

    <ng-template #noData>
      <div class="overview extra-info no-data">
        <p>
          {{ 'proposal.subscribers.data.deleted.explanation' | translate }}
          <a [axLink]="['proposal', proposal?.id]">
            {{ 'proposal.subscribers.data.deleted.action' | translate }}
          </a>
        </p>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #actionContent><ng-content select=".action"></ng-content></ng-template>
