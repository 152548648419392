<ax-proposal-subscribe-step-button
  type="back"
  [loading]="loading"
></ax-proposal-subscribe-step-button>
<ax-proposal-subscribe-step-button
  type="next"
  [loading]="loading"
  [subscribeLabel]="subscribeLabel"
  [noSubscribeButton]="noSubscribeButton"
  (nextClicked)="nextClicked.next()"
  (subscribeClicked)="subscribeClicked.next()"
></ax-proposal-subscribe-step-button>
