<ax-proposal-document-checkbox
  *ngIf="documentControl && !signingRequired"
  [form]="signedControl"
  [document]="documentControl.value"
  [agreementDocument]="agreementDocument"
  [agreementProperties]="agreementProperties"
  [proposal]="proposal"
  [proposalLanguage]="proposalLanguage"
  [demo]="demo"
></ax-proposal-document-checkbox>
<ax-proposal-document-sign-button
  *ngIf="documentControl && signingRequired"
  [form]="signedControl"
  [document]="documentControl.value"
  [agreementDocument]="agreementDocument"
  [proposal]="proposal"
  [demo]="demo"
  [intermediateAgreementDataCode]="intermediateAgreementDataCode"
  [agreementProperties]="agreementProperties"
  [proposalLanguage]="proposalLanguage"
></ax-proposal-document-sign-button>
