<div class="container">
  <div class="header">
    <button axButton icon-button (click)="_close.emit()" color="white">
      <ax-icon class="icon" name="close"></ax-icon>
    </button>
    <h3>
      {{ 'landing.snackbar.analytics.cookie.preferences' | translate }}
    </h3>
  </div>
  <div class="title">
    <p>{{ 'landing.snackbar.analytics.text' | translate }}</p>
  </div>
  <div class="cookie-preferences">
    <button axButton filled fullWidth color="primary" (click)="grantAll()">
      {{ 'label.accept.all' | translate }}
    </button>
    <div class="toggle-wrapper">
      <mat-slide-toggle
        [checked]="adStrorageConsentControl.value === 'granted'"
        color="primary"
        labelPosition="before"
        [aria-label]="
          (adStrorageConsentControl.value === 'denied' ? 'label.disable' : 'label.enable')
            | translate
        "
        (click)="updateConsentValue(adStrorageConsentControl)"
      >
        <span class="label">{{ 'landing.snackbar.analytics.ad_storage.title' | translate }}</span>
      </mat-slide-toggle>
      <p>{{ 'landing.snackbar.analytics.ad_storage.text' | translate }}</p>
    </div>
    <div class="toggle-wrapper">
      <mat-slide-toggle
        [checked]="adUserDataConsent.value === 'granted'"
        labelPosition="before"
        color="primary"
        [aria-label]="
          (adUserDataConsent.value === 'denied' ? 'label.disable' : 'label.enable') | translate
        "
        (click)="updateConsentValue(adUserDataConsent)"
      >
        <span class="label">{{ 'landing.snackbar.analytics.ad_user_data.title' | translate }}</span>
      </mat-slide-toggle>
      <p>{{ 'landing.snackbar.analytics.ad_user_data.text' | translate }}</p>
    </div>
    <div class="toggle-wrapper">
      <mat-slide-toggle
        [checked]="adPersonalizationConsent.value === 'granted'"
        color="primary"
        labelPosition="before"
        [aria-label]="
          (adPersonalizationConsent.value === 'denied' ? 'label.disable' : 'label.enable')
            | translate
        "
        (click)="updateConsentValue(adPersonalizationConsent)"
      >
        <span class="label">{{
          'landing.snackbar.analytics.ad_personalization.title' | translate
        }}</span>
      </mat-slide-toggle>
      <p>{{ 'landing.snackbar.analytics.ad_personalization.text' | translate }}</p>
    </div>

    <div class="toggle-wrapper">
      <mat-slide-toggle
        [checked]="analyticsStorageConsent.value === 'granted'"
        color="primary"
        labelPosition="before"
        [aria-label]="
          (analyticsStorageConsent.value === 'denied' ? 'label.disable' : 'label.enable')
            | translate
        "
        (click)="updateConsentValue(analyticsStorageConsent)"
      >
        <span class="label">{{
          'landing.snackbar.analytics.analytics_storage.title' | translate
        }}</span>
      </mat-slide-toggle>
      <p>{{ 'landing.snackbar.analytics.analytics_storage.text' | translate }}</p>
    </div>
  </div>
  <div class="button-wrapper">
    <button axButton outline color="primary" (click)="denyAll()">
      {{ 'label.reject.all' | translate }}
    </button>
    <button axButton filled color="primary" (click)="confirmSelection()">
      {{ 'label.confirm' | translate }}
    </button>
  </div>
</div>
