import {Component, Input} from '@angular/core';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  DocumentRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';

@Component({
  selector: 'ax-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent {
  @Input() document: DocumentRepresentation;
  @Input() documentType: 'PROPOSAL' | 'AGREEMENT' | 'SIGNING-ORDER';
  @Input() agreementDocument?: AgreementDocumentRepresentation;
  @Input() isAgreementDocument: boolean;
  @Input() isSignableDocument = false;
  @Input() proposal?: ProposalRepresentation;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Input() demo = false;
}
