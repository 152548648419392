import {Component} from '@angular/core';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-number-edit',
  templateUrl: 'custom-number-edit.component.html',
  styleUrls: ['../show-icon.scss'],
})
export class CustomNumberEditComponent extends AbstractProposalPropertyEdit {}
