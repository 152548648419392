import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {CustomValidators, PropertyFormControl} from '@aztrix/helpers';
import {PropertyType} from '@aztrix/models';

@Component({
  selector: 'ax-send-to-email',
  templateUrl: './send-to-email.component.html',
  styleUrls: ['./send-to-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendToEmailComponent implements OnChanges {
  @Input() hint: string;
  @Input() email: string;
  @Output() didClickSend: EventEmitter<string> = new EventEmitter<string>();

  form = PropertyFormControl.createByType(PropertyType.EMAIL, CustomValidators.propertyValid);

  sendEmail() {
    this.didClickSend.emit(this.form.value.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.email && this.email) {
      this.form.get('value')?.setValue(this.email);
      this.form.markAsDirty();
    }
  }
}
