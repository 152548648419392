import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {LoginValueFormErrorComponent} from './login-value-form-error.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [LoginValueFormErrorComponent],
  imports: [CommonModule, IconsModule, HelperPipesModule, TranslatePipe],
  exports: [LoginValueFormErrorComponent],
})
export class LoginValueFormErrorModule {}
