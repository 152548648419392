import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ButtonModule} from '@aztrix/components/button';
import {ChipsEditModule} from '@aztrix/components/chips-edit';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {RatingEditModule} from '@aztrix/components/rating-edit';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {CustomCheckEditComponent} from './custom-check-edit/custom-check-edit.component';
import {CustomChipsEditComponent} from './custom-chips-edit/custom-chips-edit.component';
import {CustomDateEditComponent} from './custom-date-edit/custom-date-edit.component';
import {CustomDefaultEditComponent} from './custom-default-edit/custom-default-edit.component';
import {CustomDropdownEditComponent} from './custom-dropdown-edit/custom-dropdown-edit.component';
import {CustomNumberEditComponent} from './custom-number-edit/custom-number-edit.component';
import {CustomPropertyEditComponent} from './custom-property-edit.component';
import {CustomRadioEditComponent} from './custom-radio-edit/custom-radio-edit.component';
import {CustomRatingEditComponent} from './custom-rating-edit/custom-rating-edit.component';
import {CustomRichTextEditComponent} from './custom-rich-text-edit/custom-rich-text-edit.component';
import {CustomToggleEditComponent} from './custom-toggle-edit/custom-toggle-edit.component';
import {CustomUrlEditComponent} from './custom-url-edit/custom-url-edit.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    CustomPropertyEditComponent,
    CustomCheckEditComponent,
    CustomDropdownEditComponent,
    CustomRadioEditComponent,
    CustomDefaultEditComponent,
    CustomChipsEditComponent,
    CustomDateEditComponent,
    CustomRatingEditComponent,
    CustomRichTextEditComponent,
    CustomToggleEditComponent,
    CustomNumberEditComponent,
    CustomUrlEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatAutocompleteModule,
    ScrollingModule,
    NgxMatSelectSearchModule,
    IconsModule,
    PropertyEditModule,
    MatChipsModule,
    ButtonModule,
    ChipsEditModule,
    ValueEditModule,
    RatingEditModule,
    MatSlideToggleModule,
    TranslatePipe,
  ],
  exports: [CustomPropertyEditComponent],
})
export class CustomPropertyEditModule {}
