import {Component, HostBinding, Input} from '@angular/core';
import {FormGroup} from '@ngneat/reactive-forms';

@Component({
  selector: 'ax-rating-edit',
  templateUrl: './rating-edit.component.html',
  styleUrls: ['./rating-edit.component.scss'],
})
export class RatingEditComponent {
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @Input() form: FormGroup<any>;
  @Input() label?: string;
  @Input() required = false;
  @Input() readonly = false;

  clearValue() {
    this.form.get('value')?.setValue(null);
  }

  get hasValue() {
    return this.form?.value?.value;
  }
}
