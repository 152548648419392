<ng-template #valueView let-v>
  <ng-container [ngSwitch]="type">
    <ax-boolean-value-view
      *ngSwitchCase="'BOOLEAN'"
      [value]="v"
      [label]="label"
    ></ax-boolean-value-view>
    <ax-image-value-view *ngSwitchCase="'IMAGE'" [value]="v" [label]="label"></ax-image-value-view>
    <ax-file-value-view *ngSwitchCase="'FILE'" [value]="v" [label]="label"></ax-file-value-view>
    <ax-rich-text-value-view
      *ngSwitchCase="'RICH_TEXT'"
      [value]="v"
      [label]="label"
    ></ax-rich-text-value-view>
    <ax-date-value-view *ngSwitchCase="'DATE'" [value]="v" [label]="label"></ax-date-value-view>
    <ax-text-value-view *ngSwitchDefault [value]="v" [label]="label"></ax-text-value-view>
  </ng-container>
</ng-template>

@if (value | isArray) {
  <ul class="values">
    @for (v of value; track v) {
      <li>
        <ng-container *ngTemplateOutlet="valueView; context: {$implicit: v}"></ng-container>
      </li>
    }
  </ul>
} @else {
  <ng-container *ngTemplateOutlet="valueView; context: {$implicit: value}"></ng-container>
}
