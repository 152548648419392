import {HttpErrorResponse} from '@angular/common/http';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {OverlayService} from '@aztrix/components/overlay';
import {BehaviorSubject, first, Observable} from 'rxjs';

import {RetryVerificationComponent} from './retry-verification/retry-verification.component';

@Component({
  selector: 'ax-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss'],
})
export class OtpVerificationComponent implements OnInit {
  @Input() verified = false;
  @Input() verificationValue: string | undefined = '';
  @Input() verificationType: 'EMAIL' | 'MOBILE';
  @Input() canChangeValue = true;
  @Input() verificationCode?: string;

  @Input() verification: (code: string, verificationValue: string) => Observable<unknown>;
  @Input() retryVerification: (verificationValue: string) => Observable<unknown>;

  @Output() verificationValueChanges = new EventEmitter<string>();
  @Output() verificationComplete = new EventEmitter<string>();

  codeForm = new FormControl<string | null>({value: null, disabled: false}, [Validators.required]);

  loading$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject<string | boolean | null>(null);

  startDate = Date.now();

  constructor(
    private _overlay: OverlayService,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.verificationCode && !this.verified && this.verificationValue !== '') {
      this.codeForm.setValue(this.verificationCode);
      this.verifyOTP();
    }
  }

  verifyOTP() {
    if (this.codeForm?.errors) {
      return;
    }

    this.loading$.next(true);
    this.error$.next(null);

    this.verification(<string>this.codeForm.value, this.verificationValue || '')
      .pipe(first())
      .subscribe({
        next: () => {
          localStorage.removeItem('endTime');
          this.verified = true;
          this.loading$.next(false);
          this.verificationComplete.next(this.verificationValue || '');
        },
        error: (response: HttpErrorResponse) => {
          this.codeForm.reset();
          if (
            response.error === 'TOO_MANY_VERIFICATION_ATTEMPTS' ||
            response.error === 'VERIFICATION_CODE_EXPIRED'
          ) {
            localStorage.setItem('endTime', Date.now().toString());
          }

          this.error$.next(typeof response.error === 'string' ? response.error : true);
          this.loading$.next(false);
        },
      });
  }

  retryOTP() {
    this._overlay.createModal(this._elementRef, RetryVerificationComponent, {
      title: 'agreement.verification.retry',
      init: (modal) => {
        modal.startDate = this.startDate;
        modal.canChangeValue = this.canChangeValue;
        modal.verificationType = this.verificationType;
        modal.retryVerification = this.retryVerification;
        modal.form.setValue(this.verificationValue || '');
        modal.verificationValueChanges.pipe(first()).subscribe((verificationValue) => {
          this.verificationValue = verificationValue;
          this.verificationValueChanges.next(this.verificationValue);
          this.error$.next(null);
        });
      },
    });
  }
}
