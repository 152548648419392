import {Component, Input} from '@angular/core';
import {Agreement, AgreementMetadata} from '@aztrix/models';
import {AgreementRepresentation, MetadataRepresentation} from '@aztrix/sdk';
import {UAParser} from 'ua-parser-js';

@Component({
  selector: 'ax-agreement-metadata',
  templateUrl: './agreement-metadata.component.html',
  styleUrls: ['./agreement-metadata.component.scss', '../property.scss'],
})
export class AgreementMetadataComponent {
  @Input() agreement?: Agreement | AgreementRepresentation;

  get agreementMetadata(): AgreementMetadata[] | MetadataRepresentation[] | undefined {
    return this.agreement?.agreementMetadata;
  }

  get subscriberMetadata(): AgreementMetadata[] | MetadataRepresentation[] | undefined {
    return this.agreement?.subscriberMetadata;
  }

  get sessionMetadata(): AgreementMetadata[] {
    if (!this.agreement?.agreementData?.userAgent) {
      return [];
    }

    const sessionData = [];
    if (this.agreement.agreementData.ip) {
      sessionData.push({
        key: 'IP',
        value: this.agreement.agreementData.ip,
      });
    }

    const parsed = UAParser(this.agreement.agreementData.userAgent);
    if (parsed.os) {
      sessionData.push({
        key: 'OS',
        value: `${parsed.os.name} ${parsed.os.version}`,
      });
    }

    if (parsed.browser) {
      sessionData.push({
        key: 'Browser',
        value: `${parsed.browser.name} (v${parsed.browser.version})`,
      });
    }

    return sessionData;
  }
}
