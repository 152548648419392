import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {LOCALE, TranslateService} from '@aztrix/translate';
import {Locale} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  DocumentRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, switchMap} from 'rxjs';
import {TemplateParser} from '@aztrix/helpers';

@Component({
  selector: 'ax-document-template-preview',
  templateUrl: './document-template-preview.component.html',
  styleUrls: ['./document-template-preview.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class DocumentTemplatePreviewComponent implements OnChanges, AfterViewChecked {
  @Input() document: DocumentRepresentation;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;
  @Input() proposal?: ProposalRepresentation;
  @Input() template: string;
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Output() templateChange = new EventEmitter<string>();

  agreementProperties$ = new BehaviorSubject<AgreementPropertyRepresentation[] | undefined>(
    undefined
  );

  parsedTextTemplate$ = combineLatest([this._locale$, this.agreementProperties$]).pipe(
    switchMap(([locale, agreementProperties]) =>
      TemplateParser.parse$(
        this.document.value,
        this._translateService,
        this.proposal,
        this.proposalLanguage,
        locale,
        agreementProperties
      )
    )
  );

  constructor(
    private _translateService: TranslateService,
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>,
    private _elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.agreementProperties && changes.agreementProperties) {
      this.agreementProperties$.next(this.agreementProperties);
    }
  }

  ngAfterViewChecked(): void {
    this.templateChange.next(this._elementRef.nativeElement.shadowRoot.innerHTML);
  }
}
