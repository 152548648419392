import {Component, HostBinding} from '@angular/core';

import {AbstractCustomPropertyView} from '../abstract-custom-property-view';

@Component({
  selector: 'ax-custom-toggle-view',
  template: `<mat-slide-toggle
    [checked]="this.property.value === 'true'"
    [disabled]="true"
  ></mat-slide-toggle>`,
})
export class CustomToggleViewComponent extends AbstractCustomPropertyView {
  @HostBinding('class.c-card__property') class = true;
}
