import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {Params, Router, RouterModule} from '@angular/router';
import {NavbarModule} from '@aztrix/components/navbar';
import {OverlayService} from '@aztrix/components/overlay';
import {IconsModule} from '@aztrix/icons';

@Component({
  selector: 'lt-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, IconsModule, NavbarModule],
})
export class NavigationButtonComponent {
  @Input() url: string | null = null;
  @Input() queryParams: Params | null = null;
  @Input() label: string;
  @Input() icon: string;
  @Input() color: 'primary' | 'grey' | 'green' | 'red';
  @Input() iconColor: '' | 'green' | 'red' = '';
  @Input() loading = false;
  @Input() small = false;
  @Input() floating = false;
  @Input() ariaLabel: string;

  constructor(
    private _router: Router,
    private _overlay: OverlayService
  ) {}

  get active() {
    return this._router.url.includes('/' + this.url);
  }

  clicked() {
    this._overlay.closeAllModals();
  }
}
