<div class="property toggle">
  <ax-icon
    *ngIf="showIcon"
    class="icon type-icon"
    [name]="customIcon ? customIcon : 'toggle-switch'"
  ></ax-icon>
  <mat-slide-toggle [required]="required" [disabled]="readonly" [formControl]="toggleControl">
    {{ label }} <span *ngIf="required">&nbsp;*</span>
  </mat-slide-toggle>
  <div class="hint-wrapper">
    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      *ngIf="form.valid || form.untouched || form.disabled"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
    <ax-property-error *ngIf="form.touched" [form]="form" [label]="label"></ax-property-error>
  </div>
</div>
