import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ProposalHeaderModule} from '@aztrix/components/proposal-header';
import {HelperPipesModule} from '@aztrix/helpers';
import {ProposalSubscribeStepperModule} from '@aztrix/subscribe/proposal-subscribe-stepper';

import {SubscribeComponent} from './subscribe.component';

@NgModule({
  declarations: [SubscribeComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ProposalHeaderModule,
    ProposalSubscribeStepperModule,
    HelperPipesModule,
  ],
  exports: [SubscribeComponent],
})
export class SubscribeModule {}
