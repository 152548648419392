<ax-proposal-header
  [proposal]="proposal$ | async"
  [language]="language$ | async"
></ax-proposal-header>
<ax-proposal-subscribe-stepper
  [proposal]="proposal$ | async"
  [agreement]="agreement"
  [languageCode]="languageCode"
  [external]="true"
  [orientation]="orientation"
  [verification]="verification"
  [subscriberMetadata]="subscriberMetadata"
  (subscribed)="subscribed.emit($event)"
></ax-proposal-subscribe-stepper>
