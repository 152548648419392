import {Component, HostBinding, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Validators} from '@angular/forms';
import {Property} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {FormControl} from '@ngneat/reactive-forms';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-radio-edit',
  templateUrl: 'custom-radio-edit.component.html',
  styleUrls: ['custom-radio-edit.component.scss', '../show-icon.scss'],
})
export class CustomRadioEditComponent
  extends AbstractProposalPropertyEdit
  implements OnInit, OnChanges, OnDestroy
{
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }
  radioControl = new FormControl();

  formSubscription = new Subscription();
  radioControlSubscription = new Subscription();

  ngOnInit(): void {
    if (this.required) {
      this.radioControl.setValidators(Validators.required);
    }

    this.radioControlSubscription = this.radioControl.valueChanges.subscribe((value) => {
      this.form.get('value')?.setValue(<string>value);
      this.form.markAsTouched();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: Property | PropertyRepresentation) => {
          if (this.radioControl.value !== property?.value) {
            this.radioControl.setValue(property?.value);
          }
        });

      this._disableChanges(this.form.disabled);
      this.valueControl?.registerOnDisabledChange((isDisabled) => this._disableChanges(isDisabled));

      this.radioControl.dirty$.subscribe((dirty) => {
        if (dirty) {
          this.valueControl?.markAsDirty();
        } else {
          this.valueControl?.markAsPristine();
        }
      });
    }
  }

  ngOnDestroy() {
    this.radioControlSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  get isSingleRadio(): boolean {
    return this.valueLabels ? this.valueLabels.length < 1 : true;
  }

  private _disableChanges(isDisabled: boolean) {
    if (isDisabled) {
      this.radioControl.disable({emitEvent: false});
    } else {
      this.radioControl.enable({emitEvent: false});
    }
  }
}
