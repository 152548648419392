<mat-menu #menu="matMenu">
  <ng-content></ng-content>
</mat-menu>

<button
  *ngIf="_hasItems"
  axButton
  class="menu-button cy-menu"
  [icon-button]="iconButton"
  [fullWidth]="fullWidth"
  [ngClass]="{open: _open}"
  [outline]="outline"
  [filled]="filled"
  [bigButton]="bigButton"
  [color]="color"
  [matMenuTriggerFor]="menu"
  (menuOpened)="_open = true"
  (menuClosed)="_open = false"
>
  <ax-icon class="icon" [name]="icon"></ax-icon>
  <ng-content select=".menu-label"></ng-content>
</button>
