import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@aztrix/components/button';
import {DocumentModule} from '@aztrix/components/document';
import {PropertyViewModule} from '@aztrix/components/property-view';
import {SuccessIconModule} from '@aztrix/components/success-icon';
import {ValueViewModule} from '@aztrix/components/value-view';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {CustomPropertyViewModule} from '@aztrix/subscribe/custom-property-view';

import {AgreementDataComponent} from './agreement-data.component';
import {AgreementDocumentComponent} from './agreement-documents/agreement-document/agreement-document.component';
import {AgreementDocumentsComponent} from './agreement-documents/agreement-documents.component';
import {AgreementPropertyViewComponent} from './agreement-property-view/agreement-property-view.component';
import {AgreementStepViewComponent} from './agreement-step-view/agreement-step-view.component';
import {AgreementTemplateViewComponent} from './agreement-template-view/agreement-template-view.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    AgreementDataComponent,
    AgreementStepViewComponent,
    AgreementPropertyViewComponent,
    AgreementDocumentComponent,
    AgreementDocumentsComponent,
    AgreementTemplateViewComponent,
  ],
  imports: [
    CommonModule,
    PropertyViewModule,
    CustomPropertyViewModule,
    ValueViewModule,
    HelperPipesModule,
    ButtonModule,
    IconsModule,
    RouterModule,
    SuccessIconModule,
    DocumentModule,
    TranslatePipe,
  ],
  exports: [AgreementDataComponent],
})
export class AgreementDataModule {}
