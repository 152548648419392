import {Pipe, PipeTransform} from '@angular/core';
import {Property, PropertyType} from '@aztrix/models';

@Pipe({
  name: 'propertiesOfType',
  standalone: true,
})
export class PropertiesOfTypePipe implements PipeTransform {
  transform(properties: Property[], type: PropertyType): Property[] {
    if (!properties || !type) {
      return [];
    }
    return [...properties.filter((p) => p.type === type)];
  }
}
