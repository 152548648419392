import {HttpClient} from '@angular/common/http';
import {Component, Input} from '@angular/core';
import {AvatarType} from '@aztrix/components/avatar';
import {avatarUrl} from '@aztrix/helpers';
import {AgreementRepresentation, ProfileRepresentation} from '@aztrix/sdk';
import {defer, Observable, of} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

@Component({
  selector: 'ax-agreement-subscriber',
  templateUrl: './agreement-subscriber.component.html',
  styleUrls: ['./agreement-subscriber.component.scss', '../property.scss'],
})
export class AgreementSubscriberComponent {
  @Input() agreement: AgreementRepresentation;

  ProfileContext = ProfileRepresentation.ProfileContextEnum;

  agreementHolder$: Observable<ProfileRepresentation | undefined> = defer(() =>
    this.agreement && this.agreement.subscriberId
      ? this._http.get<ProfileRepresentation>(`/r/profiles/${this.agreement.subscriberId}`)
      : of(undefined)
  ).pipe(shareReplay(1));

  avatarType$ = this.agreementHolder$.pipe(
    map((profile) => {
      if (profile?.profileContext === ProfileRepresentation.ProfileContextEnum.INDIVIDUAL) {
        return AvatarType.INDIVIDUAL;
      } else {
        return AvatarType.ORGANISATION;
      }
    })
  );

  subscriberAvatar$: Observable<string> = this.agreementHolder$.pipe(
    map((profile) => avatarUrl(profile))
  );

  constructor(private _http: HttpClient) {}
}
