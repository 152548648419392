import {CommonModule} from '@angular/common';
import {Component, EventEmitter, HostBinding, Output} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {TranslatePipe} from '@aztrix/translate';

@Component({
  selector: 'lt-private-banner',
  standalone: true,
  imports: [CommonModule, IconsModule, TranslatePipe, ButtonModule],
  templateUrl: './private-banner.component.html',
  styleUrl: './private-banner.component.scss',
})
export class PrivateBannerComponent {
  @Output() bannerClicked = new EventEmitter<void>();
  @HostBinding('class.close') close = false;

  didClickBanner(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.bannerClicked.emit();
  }

  didClickClose(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.close = true;
  }
}
