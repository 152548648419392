<div class="section-wrapper" *ngIf="form && language?.askToSendEmail">
  <form [formGroup]="form">
    <div class="send-to-email">
      <mat-checkbox class="check" [formControl]="checkedControl">
        {{ 'proposal.subscribe.question.receive-copy' | translate }}
      </mat-checkbox>
      <ax-property-edit
        *ngIf="emailControl?.enabled"
        [form]="emailControl"
        [autofocus]="false"
        [autocompleteProperties]="myEmailProperties"
        [autocomplete]="true"
        [hint]="language?.askToSendEmailDescription"
      ></ax-property-edit>
    </div>
  </form>
</div>
