import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ReactiveFormsModule} from '@angular/forms';
import {DomainEditModule} from '@aztrix/components/domain-edit';
import {AlistRepresentation, AlistsService, PagesService} from '@aztrix/sdk';
import {FormControl} from '@ngneat/reactive-forms';
import {catchError, debounceTime, first, map, of, ReplaySubject, switchMap} from 'rxjs';

@Component({
  selector: 'lt-alist-domain-edit',
  templateUrl: './alist-domain-edit.component.html',
  styleUrl: './alist-domain-edit.component.scss',
  standalone: true,
  imports: [CommonModule, DomainEditModule, ReactiveFormsModule],
})
export class AlistDomainEditComponent implements OnChanges {
  @Input() alist: AlistRepresentation;
  @Input() form: FormControl<string | undefined>;
  @Input() label: string;
  @Input() postfix: string;
  @Input() readonly = false;

  form$ = new ReplaySubject<FormControl<any>>(0);
  validDomain$ = this.form$.pipe(
    switchMap((form) => form.statusChanges),
    map((status) => status === 'VALID')
  );

  private _domain$ = new ReplaySubject<string>(1);

  domain$ = this._domain$.pipe(
    debounceTime(500),
    switchMap((value: string) => this._alists.getAlistByDomain(value)),
    map((alist) => (alist && alist.domain !== this.alist?.domain ? {'domain-in-use': true} : null)),
    catchError(() => of(null))
  );

  pageDomain$ = this._domain$.pipe(
    debounceTime(500),
    switchMap((value: string) => this._pages.getPageByDomain(value)),
    map((page) => (page ? {'domain-in-use': true} : null)),
    catchError(() => of(null))
  );

  asyncDomainValidator: AsyncValidatorFn = (control: AbstractControl) => {
    this._domain$.next(control.value);
    return this.domain$.pipe(first());
  };

  asyncDomainPageValidator: AsyncValidatorFn = (control: AbstractControl) => {
    this._domain$.next(control.value);
    return this.pageDomain$.pipe(first());
  };

  constructor(
    private _alists: AlistsService,
    private _pages: PagesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.form.addAsyncValidators(this.asyncDomainValidator);
      this.form.addAsyncValidators(this.asyncDomainPageValidator);
      this.form$.next(this.form);
    }

    if (changes.alist && this.alist) {
      this.form.setValue(this.alist?.domain || '');
    }
  }
}
