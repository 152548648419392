@if (proposalLanguage) {
  <ax-property-errors
    [controls]="controls"
    [proposalLanguage]="proposalLanguage"
  ></ax-property-errors>
}

<div class="c-property-edit__error" *ngFor="let types of typeErrors">
  <ax-icon class="icon" name="alert-circle"></ax-icon>
  <span>{{
    'property.validation.required'
      | translate: {propertyType: types[0].type | proposalPropertyTitle: proposalLanguage}
  }}</span>
</div>
