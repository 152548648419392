<div class="metadata property" *ngIf="agreementMetadata?.length">
  <div class="title">{{ 'agreement.agreementMetadata' | translate }}</div>

  <div class="metadata-grid">
    <ng-container *ngFor="let data of agreementMetadata">
      <div class="meta-grid-key">{{ data.key }}</div>
      <div class="meta-grid-value">{{ data.value }}</div>
    </ng-container>
  </div>
</div>
<div class="metadata property" *ngIf="subscriberMetadata?.length">
  <div class="title">{{ 'agreement.subscriberMetadata' | translate }}</div>

  <div class="metadata-grid">
    <ng-container *ngFor="let data of subscriberMetadata">
      <div class="meta-grid-key">{{ data.key }}</div>
      <div class="meta-grid-value">{{ data.value }}</div>
    </ng-container>
  </div>
</div>
<div class="metadata property" *ngIf="sessionMetadata?.length">
  <div class="title">{{ 'agreement.sessionMetadata' | translate }}</div>

  <div class="metadata-grid">
    <ng-container *ngFor="let data of sessionMetadata">
      <div class="meta-grid-key">{{ data.key }}</div>
      <div class="meta-grid-value">{{ data.value }}</div>
    </ng-container>
  </div>
</div>
