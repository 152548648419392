import {Component} from '@angular/core';

import {AbstractItemView} from '../abstract-item-view';

@Component({
  selector: 'lt-item-link-view',
  templateUrl: './item-link-view.component.html',
  styleUrls: ['./item-link-view.component.scss', '../item-view.scss'],
})
export class ItemLinkViewComponent extends AbstractItemView {
  get data() {
    return <string>this.item.data;
  }
}
