<ax-button
  *ngIf="!(firstStep && type === 'back') && !(noSubscribeButton && type === 'next' && lastStep)"
  axButton
  [loading]="loading"
  type="button"
  color="primary"
  [filled]="type !== 'back'"
  (click)="clicked()"
>
  {{ label | translate }}
</ax-button>
