import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BASE_URL} from '@aztrix/environment';
import {typeFilter} from '@aztrix/helpers';
import {AgreementData, Profile, Property, PropertyType, Proposal} from '@aztrix/models';
import {
  AgreementDataRepresentation,
  AgreementPropertyRepresentation,
  AgreementRepresentation,
  ProfileRepresentation,
  ProfilesService,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {combineLatest, defer, EMPTY, Observable, of, ReplaySubject} from 'rxjs';
import {filter, map, mergeMap, shareReplay} from 'rxjs/operators';

@Component({
  selector: 'ax-agreement-overview',
  templateUrl: './agreement-overview.component.html',
  styleUrls: ['./agreement-overview.component.scss', '../property.scss', '../overview.scss'],
})
export class AgreementOverviewComponent implements OnChanges {
  @Input() myProfile?: ProfileRepresentation;
  @Input() proposal?: ProposalRepresentation | Proposal;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() agreementData?: AgreementDataRepresentation;

  @Input() historyData: AgreementData[];
  @Input() isExternal = false;
  @Input() showOutOfSync = true;
  @Output() addProperty = new EventEmitter<Property>();
  @Output() editProperty = new EventEmitter<Property | undefined>();
  @Output() didClickSendEmail = new EventEmitter<void>();

  private _proposal$ = new ReplaySubject<ProposalRepresentation>(1);
  private _agreement$ = new ReplaySubject<AgreementRepresentation>(1);
  private _agreementData$ = new ReplaySubject<AgreementDataRepresentation>(1);
  private _myProfile$ = new ReplaySubject<ProfileRepresentation>(1);

  owner$: Observable<Profile> = this._proposal$.pipe(
    mergeMap((proposal) => (proposal ? this._getProfile(proposal?.ownerId || '') : of(null)))
  );

  isOwner$: Observable<boolean> = combineLatest([this._myProfile$, this.owner$]).pipe(
    map(([myProfile, owner]) => {
      if (myProfile.id === owner.id) {
        return true;
      } else {
        return false;
      }
    })
  );

  agreementHolder$: Observable<Profile> = defer(() =>
    this.agreement && this.agreement.subscriberId
      ? this._getProfile(this.agreement.subscriberId)
      : EMPTY
  ).pipe(shareReplay(1));

  isSubscriber$: Observable<boolean> = combineLatest([this._myProfile$, this._agreement$]).pipe(
    map(([myProfile, agreement]) => myProfile.id === agreement.subscriberId)
  );

  agreementProperties$: Observable<AgreementPropertyRepresentation[] | undefined> =
    this._agreementData$.pipe(
      filter((d) => !!d),
      map((d) => d.agreementProperties)
    );
  properties$ = this.agreementProperties$.pipe(
    map((properties) => properties?.map((p) => p.property))
  );
  emailProperty$ = this.properties$.pipe(
    map((properties) => properties?.find(typeFilter(PropertyType.EMAIL)))
  );

  constructor(
    public dialog: MatDialog,
    private _profiles: ProfilesService,
    @Optional() @Inject(BASE_URL) public baseUrl: string
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.agreement && changes.agreement) {
      this._agreement$.next(this.agreement);
    }

    if (this.proposal && changes.proposal) {
      this._proposal$.next(<ProposalRepresentation>this.proposal);
    }

    if (this.agreementData && changes.agreementData) {
      this._agreementData$.next(this.agreementData);
    }

    if (this.myProfile && changes.myProfile) {
      this._myProfile$.next(this.myProfile);
    }
  }

  private _getProfile(profileId: string): Observable<any> {
    return this._profiles.getProfile(profileId, 'FULL');
  }
}
