import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ax-action-finished',
  templateUrl: 'action-finished.component.html',
  styleUrls: ['action-finished.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFinishedComponent {
  @Input() bodyIconClass? = 'check';
  @Input() bodyIconColorClass: 'green' | 'red' | 'orange' = 'green';
  @Input() bodyTitle: string;
  @Input() bodyExplanation: string;
  @Input() buttonLabel = 'label.ok';
  @Input() showButton = true;
  @Input() showIcon = true;
  @Output() didClickButton = new EventEmitter<void>();
}
