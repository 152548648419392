import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AvatarModule} from '@aztrix/components/avatar';
import {ButtonModule} from '@aztrix/components/button';
import {CopyTextModule} from '@aztrix/components/copy-text';
import {LinkModule} from '@aztrix/components/link';
import {MenuButtonModule} from '@aztrix/components/menu-button';
import {PoweredByAztrixModule} from '@aztrix/components/powered-by-aztrix';
import {ProposalHeaderModule} from '@aztrix/components/proposal-header';
import {SendToEmailModule} from '@aztrix/components/send-to-email';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {ConfirmExpireModalModule} from '@aztrix/subscribe/confirm-expire-modal';

import {AgreementDataModule} from './agreement-data/agreement-data.module';
import {AgreementDownloadPdfButtonComponent} from './agreement-download-pdf-button/agreement-download-pdf-button.component';
import {AgreementExtraInfoComponent} from './agreement-extra-info/agreement-extra-info.component';
import {AgreementVerificationStatusComponent} from './agreement-extra-info/agreement-verification-status/agreement-verification-status.component';
import {AgreementMetadataComponent} from './agreement-metadata/agreement-metadata.component';
import {AgreementHistoryActionModule} from './agreement-overview/agreement-history-action/agreement-history-action.module';
import {AgreementOverviewComponent} from './agreement-overview/agreement-overview.component';
import {AgreementSendToEmailButtonComponent} from './agreement-send-to-email-button/agreement-send-to-email-button.component';
import {AgreementStatusComponent} from './agreement-status/agreement-status.component';
import {AgreementSubscriberComponent} from './agreement-subscriber/agreement-subscriber.component';
import {AgreementVerificationModule} from './agreement-verification/agreement-verification.module';
import {AgreementViewComponent} from './agreement-view.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    AgreementViewComponent,
    AgreementMetadataComponent,
    AgreementStatusComponent,
    AgreementSubscriberComponent,
    AgreementVerificationStatusComponent,
    AgreementOverviewComponent,
    AgreementExtraInfoComponent,
    AgreementDownloadPdfButtonComponent,
    AgreementSendToEmailButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ConfirmExpireModalModule,
    SendToEmailModule,
    SnackbarModule,
    ProposalHeaderModule,
    ButtonModule,
    IconsModule,
    HelperPipesModule,
    TranslatePipe,
    AvatarModule,
    CopyTextModule,
    LinkModule,
    AgreementDataModule,
    AgreementHistoryActionModule,
    MenuButtonModule,
    PoweredByAztrixModule,
    AgreementVerificationModule,
  ],
  exports: [AgreementViewComponent, AgreementOverviewComponent, AgreementDataModule],
})
export class AgreementViewModule {}
