<ng-container [ngSwitch]="customFieldType">
  <ax-custom-labels-view
    *ngSwitchCase="ProposalCustomType.CHECK || ProposalCustomType.CHIPS"
    [property]="property"
    [requestedPropertyType]="requestedPropertyType"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
    [isInListView]="isInListView"
  ></ax-custom-labels-view>
  <ax-custom-label-view
    *ngSwitchCase="ProposalCustomType.RADIO || ProposalCustomType.DROPDOWN"
    [property]="property"
    [requestedPropertyType]="requestedPropertyType"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
  ></ax-custom-label-view>
  <ax-custom-date-value-view
    *ngSwitchCase="ProposalCustomType.DATE"
    [property]="property"
    [requestedPropertyType]="requestedPropertyType"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
  ></ax-custom-date-value-view>
  <ax-custom-rating-view
    *ngSwitchCase="ProposalCustomType.RATING"
    [property]="property"
    [requestedPropertyType]="requestedPropertyType"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
  ></ax-custom-rating-view>
  <ax-custom-rich-text-view
    *ngSwitchCase="ProposalCustomType.RICH_TEXT"
    [property]="property"
    [requestedPropertyType]="requestedPropertyType"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
  ></ax-custom-rich-text-view>
  <ax-custom-toggle-view
    *ngSwitchCase="ProposalCustomType.TOGGLE"
    [property]="property"
    [requestedPropertyType]="requestedPropertyType"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
  ></ax-custom-toggle-view>
  <ax-custom-value-view
    *ngSwitchDefault
    [property]="property"
    [requestedPropertyType]="requestedPropertyType"
    [requestedPropertyTypeDescription]="requestedPropertyTypeDescription"
  ></ax-custom-value-view>
</ng-container>
