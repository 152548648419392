import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {equals, isOverflowing} from '@aztrix/helpers';
import {Property} from '@aztrix/models';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'ax-chips-edit',
  templateUrl: './chips-edit.component.html',
  styleUrls: ['chips-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsEditComponent implements OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() label?: string;
  @Input() hint?: string;
  @Input() required = false;
  @Input() autocompleteValues: string[];
  @Input() disableSpecialFileCharacters = false;
  @Input() errorsTemplate: TemplateRef<unknown>;
  @Input() readonly = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipsCtrl = new UntypedFormControl();
  filteredChips$: Observable<string[]>;
  chips: string[] = [];
  chips$ = new BehaviorSubject<string[]>([]);

  formSubscription = new Subscription();
  chipsCtrlSubscription = new Subscription();
  collapsed = false;
  overflowing = false;

  @ViewChild('chipList') chipList: any;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('hintElement') hintElement: ElementRef;

  constructor() {
    this.filteredChips$ = combineLatest([
      this.chipsCtrl.valueChanges.pipe(startWith(this.chipsCtrl.value)),
      this.chips$,
    ]).pipe(
      map(
        ([value]) =>
          this.autocompleteValues?.filter(
            (chip) =>
              (!value || chip.toLowerCase().indexOf(value?.toLowerCase()) === 0) &&
              !this._duplicate(chip)
          )
      )
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: Property) => {
          const values = property?.value?.split(',').filter((v) => v?.length);
          if (!equals(this.chips$.value, values) && values) {
            this.chips = values;
            this.chips$.next(values);
          }
        });

      this.formSubscription.add(
        this.form.statusChanges.subscribe(() => {
          this._setFormState();
        })
      );
    }
  }

  add({chipInput, value}: MatChipInputEvent): void {
    value = (value || '').trim();

    if (value && !this._duplicate(value)) {
      this.chips.push(value);
      this.chips$.next(this.chips);
    }

    if (chipInput) {
      chipInput.inputElement.value = '';
    }

    this.chipsCtrl.setValue(null);
    this._setFormValue();
  }

  remove(value: string): void {
    const index = this.chips.indexOf(value);

    if (index >= 0) {
      this.chips.splice(index, 1);
      this.chips$.next(this.chips);
    }

    this._setFormState();
    this._setFormValue();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this._duplicate(event.option.viewValue)) {
      this.chips.push(event.option.viewValue.trim());
      this.chips$.next(this.chips);
    }
    this.chipsCtrl.setValue(null);

    this._setFormState();
    this._setFormValue();
  }

  blur(event: any) {
    if (event.chipInput?.inputElement?.tagName?.toLowerCase() !== 'mat-option') {
      this.add(event);
    }
  }

  clearValue() {
    this.chips = [];
    this.chips$.next(this.chips);
    this.form.reset();
    this.form.markAsDirty();
  }

  private _setFormValue() {
    const value = this.chips$.value?.join(',');
    this.form.setValue({...this.form.value, value});
    this.form.markAsDirty();
  }

  private _setFormState() {
    this.chipList.errorState = this.form.invalid;
    this.chipsCtrl.updateValueAndValidity();
  }

  private _duplicate(valueToCheck: string): boolean {
    return (
      this.chips
        .map((chip) => chip.toLowerCase())
        .findIndex((chip) => valueToCheck.trim().toLowerCase() === chip) >= 0
    );
  }

  get hasValue() {
    return this.form?.value?.value;
  }

  isOverflowing() {
    if (!this.overflowing) {
      this.overflowing = isOverflowing(this.hintElement);
      return this.overflowing;
    }

    return this.overflowing;
  }
}
