import {HttpClient} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {getLink} from '@aztrix/helpers';
import {Agreement, AgreementVerificationType, Proposal} from '@aztrix/models';
import {AgreementRepresentation, AgreementsService, ProposalRepresentation} from '@aztrix/sdk';
import {map, Observable, tap} from 'rxjs';

@Component({
  selector: 'ax-agreement-verification',
  templateUrl: './agreement-verification.component.html',
  styleUrls: ['./agreement-verification.component.scss'],
})
export class AgreementVerificationComponent implements OnChanges {
  @Input() proposal?: Proposal | ProposalRepresentation;
  @Input() agreement?: Agreement | AgreementRepresentation;
  @Input() isExternal: boolean;

  @Output() verified = new EventEmitter<void>();

  AgreementVerificationType = AgreementVerificationType;

  _verified = false;
  success = false;
  resendSuccess = false;

  verificationValue: string | undefined;

  verificationCode$ = this._route.queryParams.pipe(
    map((params) => <string>params.verification_code)
  );

  constructor(
    private _agreements: AgreementsService,
    private _http: HttpClient,
    private _changeDetector: ChangeDetectorRef,
    private _route: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.proposal || changes.agreement) {
      this._verified =
        !!this.proposal?.verification &&
        this.proposal.verification !== AgreementVerificationType.NONE &&
        !!this.agreement?.verified &&
        !!this.agreement.active;
      this.verificationValue = this.agreement?.verificationValue;
    }
  }

  verifyIdentityOnAgreement(identityKey: string) {
    if (this.isExternal) {
      this._agreements
        .updateExternalAgreement(<string>this.agreement?.agreementCode, {
          verificationValue: identityKey,
        })
        .subscribe(() => {
          this.success = true;
          this.verified.emit();
        });
    } else {
      this._agreements
        .updateAgreement(<string>this.agreement?.agreementCode, {
          verificationValue: identityKey,
        })
        .subscribe(() => {
          this.success = true;
          this.verified.emit();
        });
    }
  }

  verification = (code: string): Observable<any> => {
    return this._agreements.verify(this.agreement?.id || '', code).pipe(
      tap(() => {
        this._verified = true;
        this.success = true;
        this.verified.emit();
      })
    );
  };

  retryVerification = (verificationValue: string): Observable<any> => {
    if (this.isExternal) {
      return this._http
        .post<Agreement>(
          getLink('r/agreements/external/:agreementCode', {
            agreementCode: this.agreement?.agreementCode,
          }),
          {
            verificationValue,
          }
        )
        .pipe(
          tap(() => {
            this.resendSuccess = true;
            this._changeDetector.detectChanges();
          })
        );
    } else {
      const authToken = localStorage.getItem('authToken');
      return this._http
        .post<Agreement>(
          getLink('r/agreements/:agreementId', {
            agreementId: this.agreement?.id,
          }),
          {
            verificationValue,
          },
          {
            observe: 'body',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: {AuthToken: authToken || ''},
          }
        )
        .pipe(
          tap(() => {
            this.resendSuccess = true;
            this._changeDetector.detectChanges();
          })
        );
    }
  };
}
