import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Component, Input} from '@angular/core';
import {Agreement, ProposalLanguage} from '@aztrix/models';
import {AgreementRepresentation, ProposalLanguageRepresentation} from '@aztrix/sdk';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-download-pdf-button',
  templateUrl: './agreement-download-pdf-button.component.html',
  styleUrls: ['./agreement-download-pdf-button.component.scss'],
})
export class AgreementDownloadPdfButtonComponent {
  @Input() agreement?: Agreement | AgreementRepresentation;
  @Input() language?: ProposalLanguage | ProposalLanguageRepresentation;
  @Input() color: 'primary' | 'transparent' = 'primary';

  loading$ = new BehaviorSubject(false);

  constructor(private _http: HttpClient) {}

  downloadPdf() {
    this.loading$.next(true);
    const params = new HttpParams();
    params.append('lang', this.language?.languageCode || '');
    return this._http
      .get<string>(`r/agreements/external/${this.agreement?.agreementCode}/agreementOverviewPdf`, {
        params,
        headers: new HttpHeaders({'Content-Type': 'text/html'}),
        responseType: 'blob' as 'json',
      })
      .subscribe((pdf) => {
        const blob = new Blob([pdf], {type: 'application/pdf'});
        window.open(window.URL.createObjectURL(blob));
        this.loading$.next(false);
      });
  }
}
