import {Component, HostBinding, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AgreementVerificationType, Property} from '@aztrix/models';
import {ProposalRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-proposal-verification-form',
  templateUrl: './proposal-verification-form.component.html',
  styleUrls: ['./proposal-verification-form.component.scss'],
})
export class ProposalVerificationFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() possibleProperties: Property[];
  @Input() showLabel = true;
  @Input() verificationType?: AgreementVerificationType | ProposalRepresentation.VerificationEnum;
  @Input() verified = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AgreementVerificationType = AgreementVerificationType;

  @HostBinding('class.section-wrapper') get hasVerification() {
    return (
      !this.verified &&
      (this.verificationType === AgreementVerificationType.EMAIL ||
        this.verificationType === AgreementVerificationType.MOBILE)
    );
  }

  get propertyControl() {
    return this.form?.get('property');
  }
}
