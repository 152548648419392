import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {getRequestedPropertyIds, sortAgreementProperties} from '@aztrix/helpers';
import {
  AgreementProperty,
  Profile,
  Property,
  PropertyType,
  Proposal,
  ProposalLanguage,
  ProposalStep,
} from '@aztrix/models';
import {
  AgreementPropertyRepresentation,
  ProfileRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'ax-agreement-step-view',
  templateUrl: './agreement-step-view.component.html',
  styleUrls: ['./agreement-step-view.component.scss'],
})
export class AgreementStepViewComponent implements OnChanges {
  @Input() proposal?: Proposal | ProposalRepresentation;
  @Input() step?: ProposalStep;
  @Input() agreementProperties?: AgreementProperty[] | AgreementPropertyRepresentation[];
  @Input() language?: ProposalLanguage | ProposalLanguageRepresentation;
  @Input() myProfile?: Profile | ProfileRepresentation;
  @Input() showOutOfSync = true;
  @Input() agreementActive = true;
  @Input() agreementConfirmed = false;
  @Output() addProperty = new EventEmitter<Property>();
  @Output() editProperty = new EventEmitter<Property | undefined>();

  private _language$ = new ReplaySubject<ProposalLanguage | ProposalLanguageRepresentation>(1);
  private _proposal$ = new ReplaySubject<Proposal | ProposalRepresentation>(1);

  properties$ = new ReplaySubject<(AgreementProperty | AgreementPropertyRepresentation)[]>(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal && this.proposal) {
      this._proposal$.next(this.proposal);
    }

    if (changes.language && this.language) {
      this._language$.next(this.language);
    }

    if (changes.agreementProperties && this.agreementProperties) {
      if (this.step) {
        let properties = this.agreementProperties.filter((p) =>
          getRequestedPropertyIds(this.step, this.language?.items).includes(
            p.requestedPropertyId || ''
          )
        );
        if (this.proposal) {
          properties = sortAgreementProperties(this.proposal, properties);
          this.properties$.next(properties);
        }
      } else {
        this.properties$.next(this.agreementProperties);
      }
    }
  }
}
