import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ButtonModule} from '@aztrix/components/button';
import {OtpVerificationModule} from '@aztrix/components/otp-verification';
import {PassbaseButtonModule} from '@aztrix/components/passbase-button';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';

import {AgreementVerificationComponent} from './agreement-verification.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [AgreementVerificationComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslatePipe,
    MatFormFieldModule,
    MatInputModule,
    IconsModule,
    PassbaseButtonModule,
    HelperPipesModule,
    ButtonModule,
    OtpVerificationModule,
  ],
  exports: [AgreementVerificationComponent],
})
export class AgreementVerificationModule {}
