import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {HelperPipesModule} from '@aztrix/helpers';

import {ProposalSubscribeSendToEmailComponent} from './proposal-subscribe-send-to-email.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [ProposalSubscribeSendToEmailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    PropertyEditModule,
    MatCheckboxModule,
  ],
  exports: [ProposalSubscribeSendToEmailComponent],
})
export class ProposalSubscribeSendToEmailModule {}
