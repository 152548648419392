<ax-spinner *ngIf="(product$ | async) === null; else content"></ax-spinner>

<ng-template #content>
  <ng-container *ngIf="product$ | async as product">
    <div class="product-info">
      <h4>{{ product.name }}</h4>
      <h2 class="price">
        {{ (product.price?.amount || 0) / 100 | currency: product.price?.currency?.toUpperCase() }}
      </h2>
      <p>{{ product.description }}</p>
    </div>
  </ng-container>

  <ax-spinner *ngIf="(paymentIntent$ | async) === null; else checkout"></ax-spinner>
  <ng-template #checkout>
    <ax-stripe-checkout
      [clientSecret]="(paymentIntent$ | async)?.clientSecret"
      [accountId]="(paymentIntent$ | async)?.stripeAccount"
      (cancel)="done.next()"
      (payed)="done.next()"
    ></ax-stripe-checkout>
  </ng-template>
</ng-template>
