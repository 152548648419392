import {Directive, Input} from '@angular/core';
import {AbstractButton} from '@aztrix/components/button';

import {SettingButtonSpacing} from './setting-button.component';

@Directive()
export abstract class AbstractSettingButton extends AbstractButton {
  @Input() title?: string;
  @Input() hint?: string;
  @Input() responsive = false;
  @Input() spacing = SettingButtonSpacing.LARGE;

  @Input() override color = 'white';
}
