import {Pipe, PipeTransform} from '@angular/core';
import {differenceInSeconds} from 'date-fns';

/**
 * counts the seconds between the given time and now
 **/
@Pipe({name: 'secondsFromNow', pure: false})
export class SecondsFromNowPipe implements PipeTransform {
  transform(value: number | Date): number {
    return differenceInSeconds(value, Date.now());
  }
}
