<div class="document">
  <button
    *ngIf="documentType !== 'SIGNING-ORDER'"
    [disabled]="(loading$ | async) || false"
    class="sign-button"
    axButton
    type="button"
    [filled]="!form.value"
    [color]="(error$ | async) ? '' : 'primary'"
    (click)="signDocument()"
  >
    <ax-icon class="icon" [name]="signIcon"></ax-icon>
    {{ signLabelKey | translate }}
  </button>
  <ax-document
    [document]="document"
    [documentType]="documentType"
    [agreementDocument]="agreementDocument"
    [isAgreementDocument]="false"
    [isSignableDocument]="true"
    [agreementProperties]="agreementProperties"
    [proposal]="proposal"
    [proposalLanguage]="proposalLanguage"
    [demo]="demo"
  ></ax-document>
</div>
<p class="document-description">{{ document.description }}</p>
<ng-container *ngIf="(error$ | async) !== null">
  <ng-container *ngTemplateOutlet="error; context: {$implicit: errorMessageTranslated | translate}">
  </ng-container>
</ng-container>
<ng-container *ngIf="form?.touched && form?.invalid">
  <ng-container
    *ngTemplateOutlet="
      error;
      context: {
        $implicit: 'document.signing.required' | translate
      }
    "
  >
  </ng-container>
</ng-container>

<ng-template #error let-message>
  <mat-error>
    <div class="c-property-edit__error">
      <ax-icon class="icon" name="alert-circle"></ax-icon>
      <span>{{ message }}</span>
    </div>
  </mat-error>
</ng-template>
