import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PassbaseButtonModule} from '@aztrix/components/passbase-button';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';

import {ProposalVerificationFormComponent} from './proposal-verification-form.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [ProposalVerificationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconsModule,
    HelperPipesModule,
    PropertyEditModule,
    PassbaseButtonModule,
    TranslatePipe,
  ],
  exports: [ProposalVerificationFormComponent],
})
export class ProposalVerificationFormModule {}
