<ax-proposal-subscribe-form
  [form]="form"
  [proposal]="proposal || undefined"
  [agreement]="agreement"
  [agreementCode]="agreementCode"
  [myProfile]="myProfile"
  [languageCode]="languageCode"
  [edit]="edit"
  [demo]="demo"
  [collapsibleGroups]="collapsibleGroups"
  [autocompleteProperties]="(autocompleteProperties$ | async) || undefined"
  [noSubscribeButton]="noSubscribeButton"
  [open]="open"
  [orientation]="orientation"
  [external]="external"
  [subscriberMetadata]="subscriberMetadata"
  [subscribeLabel]="subscribeLabel"
  (subscribed)="subscribed.next($event)"
>
  <ng-content></ng-content>
</ax-proposal-subscribe-form>
