import {Pipe, PipeTransform} from '@angular/core';
import {itemAllowedInTier} from '@aztrix/helpers';
import {
  AlistItemRepresentation,
  AlistItemWithStringRepresentation,
  TierSubscriptionInfoRepresentation,
} from '@aztrix/sdk';
import {ControlsOf, ValuesOf} from '@ngneat/reactive-forms';

@Pipe({name: 'itemAllowedInTier'})
export class ItemAllowedInTierPipe implements PipeTransform {
  transform(
    currentTier: TierSubscriptionInfoRepresentation.CurrentTierEnum | undefined,
    item:
      | AlistItemWithStringRepresentation
      | ValuesOf<ControlsOf<AlistItemRepresentation>>
      | undefined
  ): unknown {
    return itemAllowedInTier(currentTier, item);
  }
}
