import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Params} from '@angular/router';
import {ConfirmPaymentComponent} from '@aztrix/components/confirm-payment';
import {OverlayService} from '@aztrix/components/overlay';
import {MatomoService} from '@aztrix/helpers';
import {CommunicationType} from '@aztrix/models';
import {
  AlistItemRepresentation,
  AlistItemWithStringRepresentation,
  AlistPropertyRepresentation,
  AlistRepresentation,
} from '@aztrix/sdk';
import {ProposalSubscribeModalComponent} from '@aztrix/subscribe/proposal-subscribe-modal';

import {ItemItemsViewComponent} from './item-items-view/item-items-view.component';

@Directive()
export abstract class AbstractItemView implements OnChanges {
  @Input() alist: AlistRepresentation;
  @Input() item: AlistItemRepresentation;
  @Input() params: Params | null;

  ItemType = AlistItemWithStringRepresentation.TypeEnum;
  ItemFormat = AlistItemWithStringRepresentation.FormatEnum;
  DataType = AlistItemWithStringRepresentation.TypeEnum;

  CommunicationType = CommunicationType;

  property: AlistPropertyRepresentation;

  get ownerId() {
    return this.alist.ownerId;
  }

  constructor(
    private _matomo: MatomoService,
    private _overlay: OverlayService,
    private _elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && this.item && this.item.data) {
      this.property = <AlistPropertyRepresentation>this.item.data;
    }
  }

  openSubscribe() {
    this.trackClick();
    this._overlay.createModal(this._elementRef, ProposalSubscribeModalComponent, {
      closeOnBackdropClick: false,
      init: (modal) => {
        modal.proposalId = <string>this.item.data;
        modal.languageCode = 'en';
      },
    });
  }

  openPayModal(event: Event) {
    event.preventDefault();
    this.trackClick();

    this._overlay.createModal(this._elementRef, ConfirmPaymentComponent, {
      title: 'label.pay',
      shadowDOM: false,
      closeOnBackdropClick: false,
      init: (modal) => {
        modal.productId = <string>this.item.data;
        modal.ownerId = this.ownerId || '';
        modal.setPaymentIntent();

        modal.done.subscribe(() => {
          this._overlay.closeModal();
        });
      },
    });
  }

  openPropertiesModal(event: Event) {
    event.preventDefault();
    this.trackClick();
    this._overlay.createModal(this._elementRef, ItemItemsViewComponent, {
      title: this.item.title || 'item.type.PROPERTIES',
      init: (modal) => {
        modal.alist = this.alist;
        modal.item = this.item;
        modal.params = this.params;
        modal.onlyProperties = true;
      },
    });
  }

  openItemsModal(event: Event) {
    event.preventDefault();
    this.trackClick();
    this._overlay.createModal(this._elementRef, ItemItemsViewComponent, {
      title: this.item.title || 'item.type.PRODUCT_CARD',
      init: (modal) => {
        modal.alist = this.alist;
        modal.item = this.item;
        modal.params = this.params;
      },
    });
  }

  trackClick() {
    this._matomo.trackEvent('Item', 'click');
  }
}
