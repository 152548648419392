<div class="property" *ngIf="agreement?.subscriberId">
  <div class="title">{{ 'agreement.subscriber' | translate }}</div>
  <ng-container *ngIf="(agreementHolder$ | async) === undefined">
    {{ 'agreement.subscriber.private' | translate }}
  </ng-container>
  <a
    *ngIf="agreementHolder$ | async"
    [axLink]="['/profile', (agreementHolder$ | async)?.id]"
    class="profile"
  >
    <ax-avatar
      [source]="(subscriberAvatar$ | async) || undefined"
      [type]="(avatarType$ | async) || undefined"
      [round]="(agreementHolder$ | async)?.profileContext === ProfileContext.INDIVIDUAL"
    ></ax-avatar>
    <span>{{ agreementHolder$ | async | displayName }}</span>
  </a>
</div>
