<ax-agreement-history-action
  [proposal]="proposal"
  [agreement]="agreement"
  [agreementData]="agreementData"
  [historyData]="historyData"
  [isExternal]="isExternal"
></ax-agreement-history-action>

<ax-agreement-data
  *ngIf="agreement?.agreementData"
  [myProfile]="myProfile"
  [proposal]="proposal"
  [language]="language"
  [agreementProperties]="(agreementProperties$ | async) || []"
  [showOutOfSync]="showOutOfSync"
  [agreementActive]="agreement?.active || false"
  [agreementConfirmed]="agreement?.agreementData?.confirmed || false"
  [agreementDocuments]="agreement?.agreementData?.documents || []"
  (addProperty)="addProperty.next($event)"
  (editProperty)="editProperty.next($event)"
></ax-agreement-data>

<ax-agreement-extra-info
  *ngIf="!isExternal"
  class="overview"
  [myProfile]="myProfile"
  [proposal]="proposal"
  [language]="language"
  [agreement]="agreement"
  [agreementData]="agreement?.agreementData"
  (didClickSendEmail)="didClickSendEmail.next($event)"
></ax-agreement-extra-info>

<ax-agreement-metadata
  *ngIf="!isExternal && (isOwner$ | async)"
  class="overview"
  [agreement]="agreement"
></ax-agreement-metadata>
