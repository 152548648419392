<div class="header">
  <span class="label">{{ label }}<span *ngIf="required">&nbsp;*</span></span>

  <button
    axButton
    icon-button
    type="button"
    *ngIf="form?.enabled && hasValue && !readonly"
    (click)="clearValue()"
  >
    <ax-icon class="icon" name="close"></ax-icon>
  </button>
</div>
<form [formGroup]="form">
  <span class="stars" [ngClass]="{readonly: readonly}">
    <ng-container *ngFor="let number of ['5', '4', '3', '2', '1']">
      <input
        [formControl]="form.get('value')"
        type="radio"
        [id]="'rating-' + number"
        name="rating"
        [value]="number"
        [readonly]="readonly"
        [checked]="form.get('value')?.value === number"
      />
      <label [for]="'rating-' + number" (click)="form.touched ? null : form.markAsTouched()">
        <ax-icon class="icon star" name="star"></ax-icon>
      </label>
    </ng-container>
  </span>
</form>
