import {Component, HostBinding} from '@angular/core';

import {AbstractCustomPropertyView} from '../abstract-custom-property-view';

@Component({
  selector: 'ax-custom-label-view',
  template: `{{ label || ('property.value.empty' | translate) }}`,
})
export class CustomLabelViewComponent extends AbstractCustomPropertyView {
  @HostBinding('class.c-card__property') class = true;

  get label() {
    return this.valueLabels.find((valueLabel) => this.property.value === valueLabel.value)?.label;
  }
}
