import {Component, OnChanges} from '@angular/core';

import {AbstractItemView} from '../abstract-item-view';

@Component({
  selector: 'lt-item-property-view',
  templateUrl: './item-property-view.component.html',
  styleUrls: ['./item-property-view.component.scss', '../item-view.scss'],
})
export class ItemPropertyViewComponent extends AbstractItemView implements OnChanges {}
