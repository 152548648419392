import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {NavbarComponent} from './navbar.component';
import {NavbarItemDirective} from './navbar-item.directive';
import {NavbarBottomSheetComponent} from './navbar-more/navbar-bottom-sheet/navbar-bottom-sheet.component';
import {NavbarMoreComponent} from './navbar-more/navbar-more.component';

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarItemDirective,
    NavbarMoreComponent,
    NavbarBottomSheetComponent,
  ],
  imports: [CommonModule, IconsModule, TranslatePipe, MatBottomSheetModule],
  exports: [NavbarComponent, NavbarItemDirective, NavbarMoreComponent],
})
export class NavbarModule {}
