<div class="wings">
  <svg width="30" height="30">
    <path fill="#fff" d="M0,0 L0,30 L30,30 C15,30 0,15 0,0"></path>
  </svg>
  <svg width="30" height="30">
    <path fill="#fff" d="M0,30 L30,30 L30,0 C30,15 15,30 0,30"></path>
  </svg>
</div>
<nav>
  <ng-content></ng-content>
</nav>
