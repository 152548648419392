<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [ngClass]="{single: isSingleCheck}"
  [name]="customIcon ? customIcon : 'card-text'"
></ax-icon>
<div>
  <mat-checkbox
    class="check"
    *ngIf="isSingleCheck; else title"
    [checked]="checked()"
    [disabled]="form.disabled"
    (change)="update(values[0] || 'TRUE', $event.checked)"
  >
    {{ label }}<span *ngIf="required">&nbsp;*</span>
  </mat-checkbox>

  <ng-template #title>
    <div class="header">
      <span class="label">{{ label }}<span *ngIf="required">&nbsp;*</span></span>

      <button
        axButton
        icon-button
        type="button"
        *ngIf="form?.enabled && hasValue && !readonly"
        (click)="clearValue()"
      >
        <ax-icon class="icon" name="close"></ax-icon>
      </button>
    </div>
  </ng-template>

  <div class="checkboxes" *ngIf="!isSingleCheck">
    <mat-checkbox
      *ngFor="let valueTranslation of valueLabels"
      [value]="valueTranslation.value || ''"
      [ngClass]="{readonly: readonly}"
      [name]="getName() + ' ' + valueTranslation.value"
      [checked]="checked(valueTranslation.value)"
      [disabled]="form.disabled"
      (change)="update(valueTranslation.value, $event.checked)"
    >
      {{ valueTranslation.label }}
    </mat-checkbox>
  </div>
  <div class="hint-wrapper">
    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      *ngIf="!isSingleCheck && (form.valid || form.untouched || form.disabled)"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
    <ax-property-error *ngIf="form.touched" [form]="form" [label]="label"></ax-property-error>
  </div>
</div>
