import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'ax-navbar-bottom-sheet',
  templateUrl: './navbar-bottom-sheet.component.html',
  styleUrls: ['./navbar-bottom-sheet.component.scss'],
})
export class NavbarBottomSheetComponent {
  @Input() content: TemplateRef<unknown>;
}
