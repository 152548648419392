import {CommonModule} from '@angular/common';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ButtonModule} from '@aztrix/components/button';
import {AnalyticsService, defaultConsent, grantedConsent} from '@aztrix/helpers';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {TranslatePipe} from '@aztrix/translate';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'lt-cookie-preferences',
  templateUrl: './cookie-preferences.component.html',
  styleUrls: ['../cookie-modal.scss', './cookie-preferences.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslatePipe, ButtonModule, IconsModule, MatSlideToggleModule],
})
export class CookiePreferencesComponent implements OnInit {
  @Output() _close = new EventEmitter<void>();

  consentForm = this._analyticsService.consentForm;

  currentConsent = this._analyticsService.getConsentFromLocalStorage() || defaultConsent;

  constructor(private _analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.consentForm.patchValue(this.currentConsent);
  }

  get adStrorageConsentControl() {
    return this.consentForm.get('ad_storage') ?? new FormControl(undefined);
  }

  get adUserDataConsent() {
    return this.consentForm.get('ad_user_data') ?? new FormControl(undefined);
  }

  get adPersonalizationConsent() {
    return this.consentForm.get('ad_personalization') ?? new FormControl(undefined);
  }

  get analyticsStorageConsent() {
    return this.consentForm.get('analytics_storage') ?? new FormControl(undefined);
  }

  confirmSelection() {
    this._analyticsService.updateConsent(this.consentForm.value);
    this._close.emit();
  }

  grantAll() {
    this._analyticsService.updateConsent(grantedConsent);
    this._close.emit();
  }

  denyAll() {
    this._analyticsService.updateConsent(defaultConsent);
    this._close.emit();
  }

  updateConsentValue(control: AbstractControl) {
    if (control.value === 'granted') {
      control.setValue('denied');
    } else {
      control.setValue('granted');
    }
  }
}
