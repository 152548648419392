import {Component} from '@angular/core';

import {AbstractItemView} from '../abstract-item-view';

@Component({
  selector: 'lt-item-text-view',
  templateUrl: './item-text-view.component.html',
  styleUrl: './item-text-view.component.scss',
})
export class ItemTextViewComponent extends AbstractItemView {
  get data() {
    return <string>this.item.data;
  }
}
