<ax-value-edit
  type="TEXT"
  [form]="alistNameForm"
  [label]="label | translate"
  [required]="true"
  [prefix]="prefix ?? (backendUrl$ | async) + '/'"
  [errorsTemplate]="errorsTemplate"
  [readonly]="readonly"
  [postfix]="postfix"
></ax-value-edit>

<ng-template #errorsTemplate>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.alistExists">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'register.error.alreadyExists' | translate: {name: alistNameForm.errors?.alistExists}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.required">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.required' | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.propertyNoSpace">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.invalidCharacters.space'
        | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.propertyInvalidCharacters">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.invalidCharacters.contain'
        | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
  <div class="c-property-edit__error" *ngIf="alistNameForm.errors?.propertyMaxCharacters">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.maxCharacters' | translate: {propertyType: 'label.name' | translate}
    }}</span>
  </div>
</ng-template>
