import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PropertyEditModule} from '@aztrix/components/property-edit';

import {CustomPropertyEditModule} from './custom-property-edit/custom-property-edit.module';
import {ProposalFileEditComponent} from './proposal-file-edit/proposal-file-edit.component';
import {ProposalImageEditComponent} from './proposal-image-edit/proposal-image-edit.component';
import {ProposalPropertyEditComponent} from './proposal-property-edit.component';

@NgModule({
  declarations: [
    ProposalPropertyEditComponent,
    ProposalFileEditComponent,
    ProposalImageEditComponent,
  ],
  imports: [CommonModule, PropertyEditModule, CustomPropertyEditModule],
  exports: [ProposalPropertyEditComponent, CustomPropertyEditModule],
})
export class ProposalPropertyEditModule {}
