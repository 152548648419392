<a
  axNavbarItem
  *ngIf="url; else button"
  class="button"
  [ngClass]="[
    color || '',
    small ? 'small' : '',
    floating ? 'floating' : '',
    loading ? 'loading' : ''
  ]"
  [routerLink]="[url]"
  [queryParams]="queryParams"
  queryParamsHandling="merge"
  [active]="active"
  [attr.aria-label]="label || ariaLabel"
  (click)="clicked()"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>

<ng-template #button>
  <button
    axButton
    class="button"
    [ngClass]="[
      color || '',
      small ? 'small' : '',
      floating ? 'floating' : '',
      loading ? 'loading' : ''
    ]"
    [attr.aria-label]="label || ariaLabel"
    (click)="clicked()"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </button>
</ng-template>
<ng-template #content>
  <div class="content" [ngClass]="{avatar: !icon && !label}">
    <ax-icon
      *ngIf="icon"
      class="icon prefix"
      [ngClass]="[loading ? '' : iconColor]"
      [name]="icon"
    ></ax-icon>
    <ng-content select=".avatar"></ng-content>
    <span *ngIf="label" class="label">
      {{ label }}
    </span>
  </div>
</ng-template>
