import {Directive, ElementRef, Input, ViewChild} from '@angular/core';
import {iconNameForCustomPropertyType, isOverflowing} from '@aztrix/helpers';
import {
  CustomFieldValueLabel,
  Property,
  PropertyType,
  ProposalCustomType,
  ProposalPropertyType,
  ProposalPropertyTypeDescription,
} from '@aztrix/models';
import {
  CustomFieldInfoRepresentation,
  CustomFieldValueLabelRepresentation,
  ProfilePropertyRepresentation,
  RequestedPropertyTypeDescriptionRepresentation,
  RequestedPropertyTypeRepresentation,
} from '@aztrix/sdk';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';

let nextUniqueId = 0;

@Directive()
export abstract class AbstractProposalPropertyEdit {
  @Input() form: FormGroup<ControlsOf<Property | ProfilePropertyRepresentation>>;
  @Input() name?: string;
  @Input() autofocus: boolean;
  @Input() required = false;
  @Input() showIcon = true;

  @Input() proposalId: string;
  @Input() requestedPropertyId: string;
  @Input() requestedPropertyType?: ProposalPropertyType | RequestedPropertyTypeRepresentation;
  @Input() requestedPropertyTypeDescription?:
    | ProposalPropertyTypeDescription
    | RequestedPropertyTypeDescriptionRepresentation;
  @Input() readonly = false;
  @ViewChild('hintElement') hintElement: ElementRef;

  uniqueId = nextUniqueId++;
  collapsed = false;
  overflowing = false;

  get valueControl() {
    return <FormControl<string | number | Date | undefined>>this.form.get('value');
  }

  get valueLabels(): (CustomFieldValueLabel | CustomFieldValueLabelRepresentation)[] {
    const valueLabels = this.requestedPropertyTypeDescription?.customFieldDescription?.valueLabels;
    if (!valueLabels) {
      return [];
    }
    return [...valueLabels].sort((vl1, vl2) => {
      if (vl1.orderIndex === vl2.orderIndex) {
        return 0;
      }
      return (vl1.orderIndex || -1) < (vl2.orderIndex || -1) ? -1 : 1;
    });
  }

  get values(): string[] {
    return this.requestedPropertyType?.customFieldInfo?.values || [];
  }

  get label(): string | undefined {
    return this.requestedPropertyTypeDescription?.customFieldDescription?.label;
  }

  get customIcon(): string | undefined {
    return this.requestedPropertyTypeDescription?.customFieldDescription?.icon;
  }

  get hint(): string | undefined {
    return this.requestedPropertyTypeDescription?.description;
  }

  get type(): PropertyType | undefined {
    return this.requestedPropertyType?.type;
  }

  get customFieldType(): ProposalCustomType | CustomFieldInfoRepresentation.TypeEnum | undefined {
    return this.requestedPropertyType?.customFieldInfo?.type;
  }

  get hasValue() {
    return this.form?.value?.value;
  }

  getName() {
    return [this.name, this.form.value.type, this.customFieldType, this.uniqueId]
      .filter((v) => !!v)
      .join(' ');
  }

  getCustomPropertyIcon(): string {
    return iconNameForCustomPropertyType(this.customFieldType);
  }

  clearValue() {
    this.valueControl?.setValue('');
  }

  isOverflowing() {
    if (!this.overflowing) {
      this.overflowing = isOverflowing(this.hintElement);
      return this.overflowing;
    }

    return this.overflowing;
  }
}
