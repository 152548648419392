import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {hasValue, Subscribe} from '@aztrix/helpers';
import {Property, PropertyType, ProposalLanguage} from '@aztrix/models';
import {ProposalLanguageRepresentation} from '@aztrix/sdk';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ax-proposal-subscribe-send-to-email',
  templateUrl: './proposal-subscribe-send-to-email.component.html',
  styleUrls: ['./proposal-subscribe-send-to-email.component.scss', 'section.scss'],
})
export class ProposalSubscribeSendToEmailComponent implements OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() language?: ProposalLanguage | ProposalLanguageRepresentation;

  private _subscriptions = new Subscription();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.language && this.language && this.checkedControl) {
      this.checkedControl.setValue(this.language.askToSendEmail);
    }

    if (changes.form && this.form) {
      this._subscriptions?.unsubscribe();
      this._subscriptions = new Subscription();

      this._subscriptions.add(
        this.checkedControl?.valueChanges.subscribe((checked) => {
          if (this.emailControl) {
            if (checked) {
              this.emailControl.enable();
            } else {
              this.emailControl.disable();
            }
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  get checkedControl() {
    return <FormControl>this.form?.get('checked');
  }

  get emailControl() {
    return this.form?.get('email');
  }

  get myEmailProperties(): Property[] {
    return Subscribe.formProperties(this.form.value).filter(
      (property) => property.type === PropertyType.EMAIL && hasValue(property)
    );
  }
}
