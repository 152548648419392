import {Component, OnDestroy, OnInit} from '@angular/core';
import {defaultFormat} from '@aztrix/helpers';
import {FormControl} from '@ngneat/reactive-forms';
import {format, isEqual, isValid, parse} from 'date-fns';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-date-edit',
  templateUrl: './custom-date-edit.component.html',
  styleUrls: ['./custom-date-edit.component.scss'],
})
export class CustomDateEditComponent
  extends AbstractProposalPropertyEdit
  implements OnInit, OnDestroy
{
  dateControl = new FormControl<Date | number>();

  private _subscriptions = new Subscription();

  ngOnInit() {
    this._subscriptions.unsubscribe();
    this._subscriptions = new Subscription();
    this._subscriptions.add(
      this.dateControl.valueChanges.subscribe((value) => {
        if (isValid(value)) {
          this.valueControl?.setValue(format(+value, defaultFormat));
        } else {
          this.valueControl?.setValue('');
        }
      })
    );
    this._subscriptions.add(
      this.valueControl?.valueChanges
        .pipe(startWith(this.valueControl.value))
        .subscribe((value) => {
          if (!value) {
            return;
          }

          const date = parse(<string>value, defaultFormat, new Date());

          if (!isEqual(date, this.dateControl.value)) {
            this.dateControl.setValue(date);
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
