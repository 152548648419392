import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {ActionFinishedModule} from '@aztrix/components/action-finished';
import {ButtonModule} from '@aztrix/components/button';
import {PropertyEditModule} from '@aztrix/components/property-edit';
import {TranslatePipe} from '@aztrix/translate';

import {SendToEmailComponent} from './send-to-email.component';

@NgModule({
  declarations: [SendToEmailComponent],
  imports: [
    CommonModule,
    PropertyEditModule,
    TranslatePipe,
    ReactiveFormsModule,
    ButtonModule,
    ActionFinishedModule,
  ],
  exports: [SendToEmailComponent],
})
export class SendToEmailModule {}
