import {Pipe, PipeTransform} from '@angular/core';
import {propertyCommunicationType} from '@aztrix/helpers';
import {
  AlistItemRepresentation,
  AlistItemWithPropertyRepresentation,
  AlistPropertyRepresentation,
} from '@aztrix/sdk';
import {ControlsOf, ValuesOf} from '@ngneat/reactive-forms';

@Pipe({
  name: 'canChangeIcon',
})
export class CanChangeIconPipe implements PipeTransform {
  transform(
    item: AlistItemRepresentation | ValuesOf<ControlsOf<AlistItemRepresentation>> | undefined
  ): boolean {
    return (
      item?.type !== AlistItemWithPropertyRepresentation.TypeEnum.HEADER &&
      item?.type !== AlistItemWithPropertyRepresentation.TypeEnum.TEXT &&
      (item?.type !== AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY ||
        !!propertyCommunicationType(<AlistPropertyRepresentation>item?.data))
    );
  }
}
