<div class="header">
  <div></div>
  <h2 class="name proposal">{{ language?.name }}</h2>
  <ng-content></ng-content>
</div>

<p axChip color="warning" *ngIf="proposal?.retentionDuration" class="retention-duration">
  {{ 'proposal.retentionDuration.value' | translate: {days: proposal?.retentionDuration} }}
</p>

<p class="description">{{ language?.description }}</p>
