<ng-container
  *ngIf="
    (isHistoryAgreement$ | async) === false && (canViewHistory$ | async) && historyData?.length
  "
>
  <a
    class="history-link"
    [axLink]="
      isExternal
        ? ('external_agreement_history' | routerLink: {code: agreement?.agreementCode})
        : ('agreement_history' | routerLink: {proposalId: proposal?.id, agreementId: agreement?.id})
    "
  >
    {{ 'agreement.history.view' | translate }}
  </a>
</ng-container>
