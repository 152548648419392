import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  Validators,
} from '@angular/forms';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';

@Component({
  selector: 'ax-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: OtpInputComponent}],
})
export class OtpInputComponent
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  static nextId = 0;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  form = new FormControl<string | null>({value: null, disabled: false}, [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(6),
  ]);

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'otp-input';
  id = `otp-input-${OtpInputComponent.nextId++}`;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  get empty() {
    return !this.form.value?.length;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    return this.form.value;
  }
  set value(value: string | null) {
    if (!value) {
      value = '';
    }

    const chars = [...value.toUpperCase()]
      .filter((char) => {
        const code = char.charCodeAt(0);
        return code > 47 && code < 91;
      })
      .slice(0, 6);

    this.form.setValue(chars.join(''));
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.form.invalid && this.touched;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    this._elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.input, 'program');
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl): void {
    const caretPosition = this.input.nativeElement.selectionStart;

    control.setValue(control.value.toUpperCase());

    this.writeValue(this.value);
    this.onChange(this.value);

    this.input.nativeElement.setSelectionRange(caretPosition, caretPosition);
  }

  paste(event: ClipboardEvent) {
    event.preventDefault();
    if (event.clipboardData) {
      this.value = event.clipboardData.getData('text')?.toUpperCase();
    } else {
      navigator.clipboard.readText().then((text) => (this.value = text.toUpperCase()));
    }
    this.onChange(this.value);
  }
}
