import {Component} from '@angular/core';

import {AbstractCustomPropertyView} from '../abstract-custom-property-view';

@Component({
  selector: 'ax-custom-rating-view',
  templateUrl: './custom-rating-view.component.html',
  styleUrls: ['./custom-rating-view.component.scss'],
})
export class CustomRatingViewComponent extends AbstractCustomPropertyView {
  starCount = 5;
  counter = Array(this.starCount)
    .fill(1)
    .map((_x, i) => i + 1);
}
