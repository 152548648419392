import {ChangeDetectionStrategy, Component} from '@angular/core';

import {ValueView} from '../../value-view';

@Component({
  selector: 'ax-file-value-view',
  templateUrl: './file-value-view.component.html',
  styleUrls: ['./file-value-view.component.scss', '../../value-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileValueViewComponent extends ValueView {
  get name() {
    try {
      return JSON.parse(this.value).name;
    } catch {
      return '';
    }
  }
}
