<ng-template #content>
  <ax-subscribe-form
    [proposal]="(proposal$ | async) || undefined"
    [agreement]="agreement"
    [languageCode]="languageCode"
    [subscriberMetadata]="subscriberMetadata"
  ></ax-subscribe-form>
</ng-template>

<ng-container *ngIf="error$ | async; else content">
  <p class="error">
    Proposal with id {{ proposalId }} could not be retrieved. Please try again later
  </p>
</ng-container>
