import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ComponentsThemeComponent} from './components-theme.component';

@NgModule({
  declarations: [ComponentsThemeComponent],
  imports: [CommonModule],
  exports: [ComponentsThemeComponent],
})
export class ComponentsThemeModule {}
