<ng-template #content>
  <ng-content select=".content"></ng-content>
</ng-template>

<ng-template #post>
  <ng-content select=".post"></ng-content>
</ng-template>

<ax-button
  class="button"
  [ngClass]="{filled: _filled, outline: _outline}"
  [type]="type"
  [color]="''"
  [hover]="false"
  [labelHideMobile]="_labelHideMobile"
  [smallButton]="_smallButton"
  [fullWidth]="_fullWidth"
  [filled]="true"
  [outline]="_outline"
  [icon-button]="_iconButton"
  [hoverLabel]="_hoverLabel"
  [disabled]="disabled"
  [ariaLabel]="title || ariaLabel"
  (click)="clicked()"
>
  <ax-spinner class="pre" *ngIf="_loading" [ngClass]="'ax-' + color"></ax-spinner>
  <div class="pre" *ngIf="!_loading" [ngClass]="'ax-' + color">
    <ax-icon *ngIf="icon" class="icon data-icon" [name]="icon"></ax-icon>
    <ng-content *ngIf="!icon" select=".pre"></ng-content>
  </div>

  <div *ngIf="!_iconButton" class="button-content" [ngClass]="'ax-' + color">
    <div class="data">
      <div class="title" *ngIf="title">{{ title }}</div>
      <ng-content select=".data"></ng-content>
      <div class="hint" *ngIf="hint">{{ hint }}</div>
    </div>

    <div class="post">
      <ng-container *ngTemplateOutlet="post"></ng-container>
      <ax-icon
        *ngIf="postIcon"
        class="icon post-icon"
        [ngClass]="{open: open && !small}"
        [name]="responsive && !small ? 'chevron-down' : postIcon"
      ></ax-icon>
    </div>
  </div>
</ax-button>
<div
  class="panel"
  [ngClass]="[open ? 'open' : '', 'ax-' + color]"
  *ngIf="responsive && !small"
  (click)="stopPropagationWhenResponsive($event)"
>
  <div class="internal-padding" [ngStyle]="{display: open ? 'block' : 'none'}">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>
<div class="label-hint">
  <ng-content select=".label-hint"></ng-content>
</div>
