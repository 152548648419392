import {Component, HostBinding} from '@angular/core';

import {AbstractCustomPropertyView} from '../abstract-custom-property-view';

@Component({
  selector: 'ax-custom-value-view',
  template: `{{ property.value || ('property.value.empty' | translate) }}`,
})
export class CustomValueViewComponent extends AbstractCustomPropertyView {
  @HostBinding('class.c-card__property') class = true;
}
