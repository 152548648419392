<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [name]="customIcon ? customIcon : 'card-text'"
></ax-icon>
<div>
  <mat-form-field
    class="c-input"
    [ngClass]="{readonly: readonly}"
    appearance="outline"
    [hideRequiredMarker]="!required"
    style="width: 100%"
  >
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="dropdownControl" (openedChange)="openedChange($event)">
      <mat-option
        *ngIf="(valueLabels$ | async)?.length || 0 > 6"
        (keydown)="$event.stopPropagation()"
      >
        <ngx-mat-select-search
          [formControl]="formFilterControl"
          [placeholderLabel]="'label.search' | translate"
          [noEntriesFoundLabel]="'search.results.absence' | translate"
        >
          <ax-icon class="icon" name="close" ngxMatSelectSearchClear></ax-icon>
        </ngx-mat-select-search>
      </mat-option>
      <cdk-virtual-scroll-viewport
        #viewport
        [itemSize]="itemSize"
        [appendOnly]="true"
        [style.height.px]="viewportHeight$ | async"
      >
        <mat-option
          *cdkVirtualFor="let result of valueLabelsFiltered$ | async"
          [value]="result.value"
          (keydown)="$event.stopPropagation()"
        >
          <div class="option-wrapper">
            <span>{{ result.label }}</span>
          </div>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-select>

    <button
      axButton
      icon-button
      type="button"
      *ngIf="form?.enabled && hasValue && !readonly"
      matSuffix
      (click)="clearDropdownValue()"
    >
      <ax-icon class="icon" name="close"></ax-icon>
    </button>

    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
    <mat-error>
      <ax-property-error *ngIf="form.touched" [form]="form" [label]="label"></ax-property-error>
    </mat-error>
  </mat-form-field>
</div>
