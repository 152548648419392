import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {HelperPipesModule} from '@aztrix/helpers';

import {ConfirmExpireModalComponent} from './confirm-expire-modal.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [ConfirmExpireModalComponent],
  imports: [CommonModule, ButtonModule, TranslatePipe],
  exports: [ConfirmExpireModalComponent],
})
export class ConfirmExpireModalModule {}
