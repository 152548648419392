import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ProposalVerificationFormModule} from '@aztrix/subscribe/proposal-verification-form';

import {ProposalSubscribeVerificationFormComponent} from './proposal-subscribe-verification-form.component';

@NgModule({
  declarations: [ProposalSubscribeVerificationFormComponent],
  imports: [CommonModule, ProposalVerificationFormModule],
  exports: [ProposalSubscribeVerificationFormComponent],
})
export class ProposalSubscribeVerificationFormModule {}
