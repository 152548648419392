<mat-form-field
  class="c-input input"
  appearance="outline"
  [ngClass]="{readonly: readonly}"
  floatLabel="always"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <div class="input-field">
    @if (form) {
      <input matInput [type]="'text'" [formControl]="form" [readonly]="readonly" />
    }
    <span *ngIf="postfix" class="custom-prefix">
      {{ postfix }}
    </span>
  </div>
  <mat-error *ngFor="let error of errors | keyvalue">
    <ax-icon name="alert-circle"></ax-icon>
    <span>{{
      errorTranslationKey(error.key)
        | translate
          : (error.key === 'required'
              ? {propertyType: ('label.domain.url' | translate)}
              : error.value)
    }}</span>
  </mat-error>
  <mat-hint align="end">
    <a target="_blank" [href]="howToLink">{{ 'page.domain.hint' | translate }}</a>
  </mat-hint>
</mat-form-field>
