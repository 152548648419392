import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'ax-value-view-label',
  template: `<span class="label" *ngIf="label">{{ label }} </span> <ng-content></ng-content>`,
  styleUrls: ['value-view-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueViewLabelComponent {
  @HostBinding('class.has-label') @Input() label: string;
}
