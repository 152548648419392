import {Component, Input} from '@angular/core';

@Component({
  selector: 'ax-subscribe-failed-snackbar',
  templateUrl: './subscribe-failed-snackbar.component.html',
  styleUrls: ['./subscribe-failed-snackbar.component.scss'],
})
export class SubscribeFailedSnackbarComponent {
  @Input() errorMessage: string;
}
