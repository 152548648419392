import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {HelperPipesModule} from '@aztrix/helpers';

import {SubscribeFailedSnackbarComponent} from './subscribe-failed-snackbar.component';

@NgModule({
  declarations: [SubscribeFailedSnackbarComponent],
  imports: [CommonModule, SnackbarModule, HelperPipesModule],
  exports: [SubscribeFailedSnackbarComponent],
})
export class SubscribeFailedSnackbarModule {}
