import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {PaymentIntentRepresentation, ProductsService} from '@aztrix/sdk';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';
import {
  BehaviorSubject,
  distinctUntilChanged,
  first,
  ReplaySubject,
  shareReplay,
  Subscription,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'ax-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss'],
})
export class ConfirmPaymentComponent implements OnChanges, OnDestroy {
  @Input() productId: string;
  @Input() ownerId: string;
  @Output() done = new EventEmitter<void>();

  @MediaQuery('(min-width: 37.5rem)') @HostBinding('class.medium') medium = false;

  paymentBusy$ = new BehaviorSubject(false);

  stripeInvoice$ = new ReplaySubject(1);

  productId$ = new ReplaySubject<string>(1);
  paymentIntent$ = new ReplaySubject<PaymentIntentRepresentation>(1);

  product$ = this.productId$.pipe(
    distinctUntilChanged(),
    switchMap((productId) => this._productService.getProductById(this.ownerId, productId)),
    shareReplay(1)
  );

  private _subscriptions = new Subscription();

  constructor(
    private _productService: ProductsService,
    resize: ObserveResizeService,
    elementRef: ElementRef,
    changeDetector: ChangeDetectorRef
  ) {
    resize.register(this, elementRef, changeDetector);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productId && this.productId) {
      this.productId$.next(this.productId);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  setPaymentIntent() {
    this._productService
      .getPaymentIntentForProduct(this.ownerId, this.productId)
      .pipe(first())
      .subscribe((paymentIntent) => {
        this.paymentIntent$.next(paymentIntent);
      });
  }
}
