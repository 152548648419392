import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {agreementDocumentByProposalDocumentId} from '@aztrix/helpers';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  DocumentRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {ControlsOf, FormArray, FormGroup} from '@ngneat/reactive-forms';
import {ReplaySubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'ax-proposal-documents',
  templateUrl: './proposal-documents.component.html',
  styleUrls: ['./proposal-documents.component.scss', '../section.scss'],
})
export class ProposalDocumentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() proposal?: ProposalRepresentation;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Input() agreementDocuments: AgreementDocumentRepresentation[];
  @Input() intermediateAgreementDataCode: string;
  @Input() demo = false;
  @Input()
  @HostBinding('class.empty')
  empty = true;

  form$ = new ReplaySubject<UntypedFormGroup>(1);

  documentControls$ = this.form$.pipe(
    map((form) => {
      if (!form || !form.get('documents')) {
        return [];
      }
      return (
        (<FormArray<DocumentRepresentation>>form.get('documents')).controls.sort((c1, c2) => {
          return (c1.value.orderIndex || 0) < (c2.value.orderIndex || 0) ? -1 : 1;
        }) || []
      );
    })
  );

  subscription = new Subscription();

  ngOnInit() {
    this.subscription = this.documentControls$
      .pipe(map((documentControls) => documentControls.length <= 0))
      .subscribe((empty) => {
        this.empty = empty;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.form && this.form) || (changes.agreementDocuments && this.agreementDocuments)) {
      this.form$.next(this.form);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  agreementDocumentById(documentControl: FormGroup<ControlsOf<DocumentRepresentation>>) {
    const id = documentControl.get('id')?.value;
    return agreementDocumentByProposalDocumentId(id, this.agreementDocuments || []);
  }
}
