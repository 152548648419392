import {NgTemplateOutlet} from '@angular/common';
import {Component, Input, TemplateRef} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {OverlayService} from '@aztrix/components/overlay';
import {TranslatePipe} from '@aztrix/translate';

@Component({
  templateUrl: './setting-button-modal.component.html',
  styleUrls: ['setting-button-modal.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, ButtonModule, TranslatePipe],
})
export class SettingButtonModalComponent {
  @Input() content: TemplateRef<unknown>;

  constructor(private _overlay: OverlayService) {}

  close() {
    this._overlay.closeModal();
  }
}
