<ng-template #secretValue>
  <div class="hidden" *ngIf="secret" (click)="secret = !secret">
    <span class="text">{{ fakeValue }}</span>
    <span class="show">{{ 'label.show' | translate | lowercase }}</span>
  </div>
</ng-template>

<div class="input-wrapper" *ngIf="!code">
  <div class="input-content">
    <textarea
      class="input"
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      minRows="1"
      maxRows="5"
      disabled
      [hidden]="secret"
      [value]="secret ? '' : value"
    ></textarea>
  </div>

  <ng-container *ngTemplateOutlet="secretValue"></ng-container>
  <ax-copy-button class="suffix" [value]="value"></ax-copy-button>
</div>

<div class="code" *ngIf="code">
  <code *ngIf="!secret">
    <pre>{{ value }}</pre>
  </code>
  <ng-container *ngTemplateOutlet="secretValue"></ng-container>
  <ax-copy-button [value]="value"></ax-copy-button>
</div>
