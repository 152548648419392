import {Component, HostBinding, Input} from '@angular/core';

import {AbstractCustomPropertyView} from '../abstract-custom-property-view';

@Component({
  selector: 'ax-custom-labels-view',
  template: `<div class="c-card__property" *ngFor="let label of labels">
      {{ label }}
    </div>
    <div class="c-card__property" *ngIf="!labels.length">
      {{ 'property.value.empty' | translate }}
    </div>`,
  styleUrls: ['custom-labels-view.component.scss'],
})
export class CustomLabelsViewComponent extends AbstractCustomPropertyView {
  @HostBinding('class.in-list-view')
  @Input()
  override isInListView = false;

  get labels() {
    const values = this.property?.value?.split(',');
    if (!values?.length) {
      return [];
    }

    const labels = [];
    for (const value of values) {
      const valueLabel = this.valueLabels.find((vl) => vl.value === value);
      if (valueLabel) {
        labels.push(valueLabel.label);
      } else {
        labels.push(value);
      }
    }

    return labels;
  }
}
