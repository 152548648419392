import {Directive, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  DocumentRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';

@Directive()
export abstract class AbstractProposalDocument {
  @Input() form: FormControl;
  @Input() document: DocumentRepresentation;
  @Input() agreementDocument?: AgreementDocumentRepresentation;
  @Input() proposal?: ProposalRepresentation;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Input() demo = false;
  @Input() documentType: 'PROPOSAL' | 'AGREEMENT' | 'SIGNING-ORDER' = 'AGREEMENT';
}
