import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Params} from '@angular/router';
import {itemCanBeDisplayed, itemIsLegalRelated, sortItems} from '@aztrix/helpers';
import {DownloadVcardService} from '@aztrix/helpers';
import {
  AlistItemRepresentation,
  AlistItemWithItemsRepresentation,
  AlistItemWithStringRepresentation,
  AlistLanguageRepresentation,
  AlistRepresentation,
} from '@aztrix/sdk';

@Component({
  selector: 'lt-item-items-view',
  templateUrl: './item-items-view.component.html',
  styleUrls: ['./item-items-view.component.scss', '../item-view.scss'],
})
export class ItemItemsViewComponent implements OnChanges {
  @Input() alist: AlistRepresentation;
  @Input() alistLanguage?: AlistLanguageRepresentation;
  @Input() item?: AlistItemRepresentation;
  @Input() params: Params | undefined | null; // unused dummy to avoid issues in AbstractItemView
  @HostBinding('class.properties') onlyProperties = false;

  ItemFormat = AlistItemWithStringRepresentation.FormatEnum;
  ItemType = AlistItemWithItemsRepresentation.TypeEnum;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupedItems: any[] = [];

  constructor(private _downloadVcard: DownloadVcardService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item || changes.alistLanguage) {
      this.groupedItems = [];

      let allItems: AlistItemRepresentation[] = [];
      if (this.item) {
        allItems = <AlistItemRepresentation[]>this.item.data;
      } else if (this.alistLanguage?.items) {
        allItems = this.alistLanguage.items;
      }

      let items: AlistItemRepresentation[] = [];
      for (const item of sortItems(allItems).filter(
        (item) => itemCanBeDisplayed(item) && item.visible && !itemIsLegalRelated(item)
      )) {
        if (items.find((i) => i.format === item.format) || !items.length) {
          items.push(item);
        } else {
          this.groupedItems.push(items);
          items = [];
          items.push(item);
        }
      }
      this.groupedItems.push(items);
    }
  }

  downloadItemAsVcf() {
    if (this.item) {
      this._downloadVcard.downloadItemAsVcf(this.alist.name || '', this.item);
    }
  }
}
