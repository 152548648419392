import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@aztrix/components/button';
import {ConfirmPaymentModule} from '@aztrix/components/confirm-payment';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {PropertyViewModule} from '@aztrix/components/property-view';
import {ValueViewModule} from '@aztrix/components/value-view';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {ProposalSubscribeModalModule} from '@aztrix/subscribe/proposal-subscribe-modal';

import {AlistPipesModule} from '../pipes/alist-pipes.module';
import {ItemButtonViewComponent} from './item-button-view/item-button-view.component';
import {ItemCollapseViewComponent} from './item-collapse-view/item-collapse-view.component';
import {ItemIconViewComponent} from './item-icon-view/item-icon-view.component';
import {ItemItemsViewComponent} from './item-items-view/item-items-view.component';
import {ItemLinkViewComponent} from './item-link-view/item-link-view.component';
import {ItemPageViewComponent} from './item-page-view/item-page-view.component';
import {ItemPropertyViewComponent} from './item-property-view/item-property-view.component';
import {ItemTextViewComponent} from './item-text-view/item-text-view.component';
import {ItemUnavailableViewComponent} from './item-unavailable-view/item-unavailable-view.component';
import {ItemViewComponent} from './item-view.component';
import {ItemViewLinkDirective} from './item-view-link.directive';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    ItemViewComponent,
    ItemPropertyViewComponent,
    ItemButtonViewComponent,
    ItemIconViewComponent,
    ItemLinkViewComponent,
    ItemUnavailableViewComponent,
    ItemTextViewComponent,
    ItemItemsViewComponent,
    ItemPageViewComponent,
    ItemCollapseViewComponent,
    ItemViewLinkDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PropertyViewModule,
    ButtonModule,
    IconsModule,
    HelperPipesModule,
    TranslatePipe,
    ProposalSubscribeModalModule,
    ConfirmPaymentModule,
    ImageSourceModule,
    AlistPipesModule,
    ValueViewModule,
    MatSidenavModule,
  ],
  exports: [ItemViewComponent, ItemItemsViewComponent],
})
export class ItemViewModule {}
