import {Component, HostBinding} from '@angular/core';

import {AbstractCustomPropertyView} from '../abstract-custom-property-view';

@Component({
  selector: 'ax-custom-date-value-view',
  template: `{{ (property.value | formatDate) || ('property.value.empty' | translate) }}`,
})
export class CustomVDateValueViewComponent extends AbstractCustomPropertyView {
  @HostBinding('class.c-card__property') class = true;
}
