import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {EnvironmentService} from '@aztrix/environment';
import {filter, first} from 'rxjs';

@Component({
  selector: 'ax-passbase-button',
  templateUrl: './passbase-button.component.html',
  styleUrls: ['./passbase-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassbaseButtonComponent implements AfterViewInit {
  @ViewChild('passbaseButton') passbaseButton: ElementRef;
  @Output() verificationFinished = new EventEmitter<string>();

  constructor(private _environment: EnvironmentService) {}

  ngAfterViewInit() {
    this._environment
      .string$('PASSBASE_API_KEY')
      .pipe(
        filter((value) => !!value),
        first()
      )
      .subscribe((passbaseApiKey) => {
        // Passbase.default.renderButton(this.passbaseButton.nativeElement, passbaseApiKey, {
        //   onFinish: (identityAccessKey: string) => {
        //     this.verificationFinished.emit(identityAccessKey);
        //   },
        // });
      });
  }
}
