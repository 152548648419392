<button
  [ngClass]="{menu: color !== 'primary'}"
  class="button"
  axButton
  [color]="color"
  [filled]="color === 'primary'"
  (click)="openSendToEmailModal()"
  fullWidth
>
  <ax-icon class="icon" name="email"></ax-icon>
  {{ 'label.sendByEmail' | translate }}
</button>
