import {ChangeDetectionStrategy, Component} from '@angular/core';

import {ValueView} from '../../value-view';

@Component({
  selector: 'ax-image-value-view',
  templateUrl: './image-value-view.component.html',
  styleUrls: ['./image-value-view.component.scss', '../../value-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageValueViewComponent extends ValueView {
  get id() {
    try {
      return JSON.parse(this.value).id;
    } catch {
      return '';
    }
  }
}
