import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {PropertyType} from '@aztrix/models';

@Component({
  selector: 'ax-login-value-form-error',
  templateUrl: './login-value-form-error.component.html',
  styleUrls: ['./login-value-form-error.component.scss'],
})
export class LoginValueFormErrorComponent {
  @Input() form: AbstractControl;
  @Input() propertyType: string | PropertyType;

  get errorTypes() {
    return Object.keys(this.form?.errors || {});
  }
}
