import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LinkModule} from '@aztrix/components/link';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';

import {AgreementHistoryActionComponent} from './agreement-history-action.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [AgreementHistoryActionComponent],
  imports: [CommonModule, LinkModule, HelperPipesModule, TranslatePipe, IconsModule],
  exports: [AgreementHistoryActionComponent],
})
export class AgreementHistoryActionModule {}
