import {CommonModule} from '@angular/common';
import {Component, Inject, Input, OnChanges, Optional, SimpleChanges} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {BACKEND_URL} from '@aztrix/environment';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {AlistRepresentation, AlistsService} from '@aztrix/sdk';
import {TranslatePipe} from '@aztrix/translate';
import {FormControl} from '@ngneat/reactive-forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  first,
  map,
  of,
  ReplaySubject,
  switchMap,
} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'lt-alist-name-edit',
  templateUrl: './alist-name-edit.component.html',
  styleUrls: ['./alist-name-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ValueEditModule,
    IconsModule,
    TranslatePipe,
  ],
})
export class AlistNameEditComponent implements OnChanges {
  @Input() label = 'label.name';
  @Input() prefix: string | undefined;
  @Input() postfix = '*';
  @Input() readonly = false;
  @Input() alist: AlistRepresentation | undefined;
  @Input() alistNameForm: FormControl<string | undefined>;

  private _value$ = new ReplaySubject<string>(1);

  value$ = this._value$.pipe(
    debounceTime(500),
    switchMap((value: string) => this._alistsService.getAlistFromName(value)),
    map((alist) =>
      alist.name && alist.name !== this.alist?.name ? {alistExists: alist.name} : null
    ),
    catchError(() => of(null))
  );

  asyncNameValidator: AsyncValidatorFn = (control: AbstractControl) => {
    this._value$.next(control.value);
    return this.value$.pipe(first());
  };

  constructor(
    @Optional() @Inject(BACKEND_URL) public backendUrl$: BehaviorSubject<string>,
    private _alistsService: AlistsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alistNameForm && this.alistNameForm) {
      this.alistNameForm.addAsyncValidators(this.asyncNameValidator);
      if (this.alistNameForm.value) {
        this.alistNameForm.markAsTouched();
      }
    }
  }
}
