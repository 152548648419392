<ax-icon
  class="icon"
  [name]="item.icon ? item.icon : property.type ? (property.type | propertyIcon) : 'error'"
></ax-icon>
<span class="title">{{ item.title || (property.type | propertyTypeLabel) }}</span>
<ax-property-value-view
  [property]="property"
  [clickable]="true"
  [params]="params"
></ax-property-value-view>
