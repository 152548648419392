import {Component, Input} from '@angular/core';
import {BehaviorSubject, interval} from 'rxjs';
import {first} from 'rxjs/operators';

@Component({
  selector: 'ax-copy-button',
  templateUrl: 'copy-button.component.html',
  styleUrls: ['copy-button.component.scss'],
})
export class CopyButtonComponent {
  @Input() value?: string;
  @Input() color: 'grey' | 'white' = 'grey';
  copied$ = new BehaviorSubject(false);

  didClickCopy() {
    this.copied$.next(true);
    interval(5000)
      .pipe(first())
      .subscribe(() => {
        this.copied$.next(false);
      });
  }
}
