import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';
import {ActionFinishedModule} from '@aztrix/components/action-finished';
import {ButtonModule} from '@aztrix/components/button';
import {ConfirmPaymentModule} from '@aztrix/components/confirm-payment';
import {SpinnerModule} from '@aztrix/components/spinner';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';
import {ProposalSubscribeOverviewModule} from '@aztrix/subscribe/proposal-subscribe-overview';
import {ProposalSubscribeSendToEmailModule} from '@aztrix/subscribe/proposal-subscribe-send-to-email';
import {SubscribeFailedSnackbarModule} from '@aztrix/subscribe/subscribe-failed-snackbar';

import {ProposalDocumentsModule} from '../proposal-documents/proposal-documents.module';
import {ProposalSubscribeErrorsModule} from '../proposal-subscribe-errors/proposal-subscribe-errors.module';
import {ProposalSubscribeItemsComponent} from '../proposal-subscribe-items/proposal-subscribe-items.component';
import {ProposalSubscribeTextTemplateComponent} from '../proposal-subscribe-text-template/proposal-subscribe-text-template.component';
import {ProposalSubscribeVerificationFormModule} from '../proposal-subscribe-verification-form/proposal-subscribe-verification-form.module';
import {ProposalDocumentsStepComponent} from './proposal-documents-step/proposal-documents-step.component';
import {ProposalSubscribeStepComponent} from './proposal-subscribe-step/proposal-subscribe-step.component';
import {ProposalSubscribeStepButtonComponent} from './proposal-subscribe-step-buttons/proposal-subscribe-step-button/proposal-subscribe-step-button.component';
import {ProposalSubscribeStepButtonsComponent} from './proposal-subscribe-step-buttons/proposal-subscribe-step-buttons.component';
import {ProposalSubscribeStepsComponent} from './proposal-subscribe-steps.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    ProposalSubscribeStepsComponent,
    ProposalSubscribeStepComponent,
    ProposalDocumentsStepComponent,
    ProposalSubscribeStepButtonsComponent,
    ProposalSubscribeStepButtonComponent,
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    HelperPipesModule,
    TranslatePipe,
    IconsModule,
    ButtonModule,
    ProposalSubscribeItemsComponent,
    ProposalSubscribeTextTemplateComponent,
    ProposalDocumentsModule,
    ProposalSubscribeVerificationFormModule,
    ProposalSubscribeErrorsModule,
    ProposalSubscribeOverviewModule,
    ProposalSubscribeSendToEmailModule,
    SubscribeFailedSnackbarModule,
    SpinnerModule,
    ConfirmPaymentModule,
    ActionFinishedModule,
  ],
  exports: [
    ProposalSubscribeStepsComponent,
    ProposalSubscribeStepComponent,
    ProposalDocumentsStepComponent,
  ],
})
export class ProposalSubscribeStepsModule {}
