import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {AgreementRepresentation, AgreementsService} from '@aztrix/sdk';

@Component({
  selector: 'ax-confirm-expire-modal',
  templateUrl: './confirm-expire-modal.component.html',
  styleUrls: ['./confirm-expire-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmExpireModalComponent {
  @Input() agreement?: AgreementRepresentation;

  @Output() expired = new EventEmitter<AgreementRepresentation>();

  constructor(
    private _overlay: OverlayService,
    private _agreement: AgreementsService
  ) {}

  confirmExpiration(): void {
    if (this.agreement) {
      this._agreement
        .expire(this.agreement.id || '', this.agreement.agreementCode)
        .subscribe((agreement) => {
          this.expired.next(agreement);
          this._overlay.closeModal();
        });
    }
  }
}
