<ng-template #loading>
  <ax-spinner></ax-spinner>
</ng-template>

<ng-container *ngIf="proposal$ | async as proposal; else loading">
  <ax-subscribe-theme>
    <ax-subscribe
      [proposalId]="proposal.id || ''"
      [languageCode]="(languageCode$ | async) || undefined"
      (subscribed)="subscribedSuccess($event)"
    ></ax-subscribe>
  </ax-subscribe-theme>
</ng-container>
