import {Component} from '@angular/core';
import {iconNameForCustomPropertyType} from '@aztrix/helpers';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-default-edit',
  templateUrl: 'custom-default-edit.component.html',
  styleUrls: ['custom-default-edit.component.scss'],
})
export class CustomDefaultEditComponent extends AbstractProposalPropertyEdit {
  iconNameForCustomPropertyType = iconNameForCustomPropertyType;
}
