import {Component, Input} from '@angular/core';

@Component({
  selector: 'ax-success-icon',
  templateUrl: './success-icon.component.html',
  styleUrls: ['./success-icon.component.scss'],
})
export class SuccessIconComponent {
  @Input() name? = 'check';
  @Input() color: 'green' | 'red' | 'orange' = 'green';
}
