<ax-document
  [document]="document"
  [documentType]="'AGREEMENT'"
  [agreementDocument]="agreementDocument"
  [isAgreementDocument]="isInAgreement"
></ax-document>
<span *ngIf="showDocumentStatus"
  >&nbsp;({{ (document.signingRequired ? 'label.signed' : 'label.accepted') | translate }})</span
>
<p *ngIf="document?.description">{{ document.description }}</p>
