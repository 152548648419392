import {Component} from '@angular/core';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-url-edit',
  templateUrl: 'custom-url-edit.component.html',
  styleUrls: ['../show-icon.scss'],
})
export class CustomUrlEditComponent extends AbstractProposalPropertyEdit {}
