<input
  class="otp-input"
  size="6"
  maxLength="6"
  matInput
  [formControl]="form"
  (input)="_handleInput(form)"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
  (paste)="paste($event)"
  #input
/>
