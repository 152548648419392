<lt-alist-name-edit
  class="name-edit"
  [alistNameForm]="nameForm"
  [alist]="alist"
  [prefix]="''"
></lt-alist-name-edit>

<mat-slide-toggle
  class="toggle"
  labelPosition="before"
  [aria-label]="'page.available.toggle' | translate"
  [(ngModel)]="domainEnabled"
  ><span>{{ 'page.available.toggle' | translate }}</span></mat-slide-toggle
>

<lt-alist-domain-edit
  class="domain-edit"
  [form]="domainForm"
  [label]="'label.domain.url' | translate"
  [alist]="alist"
  [readonly]="!domainEnabled"
></lt-alist-domain-edit>

<p>{{ 'page.available.on' | translate }}</p>
<ul class="page-list">
  <li>
    <b>{{ (prefix$ | async) + '/' + name }}</b>
  </li>
  @if (domainEnabled && domain.length > 0) {
    <li>
      <b>{{ domain }}</b>
    </li>
  }
</ul>

<div class="button-wrapper">
  <lt-setting-button
    icon-button
    outline
    icon="check"
    [spacing]="SettingButtonSpacing.MEDIUM"
    [disabled]="domainEnabled ? domainForm.invalid || nameForm.invalid : nameForm.invalid"
    [ariaLabel]="'label.confirm' | translate"
    (click)="confirm()"
  ></lt-setting-button>
</div>
