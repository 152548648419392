import {Component, ContentChildren, Input, QueryList} from '@angular/core';
import {MatMenuItem} from '@angular/material/menu';

@Component({
  selector: 'ax-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {
  @Input() icon = 'dots-vertical';
  @Input() outline = false;
  @Input() filled = false;
  @Input() hasItems = false;
  @Input() iconButton = true;
  @Input() fullWidth = false;
  @Input() bigButton = false;
  @Input() color: 'primary' | null;

  _open = false;

  @ContentChildren(MatMenuItem) menuItems: QueryList<MatMenuItem>;

  get _hasItems() {
    return this.menuItems?.length || this.hasItems;
  }
}
