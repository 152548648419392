import {LocationStrategy} from '@angular/common';
import {Attribute, Directive, ElementRef, Inject, Input, Optional, Renderer2} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {BASE_URL} from '@aztrix/environment';
import {Capacitor} from '@capacitor/core';

@Directive({
  selector: 'a[axLink],area[axLink]',
})
export class LinkDirective extends RouterLink {
  @Input()
  set axLink(commands: any[] | string | null | undefined) {
    if (window.location.origin.includes(this._baseUrl) || Capacitor.isNativePlatform()) {
      this.routerLink = commands;
    } else if (commands) {
      if (typeof commands === 'string') {
        this.href = this._baseUrl + commands;
      } else {
        this.href = this._baseUrl + commands.join('/');
      }
      super.ngOnChanges({});
    }
  }

  constructor(
    @Optional() @Inject(BASE_URL) private _baseUrl: string,
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined,
    renderer: Renderer2,
    el: ElementRef,
    locationStrategy?: LocationStrategy
  ) {
    super(router, route, tabIndexAttribute, renderer, el, locationStrategy);
  }
}
