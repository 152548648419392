import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ax-proposal-subscribe-step-buttons',
  templateUrl: './proposal-subscribe-step-buttons.component.html',
  styleUrls: ['./proposal-subscribe-step-buttons.component.scss'],
})
export class ProposalSubscribeStepButtonsComponent {
  @Input() loading = false;
  @Input() subscribeLabel?: string;
  @Input() noSubscribeButton = false;
  @Output() nextClicked = new EventEmitter<void>();
  @Output() subscribeClicked = new EventEmitter<void>();
}
