<div class="property">
  <ax-icon
    *ngIf="showIcon"
    class="icon type-icon"
    [name]="customIcon ? customIcon : 'message-star'"
  ></ax-icon>
  <ax-rating-edit
    [form]="form"
    [label]="label"
    [required]="required"
    [readonly]="readonly"
  ></ax-rating-edit>
  <div class="hint-wrapper">
    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      *ngIf="form.valid || form.untouched"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
    <ax-property-error *ngIf="form.touched" [form]="form" [label]="label"></ax-property-error>
  </div>
</div>
