import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {startWith} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ax-domain-edit',
  templateUrl: './domain-edit.component.html',
  styleUrls: ['./domain-edit.component.scss'],
})
export class DomainEditComponent implements OnChanges {
  @Input() form: FormControl | undefined;
  @Input() label: string;
  @Input() postfix: string;
  @Input() readonly = false;
  @Input() howToLink: string;

  private _regexInvalidChars = /[^a-zA-Z0-9.-]/g;
  private _regexHttps = /^https:\/\//;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.form.valueChanges
        .pipe(untilDestroyed(this), startWith(this.form.value))
        .subscribe((value) => {
          if (value === '') {
            this.form?.setValue(undefined);
          } else if (this._regexHttps.test(value)) {
            this.form?.setValue(value.replace(this._regexHttps, ''));
          } else if (this._regexInvalidChars.test(value)) {
            this.form?.setValue(value.replace(this._regexInvalidChars, ''));
          }
        });
    }
  }

  get errors() {
    return this.form?.errors || {};
  }

  errorTranslationKey(errorType: string): string | undefined {
    switch (errorType) {
      case 'domain-in-use':
        return 'page.domain.validation.in-use';
      case 'required':
        return 'property.validation.required';
      default:
        return undefined;
    }
  }
}
