<a
  class="button"
  axButton
  filled
  fullWidth
  bigButton
  lowercase
  color="primary"
  [attr.aria-label]="item | itemLabel"
  [ltItemViewLink]="item"
  [ltItemViewLinkAlist]="alist"
  [ltItemViewLinkAlistLanguage]="alistLanguage"
>
  <ax-icon class="icon" [name]="item.icon ? item.icon : (item | iconForItemType)"></ax-icon>
  {{ item | itemLabel }}
  <ax-icon class="icon" name="arrow-right"></ax-icon>
</a>
