import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[axDisableSpecialFileCharacters]',
})
export class DisableSpecialFileCharactersDirective {
  regex = /[\\/:*?"<>|]/g;
  _disableSpecialFileCharacters = true;

  @Input()
  set axDisableSpecialFileCharacters(disableSpecialFileCharacters: boolean) {
    this._disableSpecialFileCharacters = disableSpecialFileCharacters;
  }

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    this.validateFields(event);

    return this._disableSpecialFileCharacters ? !this.regex.test(event.key) : true;
  }

  @HostListener('paste', ['$event']) onPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event: Event) {
    if (this._disableSpecialFileCharacters) {
      setTimeout(() => {
        this.el.nativeElement.value = this.el.nativeElement.value.replace(this.regex, '');
        event.preventDefault();
      }, 100);
    }
  }
}
