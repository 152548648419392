<ax-proposal-subscribe-property
  class="property"
  [form]="form"
  [proposalId]="proposalId"
  [proposalProperty]="property"
  [autocompleteProperties]="autocompleteProperties"
  [language]="language"
  [myProfile]="myProfile"
  [required]="required"
  [confirmed]="confirmed"
  [isOwner]="isOwner"
  [demo]="demo"
  [autofocus]="true"
></ax-proposal-subscribe-property>

<div class="h-button-wrapper">
  <button axButton (click)="cancel.next()">
    {{ 'label.close' | translate }}
  </button>
</div>
