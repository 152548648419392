<ng-template #propertyNotSocial>
  <a
    *ngIf="property | propertyIsCommunicationRelated"
    [attr.href]="property | propertyCommunicationHref: undefined : params || undefined"
    [attr.aria-label]="property | propertyDisplayValue: 'property.value.empty'"
    [target]="property | propertyCommunicationTarget"
    (click)="trackClick()"
  >
    <ax-communication-value-view
      [property]="property"
      [clickable]="false"
    ></ax-communication-value-view>
  </a>
</ng-template>

<ng-container [ngSwitch]="item.type">
  <a class="form" *ngSwitchCase="ItemType.PROPOSAL" (click)="openSubscribe()">{{
    item | itemLabel
  }}</a>
  <ng-container *ngSwitchCase="ItemType.PROPERTY">
    <a
      *ngIf="property | propertyIsSocialRelated; else propertyNotSocial"
      class="social"
      [ngClass]="property.type | propertyIcon"
      [href]="property | propertyCommunicationHref: CommunicationType.URL : params || undefined"
      [attr.aria-label]="item | itemLabel"
      target="_blank"
      (click)="trackClick()"
    >
      <span class="social__name">{{ item | itemLabel }}</span>
    </a>
  </ng-container>
  <a
    *ngSwitchCase="ItemType.PAYMENT"
    class="link"
    color="primary"
    href=""
    [attr.aria-label]="item | itemLabel"
    (click)="openPayModal($event)"
    >{{ item | itemLabel }}</a
  >
  <a
    *ngSwitchDefault
    class="link"
    color="primary"
    [attr.href]="data | urlParams: params"
    [attr.aria-label]="item | itemLabel"
    (click)="trackClick()"
    >{{ item | itemLabel }}</a
  >
</ng-container>
