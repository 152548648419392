import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ChipModule} from '@aztrix/components/chip';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ProposalHeaderComponent} from './proposal-header.component';

@NgModule({
  declarations: [ProposalHeaderComponent],
  imports: [CommonModule, IconsModule, RouterModule, TranslatePipe, ImageSourceModule, ChipModule],
  exports: [ProposalHeaderComponent],
})
export class ProposalHeaderModule {}
