<div
  class="property"
  [ngClass]="{
    'out-of-sync':
      agreementConfirmed && showOutOfSync && isOutOfSyncProperty(agreementProperty?.property)
  }"
>
  @if (agreementProperty?.property && agreementProperty?.property | isOfType: PropertyType.CUSTOM) {
    @if (language) {
      <span>
        {{
          (
            language
            | proposalPropertyDescription
              : agreementProperty?.requestedPropertyId
              : agreementProperty?.property?.type
          )?.customFieldDescription?.label | uppercase
        }}
      </span>
    }

    @if (agreementProperty && agreementProperty.property) {
      <ax-custom-property-view
        [property]="agreementProperty.property"
        [requestedPropertyType]="
          proposal
            ? (proposal
              | proposalRequestedPropertyType
                : agreementProperty.requestedPropertyId
                : agreementProperty.property.type)
            : undefined
        "
        [requestedPropertyTypeDescription]="
          language
            | proposalPropertyDescription
              : agreementProperty.requestedPropertyId
              : agreementProperty.property.type
        "
      ></ax-custom-property-view>
    }
  } @else {
    <span>{{
      ((agreementProperty?.property | propertyTypeLabel)
        ? (agreementProperty?.property | propertyTypeLabel)
        : (property | proposalPropertyTitle: language)
      ) | uppercase
    }}</span>
    <div class="property-view-wrapper">
      <ax-property-value-view [property]="agreementProperty?.property"></ax-property-value-view>
      @if (isOutOfSyncProperty(agreementProperty?.property)) {
        <ax-success-icon class="icon out-of-sync-icon" name="sync-alert"></ax-success-icon>
        <div class="button-wrapper">
          @if (canAddProperty(agreementProperty?.property)) {
            <button
              axButton
              icon-button
              filled
              color="primary"
              (click)="addProperty.emit(agreementProperty?.property)"
            >
              <ax-icon class="icon" name="plus"></ax-icon>
            </button>
          }
          @if (!isAgreementEdit && !isExternalAgreementConfirm && !isOwnerCheck()) {
            <button
              axButton
              icon-button
              filled
              color="primary"
              (click)="editProperty.next(agreementProperty?.property)"
            >
              <ax-icon class="icon" name="pencil"></ax-icon>
            </button>
          }
        </div>
      }
    </div>
  }
</div>
