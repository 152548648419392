<ng-container *ngIf="document?.type === 'TEMPLATE' && demo; else normalLink">
  <a class="link" (click)="downloadPdf(); $event.preventDefault()">
    <span class="legal-text" [attr.title]="('label.download' | translate) + ' ' + document.name"
      >{{ document.name }}<ax-icon class="icon" name="open-in-new"></ax-icon
    ></span>
  </a>

  <ax-document-template-preview
    class="template-value-view"
    *ngIf="document?.type === 'TEMPLATE'"
    [document]="document"
    [proposal]="proposal"
    [proposalLanguage]="proposalLanguage"
    [agreementProperties]="agreementProperties"
    [(template)]="template"
  ></ax-document-template-preview>
</ng-container>

<ng-template #normalLink>
  <ng-container *ngIf="document">
    <a
      class="link"
      axContrast="primary"
      *ngIf="document?.type !== 'LINK'"
      [attr.title]="document.filename"
      [attr.href]="path$ | async"
      [attr.download]="document.filename"
      (click)="downloadPdf(); $event.preventDefault()"
      target="_blank"
    >
      <span class="legal-text">{{ document.name }}</span>
    </a>

    <a
      class="link"
      axContrast="primary"
      *ngIf="document?.type === 'LINK'"
      [attr.href]="document.value"
      target="_blank"
    >
      <span class="legal-text" [attr.title]="document.value"
        >{{ document.name }} <ax-icon class="icon" name="open-in-new"></ax-icon
      ></span>
    </a>
  </ng-container>
</ng-template>
