<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [name]="customIcon ? customIcon : 'custom:chip'"
></ax-icon>
<ax-chips-edit
  [form]="form"
  [label]="label"
  [autocompleteValues]="labels"
  [required]="required"
  [hint]="hint"
  [errorsTemplate]="errors"
  [readonly]="readonly"
></ax-chips-edit>

<ng-template #errors>
  <ax-property-error *ngIf="form.touched" [form]="form" [label]="label"></ax-property-error>
</ng-template>
