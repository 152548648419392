<ng-container *ngIf="!verified">
  <ng-container
    *ngIf="
      (error$ | async) === null ||
      (error$ | async) === 'INVALID_VERIFICATION_CODE' ||
      (error$ | async) === 'INVALID_TOKEN' ||
      (error$ | async) === true
    "
  >
    <mat-form-field class="otp-form-field c-input" appearance="outline">
      <mat-label>{{ 'label.enter.code' | translate }}</mat-label>
      <ax-otp-input matInput [formControl]="codeForm"></ax-otp-input>
    </mat-form-field>

    <ng-content></ng-content>

    <ax-button
      class="verify"
      filled
      color="accent"
      [loading]="loading$ | async"
      (click)="verifyOTP()"
    >
      {{ 'label.verify' | translate }}
    </ax-button>

    <div class="resend">
      <a axButton class="resend-link" (click)="retryOTP()">
        <span>{{ 'agreement.verification.code.resend' | translate }}</span>
      </a>
    </div>
  </ng-container>

  <div class="error-message" *ngIf="(error$ | async) !== null">
    <ng-container [ngSwitch]="error$ | async">
      <span class="error" *ngSwitchCase="'INVALID_VERIFICATION_CODE'">{{
        'agreement.verification.code.error.incorrect' | translate
      }}</span>
      <span class="error" *ngSwitchCase="'INVALID_TOKEN'">{{
        'agreement.verification.code.error.incorrect' | translate
      }}</span>
      <span class="error" *ngSwitchCase="'TOO_MANY_VERIFICATION_ATTEMPTS'">{{
        'agreement.verification.code.error.too-many-attempts' | translate
      }}</span>
      <span class="error" *ngSwitchCase="'VERIFICATION_CODE_EXPIRED'">{{
        'agreement.verification.code.error.expired' | translate
      }}</span>
      <span class="error" *ngSwitchCase="'LOGIN_NOT_REGISTERED'">{{
        'error.login.invalid.login' | translate
      }}</span>
      <span class="error" *ngSwitchDefault>{{
        'error.SOMETHING_WENT_WRONG.message' | translate
      }}</span>
    </ng-container>
    <button
      axButton
      filled
      class="resend-button"
      *ngIf="
        (error$ | async) === 'TOO_MANY_VERIFICATION_ATTEMPTS' ||
        (error$ | async) === 'VERIFICATION_CODE_EXPIRED'
      "
      (click)="retryOTP()"
    >
      {{ 'property.verification.modal.mobile.resend' | translate }}
    </button>
  </div>
</ng-container>
