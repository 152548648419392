<ng-container [ngSwitch]="item.type">
  <button
    class="button"
    *ngSwitchCase="ItemType.PROPOSAL"
    axButton
    filled
    fullWidth
    bigButton
    lowercase
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openSubscribe()"
  >
    <ng-container [ngTemplateOutlet]="prefixIcon"></ng-container>
    {{ item | itemLabel }}
  </button>
  <button
    class="button"
    *ngSwitchCase="ItemType.PAYMENT"
    axButton
    filled
    fullWidth
    bigButton
    lowercase
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openPayModal($event)"
  >
    <ng-container [ngTemplateOutlet]="prefixIcon"></ng-container>
    {{ item | itemLabel }}
  </button>
  <ng-container *ngSwitchCase="ItemType.PROPERTY">
    <a
      *ngIf="property | propertyIsCommunicationRelated"
      class="button ax-social"
      axButton
      filled
      fullWidth
      bigButton
      lowercase
      color="primary"
      [attr.href]="property | propertyCommunicationHref: undefined : params || undefined"
      [attr.aria-label]="item | itemLabel"
      [target]="property | propertyCommunicationTarget"
      (click)="trackClick()"
    >
      <ng-container [ngTemplateOutlet]="prefixIcon"></ng-container>
      <span>{{ item | itemLabel }}</span>
    </a>
  </ng-container>
  <button
    class="button"
    *ngSwitchCase="ItemType.PROPERTIES"
    axButton
    filled
    fullWidth
    bigButton
    lowercase
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openPropertiesModal($event)"
  >
    <ng-container [ngTemplateOutlet]="prefixIcon"></ng-container>
    {{ item | itemLabel }}
  </button>
  <button
    class="button"
    *ngSwitchCase="ItemType.PRODUCT_CARD"
    axButton
    filled
    fullWidth
    bigButton
    lowercase
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openItemsModal($event)"
  >
    <ng-container [ngTemplateOutlet]="prefixIcon"></ng-container>
    {{ item | itemLabel }}
  </button>
  <button
    class="button"
    *ngSwitchCase="ItemType.ITEMS"
    axButton
    filled
    fullWidth
    bigButton
    lowercase
    color="primary"
    [attr.aria-label]="item | itemLabel"
    (click)="openItemsModal($event)"
  >
    <ng-container [ngTemplateOutlet]="prefixIcon"></ng-container>
    {{ item | itemLabel }}
  </button>
  <a
    *ngSwitchDefault
    class="button"
    axButton
    filled
    fullWidth
    bigButton
    lowercase
    color="primary"
    [attr.href]="data | urlParams: params"
    [attr.aria-label]="item | itemLabel"
    (click)="trackClick()"
  >
    <ng-container [ngTemplateOutlet]="prefixIcon"></ng-container>
    {{ item | itemLabel }}
  </a>
</ng-container>

<ng-template #prefixIcon>
  <ax-icon class="icon" [name]="item.icon ? item.icon : (item | iconForItemType)"></ax-icon>
</ng-template>
