<div class="property">
  <div class="title">
    {{ 'label.verifiedDate' | translate }}
  </div>
  <div *ngIf="agreement.verified && agreement?.verificationTimestamp as verifiedDate">
    <span class="status">{{
      'agreement.verified.date'
        | translate
          : {
              date: verifiedDate | formatDateTime,
              propertyValue: verificationDisplayValue
            }
    }}</span>
    <ax-icon class="icon green-text" name="custom:verified"></ax-icon>
  </div>
  <div *ngIf="!agreement.verified" class="verify-wrapper">
    {{ 'agreement.unverified' | translate }}
    <ax-icon class="icon orange-text" name="custom:verified"></ax-icon>
  </div>
</div>
