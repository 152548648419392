import {HttpClient} from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BACKEND_URL, BASE_URL} from '@aztrix/environment';
import {displayName, hasVerification, iconNameForCommunicationType} from '@aztrix/helpers';
import {CommunicationType, Proposal} from '@aztrix/models';
import {
  AgreementDataRepresentation,
  AgreementRepresentation,
  ProfileRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'ax-agreement-extra-info',
  templateUrl: './agreement-extra-info.component.html',
  styleUrls: ['./agreement-extra-info.component.scss', '../property.scss'],
})
export class AgreementExtraInfoComponent implements OnChanges {
  @Input() myProfile?: ProfileRepresentation;
  @Input() proposal?: ProposalRepresentation | Proposal;
  @Input() language?: ProposalLanguageRepresentation;
  @Input() agreement?: AgreementRepresentation;
  @Input() agreementData?: AgreementDataRepresentation;

  @Input() historyData: AgreementDataRepresentation[];
  @Output() didClickSendEmail = new EventEmitter();

  communicationType = CommunicationType.MAIL;
  iconNameForCommunicationType = iconNameForCommunicationType;
  displayName = displayName;

  isConfirmed = false;
  isVerified = false;

  private _proposal$ = new ReplaySubject<ProposalRepresentation | Proposal | undefined>(undefined);
  private _agreement$ = new ReplaySubject<AgreementRepresentation | undefined>(undefined);
  private _agreementData$ = new ReplaySubject<AgreementDataRepresentation | undefined>(undefined);
  private _myProfile$ = new ReplaySubject<ProfileRepresentation | undefined>(undefined);

  owner$: Observable<ProfileRepresentation | undefined> = this._proposal$.pipe(
    mergeMap((proposal) => (proposal ? this._getProfile(proposal.ownerId) : of(undefined)))
  );

  agreementCreator$ = this._agreementData$.pipe(
    switchMap((agreementData) => this._getProfile(agreementData?.creatorId)),
    shareReplay(1)
  );

  isOwner$: Observable<boolean> = combineLatest([this._myProfile$, this.owner$]).pipe(
    map(([myProfile, owner]) => {
      if (myProfile?.id === owner?.id) {
        return true;
      } else {
        return false;
      }
    })
  );

  isHistoryAgreement$: Observable<boolean> = combineLatest([
    this._agreementData$.pipe(filter((d) => !!d)),
    this._agreement$,
  ]).pipe(
    map(
      ([agreementData, agreement]) =>
        agreementData?.startTimestamp !== agreement?.agreementData?.startTimestamp
    )
  );

  startTimestamp$ = this._agreementData$.pipe(
    filter((d) => !!d),
    map((d) => d?.startTimestamp)
  );
  languageCode$ = this._agreementData$.pipe(
    filter((d) => !!d),
    map((d) => d?.language)
  );

  canViewHistory$: Observable<boolean> = combineLatest([
    this._agreementData$,
    this._agreement$,
  ]).pipe(map(([agreementData, agreement]) => !!agreementData || !!agreement?.expirationTimestamp));

  isSubscriber$: Observable<boolean> = combineLatest([this._myProfile$, this._agreement$]).pipe(
    map(([myProfile, agreement]) => myProfile?.id === agreement?.subscriberId)
  );

  createdByIsMyProfile$ = combineLatest([this._myProfile$, this._agreementData$]).pipe(
    mergeMap(([myProfile, agreementData]) => {
      if (!myProfile) {
        return of(false);
      } else if (agreementData?.createdBy === 'OWNER') {
        return this._proposal$.pipe(map((proposal) => proposal?.ownerId === myProfile.id));
      } else if (agreementData?.createdBy === 'SUBSCRIBER') {
        return this._agreement$.pipe(map((agreement) => agreement?.subscriberId === myProfile.id));
      } else {
        return of(false);
      }
    })
  );

  createdBySubject$ = this._agreementData$.pipe(
    map((agreementData) => {
      if (agreementData?.createdBy === 'OWNER') {
        return 'agreement.owner';
      } else if (agreementData?.createdBy === 'SUBSCRIBER') {
        return 'agreement.subscriber';
      } else {
        return '';
      }
    })
  );

  constructor(
    public dialog: MatDialog,
    private _http: HttpClient,
    @Optional() @Inject(BASE_URL) public baseUrl: string,
    @Inject(BACKEND_URL) public backendUrl$: BehaviorSubject<string>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.agreement && changes.agreement) {
      this._agreement$.next(this.agreement);
    }

    if (this.proposal && changes.proposal) {
      this._proposal$.next(this.proposal);
    }

    if (this.agreementData && changes.agreementData) {
      this._agreementData$.next(this.agreementData);
    }

    if (this.myProfile && changes.myProfile) {
      this._myProfile$.next(this.myProfile);
    }
  }

  get proposalHasVerification(): boolean {
    return hasVerification(this.proposal);
  }

  private _getProfile(
    profileId: string | undefined
  ): Observable<ProfileRepresentation | undefined> {
    if (!profileId) {
      return of(undefined);
    }
    return this.backendUrl$.pipe(
      switchMap((backendUrl) =>
        this._http.get<ProfileRepresentation>(`${backendUrl || ''}/r/profiles/${profileId}`)
      )
    );
  }
}
