<p>{{ message | translate }}</p>
<p>{{ 'warning.language.change.pending.message.lost' | translate }}</p>
<div class="button-wrapper">
  <button axButton filled (click)="discard.emit()">
    <ax-icon class="icon" name="arrow-u-left-top"></ax-icon>{{ 'label.discard' | translate }}
  </button>
  <button *ngIf="showCancel" axButton outline (click)="cancel.emit()">
    <ax-icon class="icon" name="close"></ax-icon>{{ 'label.cancel' | translate }}
  </button>
  <button axButton filled color="primary" (click)="save.emit()">
    <ax-icon class="icon" name="content-save-outline"></ax-icon>{{ 'label.save' | translate }}
  </button>
</div>
