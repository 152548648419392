<ng-container *ngIf="!isSignableDocument">
  {{ document.checkboxLabel }}
</ng-container>
<ax-document-link
  [document]="document"
  [documentType]="documentType"
  [agreementDocument]="agreementDocument"
  [isAgreementDocument]="isAgreementDocument"
  [document]="document"
  [proposal]="proposal"
  [proposalLanguage]="proposalLanguage"
  [agreementProperties]="agreementProperties"
  [isProposalDocument]="!!proposal && !!proposalLanguage"
  [demo]="demo"
></ax-document-link>
