import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {PLATFORM_URL} from '@aztrix/environment';
import {BehaviorSubject, first} from 'rxjs';

@Component({
  selector: 'ax-subscribe-success',
  templateUrl: 'subscribe-success.component.html',
  styleUrls: ['subscribe-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeSuccessComponent implements OnChanges {
  @Input() agreementCode?: string;

  agreementLink$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private _overlay: OverlayService,
    @Optional() @Inject(PLATFORM_URL) private _platformUrl$: BehaviorSubject<string>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.agreementCode && this.agreementCode) {
      this._platformUrl$
        .pipe(first())
        .subscribe((url) =>
          this.agreementLink$.next(`${url}/proposal/agreement/external/${this.agreementCode}`)
        );
    }
  }

  closeModal(): void {
    this._overlay.closeModal();
  }
}
