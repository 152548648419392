<div class="text">
  <div class="legal legal-text" *ngIf="legalText">
    {{ 'legal.text' | translate }}
  </div>
  <div class="legal">
    <a
      [ngClass]="{'ax-base-color': color === 'primary'}"
      [href]="termsUrl$ | async"
      target="_blank"
      >{{ 'label.terms' | translate }}</a
    >
    -
    <a
      [ngClass]="{'ax-base-color': color === 'primary'}"
      [href]="privacyUrl$ | async"
      target="_blank"
      >{{ 'label.privacy.policy' | translate }}</a
    >
    -
    <a [ngClass]="{'ax-base-color': color === 'primary'}" [href]="bugMail">{{
      'label.report.abuse' | translate
    }}</a>
  </div>
</div>
