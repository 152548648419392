<div class="ax-modal-body-content">
  <div class="c-modal__section">
    <div class="agreement-wrapper">
      <p>{{ 'agreement.modal.expire.explanation.1' | translate }}</p>
      <div class="h-button-wrapper">
        <button axButton (click)="confirmExpiration()" class="cy-expire" color="danger">
          {{ 'label.confirm' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
