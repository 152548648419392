<ax-button
  [ngClass]="{menu: color !== 'primary'}"
  class="button"
  [color]="color"
  [filled]="color === 'primary'"
  (click)="downloadPdf()"
  icon="custom:file-pdf"
  [loading]="loading$ | async"
  fullWidth
>
  <span>{{ 'agreement.download.pdf' | translate }}</span>
</ax-button>
