<div class="card success" *ngIf="success">
  <ax-icon class="icon" name="check"></ax-icon>
  <span>{{ 'agreement.verification.success.message' | translate }}</span>
</div>

<ng-container *ngIf="!_verified">
  <div class="message">
    <ax-icon class="icon snackbar-icon" name="alert"></ax-icon>

    <ng-container
      *ngIf="proposal?.verification === AgreementVerificationType.IDENTITY; else codeMessage"
    >
      <span *ngIf="verificationValue">{{
        'agreement.verification.snackbar.identity.message' | translate
      }}</span>
      <span *ngIf="!verificationValue">{{
        'agreement.verification.snackbar.identity.message.not.verified' | translate
      }}</span>
    </ng-container>

    <ng-template #codeMessage>
      <span
        *ngIf="!resendSuccess"
        [innerHTML]="
          'agreement.verification.snackbar.message'
            | translate
              : {
                  verificationType:
                    ('agreement.verification.type.' + proposal?.verification
                    | translate
                    | lowercase),
                  verificationValue: verificationValue
                }
        "
      ></span>
      <span
        *ngIf="resendSuccess"
        [innerHTML]="
          'agreement.verification.resend.success'
            | translate: {verificationValue: this.verificationValue}
        "
      ></span>
    </ng-template>
  </div>

  <div class="card">
    <ng-container *ngIf="proposal?.verification === AgreementVerificationType.IDENTITY; else code">
      <ax-passbase-button
        class="snackbar-button"
        *ngIf="!verificationValue && isExternal"
        (verificationFinished)="verifyIdentityOnAgreement($event)"
      ></ax-passbase-button>
    </ng-container>
    <ng-template #code>
      <ax-otp-verification
        [verificationValue]="verificationValue"
        [verificationType]="proposal?.verification === 'MOBILE' ? 'MOBILE' : 'EMAIL'"
        [verification]="verification"
        [retryVerification]="retryVerification"
        [canChangeValue]="true"
        [verificationCode]="(verificationCode$ | async) || undefined"
        (verificationValueChanges)="verificationValue = $event"
      ></ax-otp-verification>
    </ng-template>
  </div>
</ng-container>
