import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {BACKEND_URL} from '@aztrix/environment';
import {CustomValidators, HelperPipesModule} from '@aztrix/helpers';
import {AlistRepresentation} from '@aztrix/sdk';
import {FormControl} from '@ngneat/reactive-forms';
import {BehaviorSubject, map} from 'rxjs';

import {AlistDomainEditComponent} from '../alist-domain-edit/alist-domain-edit.component';
import {AlistNameEditComponent} from '../alist-name-edit/alist-name-edit.component';
import {
  SettingButtonComponent,
  SettingButtonSpacing,
} from '../setting-button/setting-button.component';
import {TranslatePipe} from '@aztrix/translate';

@Component({
  selector: 'lt-alist-realm-edit',
  templateUrl: './alist-realm-edit.component.html',
  styleUrls: ['./alist-realm-edit.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AlistNameEditComponent,
    ReactiveFormsModule,
    FormsModule,
    SettingButtonComponent,
    TranslatePipe,
    MatSlideToggleModule,
    AlistDomainEditComponent,
  ],
})
export class AlistRealmEditComponent implements OnInit {
  @Input() alist: AlistRepresentation;
  @Output() didConfirm = new EventEmitter<AlistRepresentation>();

  nameForm = new FormControl('', [Validators.required, CustomValidators.nameValidator()]);
  domainForm = new FormControl('', [Validators.required]);
  domainEnabled = false;

  valid$ = this.nameForm.statusChanges.pipe(map((status) => status === 'VALID'));
  loading$ = new BehaviorSubject(false);

  SettingButtonSpacing = SettingButtonSpacing;
  prefix$ = this._backendUrl$.pipe(map((url) => url.replace('https://', '')));

  constructor(@Optional() @Inject(BACKEND_URL) private _backendUrl$: BehaviorSubject<string>) {}

  ngOnInit() {
    if (this.alist) {
      this.domainEnabled = this.alist.domainEnabled || false;
      this.nameForm.setValue(this.alist.name || '');
      this.domainForm.setValue(this.alist.domain || '');
    }
  }

  confirm() {
    this.didConfirm.emit(<AlistRepresentation>{
      name: this.nameForm.value,
      domain: this.domainForm.value,
      domainEnabled: this.domainEnabled,
    });
  }

  get name() {
    return this.nameForm.value || '';
  }

  get domain() {
    return this.domainForm.value || '';
  }
}
