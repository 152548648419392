import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ValueViewModule} from '@aztrix/components/value-view';
import {HelperPipesModule} from '@aztrix/helpers';
import {IconsModule} from '@aztrix/icons';

import {CustomVDateValueViewComponent} from './custom-date-view/custom-date-view.component';
import {CustomLabelViewComponent} from './custom-label-view/custom-label-view.component';
import {CustomLabelsViewComponent} from './custom-labels-view/custom-labels-view.component';
import {CustomPropertyViewComponent} from './custom-property-view.component';
import {CustomRatingViewComponent} from './custom-rating-view/custom-rating-view.component';
import {CustomRichTextViewComponent} from './custom-rich-text-view/custom-rich-text-view.component';
import {CustomToggleViewComponent} from './custom-toggle-view/custom-toggle-view.component';
import {CustomValueViewComponent} from './custom-value-view/custom-value-view.component';
import {TranslatePipe} from '@aztrix/translate';

@NgModule({
  declarations: [
    CustomPropertyViewComponent,
    CustomLabelsViewComponent,
    CustomLabelViewComponent,
    CustomValueViewComponent,
    CustomVDateValueViewComponent,
    CustomRatingViewComponent,
    CustomRichTextViewComponent,
    CustomToggleViewComponent,
  ],
  imports: [
    CommonModule,
    HelperPipesModule,
    TranslatePipe,
    IconsModule,
    ValueViewModule,
    MatSlideToggleModule,
  ],
  exports: [CustomPropertyViewComponent],
})
export class CustomPropertyViewModule {}
