import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {ButtonModule} from '@aztrix/components/button';
import {IconsModule} from '@aztrix/icons';

import {MenuButtonComponent} from './menu-button.component';

@NgModule({
  imports: [CommonModule, MatMenuModule, ButtonModule, IconsModule],
  declarations: [MenuButtonComponent],
  exports: [MenuButtonComponent, MatMenuModule],
})
export class MenuButtonModule {}
