import {Component, HostBinding, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Property} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {skip, startWith, Subscription} from 'rxjs';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-toggle-edit',
  templateUrl: './custom-toggle-edit.component.html',
  styleUrls: ['./custom-toggle-edit.component.scss'],
})
export class CustomToggleEditComponent
  extends AbstractProposalPropertyEdit
  implements OnInit, OnChanges, OnDestroy
{
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }

  toggleControl = new UntypedFormControl();

  formSubscription = new Subscription();
  toggleControlSubscription = new Subscription();

  ngOnInit(): void {
    if (this.required) {
      this.toggleControl.setValidators(Validators.requiredTrue);
    }

    this.toggleControlSubscription = this.toggleControl.valueChanges
      .pipe(startWith(this.toggleControl.value || false), skip(1))
      .subscribe((value) => {
        this.valueControl?.setValue(value.toString());
        this.form.markAsTouched();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: Property | PropertyRepresentation) => {
          if (this.toggleControl.value?.toString() !== property?.value) {
            this.toggleControl.setValue(
              property?.value && property.value === 'true' ? true : false
            );
          }
        });

      this._disableChanges(this.form.disabled);
      this.valueControl?.registerOnDisabledChange((isDisabled) => this._disableChanges(isDisabled));
    }
  }

  ngOnDestroy() {
    this.toggleControlSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  private _disableChanges(isDisabled: boolean) {
    if (isDisabled) {
      this.toggleControl.disable({emitEvent: false});
    } else {
      this.toggleControl.enable({emitEvent: false});
    }
  }
}
