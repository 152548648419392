import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {EnvironmentService} from '@aztrix/environment';
import {localeToString} from '@aztrix/helpers';
import {Locale} from '@aztrix/models';
import {LOCALE} from '@aztrix/translate';
import {BehaviorSubject, first} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Stripe: any;

@Component({
  selector: 'ax-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: ['./stripe-checkout.component.scss'],
})
export class StripeCheckoutComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() clientSecret?: string;
  @Input() accountId?: string;
  @Output() cancel = new EventEmitter<void>();
  @Output() payed = new EventEmitter<void>();

  @ViewChild('checkoutElement') _checkoutElement: ElementRef;

  private _stripe: any;
  private _elements: any;
  private _stripeApiKey: any;
  private _afterViewInit = false;

  error: string | undefined;

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _environment: EnvironmentService,
    @Optional() @Inject(LOCALE) private _locale$: BehaviorSubject<Locale>
  ) {}

  ngOnInit(): void {
    this._environment
      .string$('STRIPE_API_KEY')
      .pipe(first((value) => !!value))
      .subscribe((stripeApiKey) => {
        this._stripeApiKey = stripeApiKey;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paymentIntent) {
      this._updateElements();
    }
  }

  private _updateElements() {
    if (!this._stripeApiKey || !this.clientSecret || !this._afterViewInit) {
      return;
    }

    this._stripe = Stripe(
      this._stripeApiKey,
      this.accountId
        ? {
            stripeAccount: this.accountId,
          }
        : undefined
    );

    this._elements = this._stripe.elements({
      clientSecret: this.clientSecret,
      locale: this._locale$?.value ? localeToString(this._locale$.value) : undefined,
    });
    const checkout = this._elements.create('payment', {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false,
      },
    });
    checkout.mount(this._checkoutElement.nativeElement);
  }

  ngAfterViewInit(): void {
    this._afterViewInit = true;
    this._updateElements();
  }

  async confirmPayment() {
    this.loading$.next(true);
    this.error = undefined;

    const {error} = await this._stripe.confirmPayment({
      elements: this._elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    this.loading$.next(false);

    if (error) {
      this.error = error.message;
    } else {
      this.payed.next();
    }
  }
}
