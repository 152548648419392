import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@aztrix/translate';
import {AlistItemRepresentation} from '@aztrix/sdk';
import {Observable, Subscription} from 'rxjs';
import {itemTypeLabel$} from '@aztrix/helpers';

@Pipe({name: 'itemTypeLabel', pure: false})
export class ItemTypeLabelPipe implements PipeTransform, OnDestroy {
  private _value?: AlistItemRepresentation;
  private _latestValue?: string;
  private _latestReturnedValue?: string;

  private _subscription = new Subscription();

  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  transform(value: AlistItemRepresentation): string | undefined {
    if (!this._value) {
      this._value = value;

      this._subscribe(itemTypeLabel$(this._translate, value));

      this._latestReturnedValue = this._latestValue;
      return this._latestValue;
    }

    if (value !== this._value) {
      this._dispose();
      return this.transform(value);
    }

    if (this._latestValue === this._latestReturnedValue) {
      return this._latestReturnedValue;
    }

    this._latestReturnedValue = this._latestValue;
    return this._latestValue;
  }

  ngOnDestroy() {
    this._dispose();
  }

  private _subscribe(obs: Observable<any>): void {
    this._subscription.add(
      obs.subscribe((value) => {
        this._latestValue = value;
        this._changeDetector.markForCheck();
      })
    );
  }

  private _dispose() {
    this._subscription.unsubscribe();
    this._value = undefined;
    this._latestValue = undefined;
    this._latestReturnedValue = undefined;
  }
}
