import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, Inject, Input, OnChanges, Optional, SimpleChanges} from '@angular/core';
import {BACKEND_URL} from '@aztrix/environment';
import {
  AgreementDocumentRepresentation,
  AgreementPropertyRepresentation,
  DocumentRepresentation,
  ProposalLanguageRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, first, map, ReplaySubject, switchMap} from 'rxjs';

@Component({
  selector: 'ax-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.scss'],
})
export class DocumentLinkComponent implements OnChanges {
  @Input() document: DocumentRepresentation;
  @Input() documentType: 'PROPOSAL' | 'AGREEMENT' | 'SIGNING-ORDER';
  @Input() agreementDocument?: AgreementDocumentRepresentation;
  @Input() isAgreementDocument: boolean;
  @Input() isProposalDocument = true;
  @Input() proposal?: ProposalRepresentation;
  @Input() proposalLanguage?: ProposalLanguageRepresentation;
  @Input() agreementProperties: AgreementPropertyRepresentation[];
  @Input() demo = false;
  template: string;

  documentType$ = new ReplaySubject<'PROPOSAL' | 'AGREEMENT' | 'SIGNING-ORDER'>(1);
  path$ = combineLatest([this._backendUrl$, this.documentType$]).pipe(
    map(([backendUrl, documentType]) => `${backendUrl}${this._getPath(documentType)}`)
  );

  constructor(
    private _http: HttpClient,
    @Optional() @Inject(BACKEND_URL) private _backendUrl$: BehaviorSubject<string>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentType && this.documentType) {
      this.documentType$.next(this.documentType);
    }
  }

  downloadPdf() {
    if (this.demo && this.document.type === DocumentRepresentation.TypeEnum.TEMPLATE) {
      this._http
        .post<string>(`r/utils/generatePdfFromHtml`, this.template, {
          params: {_noAuth: 'true'},
          headers: new HttpHeaders({'Content-Type': 'text/html'}),
          responseType: 'blob' as 'json',
        })
        .subscribe((pdf) => {
          const blob = new Blob([pdf], {type: 'application/pdf'});
          window.open(window.URL.createObjectURL(blob));
        });
    } else {
      this.documentType$
        .pipe(
          first(),
          switchMap((documentType) => {
            return this._http.get(`${this._getPath(documentType)}`, {
              responseType: 'blob' as 'json',
            });
          })
        )
        .subscribe((blob: any) => {
          const url = URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = this.document.filename || '';
          anchor.target = '_blank';
          anchor.style.display = 'none';
          document.body.appendChild(anchor);
          anchor.click();
          URL.revokeObjectURL(url);
        });
    }
  }

  private _getPath(documentType: 'PROPOSAL' | 'AGREEMENT' | 'SIGNING-ORDER') {
    switch (documentType) {
      case 'SIGNING-ORDER':
        return '/staticFiles/signingOrderDocuments/' + this.document?.value;
      case 'AGREEMENT':
        return this.isAgreementDocument
          ? '/staticFiles/agreementDocuments/' + this.agreementDocument?.value
          : this.demo
            ? '/staticFiles/proposalDocuments/' + this.document.value
            : '/staticFiles/intermediateAgreementDocuments/' + this.agreementDocument?.value;
      case 'PROPOSAL':
        return '/staticFiles/proposalDocuments/' + this.document.value;
      default:
        return null;
    }
  }
}
