import {Component, HostBinding} from '@angular/core';

import {AbstractProposalPropertyEdit} from '../../abstract-proposal-property-edit';

@Component({
  selector: 'ax-custom-rating-edit',
  templateUrl: './custom-rating-edit.component.html',
  styleUrls: ['./custom-rating-edit.component.scss'],
})
export class CustomRatingEditComponent extends AbstractProposalPropertyEdit {
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }
}
