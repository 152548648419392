import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {BASE_URL} from '@aztrix/environment';
import {TranslateService} from '@aztrix/translate';
import {Agreement, PropertyType, Proposal} from '@aztrix/models';
import {
  AgreementRepresentation,
  ExternalAgreementRepresentation,
  MetadataRepresentation,
  ProposalRepresentation,
} from '@aztrix/sdk';
import {BehaviorSubject, combineLatest, map} from 'rxjs';

import {SubscribeSuccessComponent} from './subscribe-success/subscribe-success.component';

@Component({
  selector: 'ax-subscribe-form',
  templateUrl: './subscribe-form.component.html',
  styleUrls: ['./subscribe-form.component.scss'],
})
export class SubscribeFormComponent implements OnChanges {
  @Input() proposal?: Proposal | ProposalRepresentation;
  @Input() agreement?: Agreement | AgreementRepresentation;
  @Input() verification: boolean;
  @Input() languageCode?: string;
  @Input() subscriberMetadata: MetadataRepresentation[];

  proposal$ = new BehaviorSubject<Proposal | ProposalRepresentation | undefined>(undefined);
  private _languageCode$ = new BehaviorSubject<string | undefined>(undefined);

  languageCode$ = combineLatest([this._languageCode$, this._translateService.lang$]).pipe(
    map(([languageCode, activeLang]) => languageCode || activeLang)
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;

  constructor(
    private _translateService: TranslateService,
    private _overlay: OverlayService,
    private _elementRef: ElementRef,
    @Optional() @Inject(BASE_URL) private _baseUrl: string
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal && this.proposal) {
      this.proposal$.next(this.proposal);
    }

    if (changes.languageCode && this.languageCode) {
      this._languageCode$.next(this.languageCode);
    }
  }

  subscribedSuccess(externalAgreement: ExternalAgreementRepresentation | undefined) {
    if (externalAgreement) {
      this._overlay.closeModal();
      this._overlay.createModal(this._elementRef, SubscribeSuccessComponent, {
        title: '',
        closeOnNavigate: false,
        init: (succesModal) => {
          succesModal.agreementCode = externalAgreement.agreement?.agreementCode;
        },
      });
    }
  }
}
