@if (form && !ready && (payed$ | async) === false) {
  @if (hasSteps && (showSteps$ | async)) {
    <div class="section-wrapper no-padding">
      <mat-stepper class="stepper" [linear]="true" [orientation]="stepperOrientation" #stepper>
        @for (stepForm of activeStepForms$ | async; track stepForm.get('id')) {
          <mat-step
            class="step"
            [stepControl]="stepForm"
            [aria-label]="outOfRange(stepForm) ? 'out-of-range' : ''"
          >
            <ng-template matStepLabel>
              <div>
                <span class="ax-base-color">{{
                  stepForm.get('title')?.value || ('agreement.add.requested' | translate)
                }}</span>
                <div class="mat-step-optional">
                  {{ stepForm.get('description')?.value }}
                </div>
              </div>
            </ng-template>

            <ax-proposal-subscribe-step
              [form]="form"
              [stepForm]="stepForm"
              [proposal]="proposal"
              [agreement]="agreement"
              [myProfile]="myProfile"
              [language]="language"
              [item]="getItemForStepForm(stepForm)"
              [autocompleteProperties]="autocompleteProperties"
              [demo]="demo"
              [collapsibleGroups]="collapsibleGroups"
            >
              <ng-content></ng-content>
            </ax-proposal-subscribe-step>

            <ax-proposal-subscribe-step-buttons
              [subscribeLabel]="(subscribeButtonLabel$ | async) || undefined"
              [noSubscribeButton]="noSubscribeButton"
              (nextClicked)="next(stepForm)"
              (subscribeClicked)="addAgreement()"
            ></ax-proposal-subscribe-step-buttons>
          </mat-step>
        }

        <mat-step [stepControl]="documentsForm" *ngIf="hasDocuments">
          <ng-template matStepLabel>
            <div>
              <span class="ax-base-color">{{ 'label.documents' | translate }}</span>
              <div class="mat-step-optional">
                {{ 'agreement.add.documents.description' | translate }}
              </div>
            </div>
          </ng-template>

          <ax-proposal-documents-step
            *ngIf="!loading"
            [form]="form"
            [proposal]="proposal"
            [proposalLanguage]="language"
            [agreementProperties]="form | formProperties"
            [agreementDocuments]="agreementDocuments || []"
            [intermediateAgreementDataCode]="intermediateAgreementData?.code || ''"
            [demo]="demo"
          ></ax-proposal-documents-step>

          <ax-spinner *ngIf="loading"></ax-spinner>

          <ax-proposal-subscribe-step-buttons
            [subscribeLabel]="(subscribeButtonLabel$ | async) || undefined"
            [noSubscribeButton]="noSubscribeButton"
            [loading]="loading"
            (nextClicked)="documentsNext()"
            (subscribeClicked)="addAgreement()"
          ></ax-proposal-subscribe-step-buttons>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>
            <div>
              <span class="ax-base-color">{{ 'agreement.add.confirm' | translate }}</span>
              <div class="mat-step-optional">
                {{ 'agreement.add.confirm.description' | translate }}
              </div>
            </div>
          </ng-template>

          <ax-proposal-subscribe-overview
            [agreementProperties]="form | formProperties"
            [proposal]="proposal"
            [language]="language"
            [expired]="!!agreement?.expirationTimestamp"
            [agreementActive]="agreement?.active || false"
            [agreementConfirmed]="agreement?.agreementData?.confirmed || false"
            [agreementDocuments]="intermediateAgreementData?.documents || []"
            [isInAgreement]="false"
          ></ax-proposal-subscribe-overview>

          <ax-proposal-subscribe-send-to-email
            [form]="sendToEmailForm"
            [language]="language"
          ></ax-proposal-subscribe-send-to-email>

          <ax-proposal-subscribe-verification-form
            [form]="form"
            [proposal]="proposal"
            [myProfile]="myProfile"
            [verified]="agreement?.verified || false"
          ></ax-proposal-subscribe-verification-form>

          <ax-subscribe-failed-snackbar
            *ngIf="subscribeError"
            [errorMessage]="'proposal.confirm.error' | translate"
          ></ax-subscribe-failed-snackbar>

          <ax-proposal-subscribe-step-buttons
            class="confirm-buttons"
            [subscribeLabel]="(subscribeButtonLabel$ | async) || undefined"
            [noSubscribeButton]="noSubscribeButton"
            [loading]="(loadingSubscribe$ | async) || false"
            (subscribeClicked)="addAgreement()"
          ></ax-proposal-subscribe-step-buttons>
        </mat-step>

        <ng-template matStepperIcon="done">
          <ax-icon class="icon" name="check"></ax-icon>
        </ng-template>

        <ng-template matStepperIcon="edit">
          <ax-icon class="icon" name="check"></ax-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
          <ax-icon
            class="icon"
            name="card-account-details"
            *ngIf="index < (stepper.steps?.length || 0) - 1"
          ></ax-icon>
          <ax-icon
            class="icon"
            name="file-check"
            *ngIf="index === (stepper.steps?.length || 0) - 1"
          ></ax-icon>
        </ng-template>
      </mat-stepper>
    </div>
  } @else {
    @if (hasSteps) {
      @for (stepForm of stepsForm.controls; track $index) {
        <ax-proposal-subscribe-step
          [form]="form"
          [stepForm]="stepForm"
          [proposal]="proposal"
          [agreement]="agreement"
          [myProfile]="myProfile"
          [language]="language"
          [demo]="demo"
          [autocompleteProperties]="autocompleteProperties"
        >
          <ng-content></ng-content>
        </ax-proposal-subscribe-step>
      }
    } @else {
      <ax-proposal-subscribe-items
        *ngIf="language?.items?.length"
        class="section-wrapper"
        [form]="form"
        [itemForm]="form"
        [proposal]="proposal"
        [autocompleteProperties]="autocompleteProperties"
        [language]="language"
        [item]="language"
        [agreement]="agreement"
        [myProfile]="myProfile"
        [isOwner]="proposal && myProfile ? proposal.ownerId === myProfile.id : false"
        [demo]="demo"
      >
        <ng-content></ng-content>
      </ax-proposal-subscribe-items>
    }

    <ax-proposal-documents-step
      [form]="form"
      [proposal]="proposal"
      [proposalLanguage]="language"
      [agreementProperties]="form | formProperties"
      [agreementDocuments]="agreementDocuments"
      [demo]="demo"
    ></ax-proposal-documents-step>
    <ax-proposal-subscribe-send-to-email
      [form]="sendToEmailForm"
      [language]="language"
    ></ax-proposal-subscribe-send-to-email>

    <ax-proposal-subscribe-verification-form
      [form]="form"
      [proposal]="proposal"
      [myProfile]="myProfile"
      [verified]="agreement?.verified || false"
    ></ax-proposal-subscribe-verification-form>

    @if (subscribeError) {
      <ax-subscribe-failed-snackbar
        [errorMessage]="'proposal.confirm.error' | translate"
      ></ax-subscribe-failed-snackbar>
    }

    @if (!noSubscribeButton) {
      <div class="h-button-wrapper">
        <ax-button
          type="button"
          color="primary"
          filled
          [loading]="loadingSubscribe$ | async"
          (click)="addAgreement()"
        >
          {{ subscribeButtonLabel$ | async | translate }}
        </ax-button>
      </div>
    }
  }
}

@if ((payed$ | async) === true && ready) {
  <div>
    <ax-action-finished
      (didClickButton)="closeModal()"
      bodyTitle="payment.complete"
      [showButton]="false"
    ></ax-action-finished>
    <div class="view-agreement">
      <button
        axButton
        filled
        color="primary"
        (click)="subscribed.emit({agreement: createdAgreementAfterPayment || undefined})"
      >
        {{ 'agreement.view' | translate }}
      </button>
    </div>
  </div>
}
