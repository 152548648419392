import {ClipboardModule} from '@angular/cdk/clipboard';
import {TextFieldModule} from '@angular/cdk/text-field';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {CopyButtonComponent} from './copy-button/copy-button.component';
import {CopyTextComponent} from './copy-text.component';

@NgModule({
  declarations: [CopyTextComponent, CopyButtonComponent],
  imports: [
    CommonModule,
    MatInputModule,
    TextFieldModule,
    IconsModule,
    ButtonModule,
    TranslatePipe,
    ClipboardModule,
  ],
  exports: [CopyTextComponent, CopyButtonComponent],
})
export class CopyTextModule {}
